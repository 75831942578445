import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Cookies from "js-cookie";
import ReactGA from "react-ga";

import cookie from "../assets/images/klimax-cookie.png";

const Cookie = (props) => {
    const [consentCookie, setConsentCookie] = useState("null");

    const {pathname} = useLocation();

    useEffect(() => {
        if (Cookies.get("consent")) {
            setConsentCookie(Cookies.get("consent"));
        }
        if (Cookies.get("consent") === "true") {
            initializationGA();
            ReactGA.pageview(window.location.pathname + window.location.search);
        } else {
            disableDefaultTracker();
        }
    }, [consentCookie, pathname]);

    function cookieAccept(e) {
        e.preventDefault();
        Cookies.set("consent", true, {expires: 365}); // in browser
        setConsentCookie("true"); // in component state
        initializationGA();
    }
    function cookieDecline(e) {
        e.preventDefault();
        Cookies.set("consent", false, {expires: 365}); // in browser
        setConsentCookie("false"); // in component state
        disableDefaultTracker();
    }

    function initializationGA() {
        ReactGA.initialize({
            trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
        });
        if (process.env.NODE_ENV === "production") {
            ReactGA.pageview(window.location.href);
        }
    }

    const disableDefaultTracker = () => {
        // Remove the default tracker.
        if (window.ga) window.ga("remove");
        // Remove the default cookies
        // _ga is used to distinguish users.
        Cookies.remove("_ga", {path: "/", domain: document.domain});
        // _gid is used to distinguish users.
        Cookies.remove("_gid", {path: "/", domain: document.domain});
        // _gat is used to throttle request rate.
        Cookies.remove("_gat", {path: "/", domain: document.domain});
    };

    return (
        <div
            className="cookie"
            style={{
                display: consentCookie !== "null" ? "none" : "flex",
            }}
        >
            <div className="cookie__img">
                <img src={cookie}/>
            </div>
            <div className="cookie__text">
                <h3>Even je aandacht ...</h3>
                <p>
                    Deze website gebruikt enkel een functionele <Link to="/cookies-privacy">cookie</Link>{" "} om je gebruikerservaring te optimaliseren.{" "}
                </p>
                <p>Deze tool bewaart jouw selectie niet, download die dus telkens.</p>
                <div className="cookie__buttons">
                    <button
                        onClick={(e) => cookieAccept(e)}
                        className="klimax-button klimax-button--inverted"
                    >
                        Begrepen
                    </button>
                    {/* <button
                        className="klimax-button"
                        onClick={(e) => cookieDecline(e)}
                    >
                        Liever niet
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default Cookie;
