import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";

import {
  changeModalState,
  deletePinnedCards,
  flipPinnedCards,
  toggleSidebar,
  unflipPinnedCards,
} from "../redux/actions/actions";

import { connect, useDispatch } from "react-redux";
import { closeMobileMenu } from "../redux/actions/actions";
import { Link } from "react-router-dom";

const MobileMenu = (props) => {
  const { mobilemenuState } = props;
  const dispatch = useDispatch();
  const { pinnedCards, pinnedGoals } = props;

  const handleDeleteAll = () => {
    if (pinnedCards.length !== 0 || pinnedGoals.length !== 0) {
      dispatch(changeModalState("DeleteAllModal"));
      dispatch(closeMobileMenu());
    }
  };
  const ref = useRef();
  const handleClickOutside = () => {
    if (mobilemenuState === true) {
      dispatch(closeMobileMenu());
    }
  };
  useOnClickOutside(ref, () => handleClickOutside());

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }

  return (
    <div
      className={`klimax-mobilemenu ${
        mobilemenuState === true ? "klimax-mobilemenu--open" : ""
      }`}
      ref={ref}
    >
      <div className="klimax-mobilemenu-wrapper">
        <div className="container">
          <button
            className="klimax-mobilemenu__close"
            onClick={() => dispatch(closeMobileMenu())}
          >
            <i className="icon-klimax-close"></i>
          </button>

          <button
            className="klimax-button klimax-button--inverted"
            onClick={() => dispatch(toggleSidebar())}
            aria-label="open de zijbalk"
          >
            <i className="icon-klimax-hamburger" />{" "}
            <span>Model en werkwijze</span>
          </button>

          <Link
            className="klimax-button klimax-button--inline klimax-button--inverted"
            onClick={() => dispatch(closeMobileMenu())}
            aria-label="download je gepinde kaartjes - open print venster"
            to="/print"
          >
            <i className="icon-klimax-download" /> <span>Download</span>
          </Link>

          <button
            className="klimax-button klimax-button--inverted"
            onClick={() => handleDeleteAll()}
            aria-label="bord leegmaken"
          >
            <i className="icon-klimax-delete" /> <span>Bord leegmaken</span>
          </button>

          <Link
            to="/inspiratie"
            className="klimax-button klimax-button--inline"
            aria-label="voeg pin toe"
            onClick={() => dispatch(closeMobileMenu())}
          >
            <i className="icon-klimax-plus" /> <span>Voeg pin toe</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mobilemenuState: state.mobilemenuState,
  pinnedCards: state.pinnedCards,
  pinnedGoals: state.pinnedGoals,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeMobileMenu: () => dispatch(closeMobileMenu()),

    // dispatching actions returned by action creators
    flipPinnedCards: () => dispatch(flipPinnedCards()),
    unflipPinnedCards: () => dispatch(unflipPinnedCards()),

    deletePinnedCards: () => dispatch(deletePinnedCards()),
    toggleSidebar: () => dispatch(toggleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
