import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { connect, useDispatch } from "react-redux";
import {
  addPinnedCard,
  changeModalState,
  flipCards,
  removePinnedCard,
} from "../../redux/actions/actions";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation } from "react-router";

const TopicCard = (props) => {
  // meegekregen uit een pagina waar deze component wordt opgeroepen
  const flipCard = props.card;

  // meegekregen props uit reducer én uit parent
  const { pinnedCards, key, cards } = props;

  const topicCategory = props.topicCategories.filter((category) => {
    return category.id === flipCard.topicCatId;
  })[0];

  // Nodig om error te voorkomen; don't ask why
  const nodeRef = React.useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (

      <div
        className={`klimax-printcard klimax-printcard--topic-${topicCategory.title}`}
        id={topicCategory.title}
        ref={nodeRef}
        aria-label="Klimaatkaart"
      >
        <div className="klimax-printcard-wrapper">
                <img
                    className="klimax-printcard-pic"
                    src={`https://oetang.be/klimax/assets/images/inspiratie/topic/${flipCard.pic1}`}
                    alt={`Foto ${flipCard.title}`}
                />
                <div className="klimax-printcard-content">
                    <div className="klimax-printcard-label">{topicCategory.title}</div>
                    <div
                        className="klimax-printcard-title"
                        dangerouslySetInnerHTML={{__html: flipCard.title}}
                    ></div>
                    
                    <div
                        className="klimax-printcard-baseline"
                        dangerouslySetInnerHTML={{__html: flipCard.teaser}}
                    ></div>
                    <p
                        dangerouslySetInnerHTML={{__html: flipCard.description}}
                    ></p>
                </div>
            </div>
      </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  topicCategories: state.topicCategory,
  pinnedCards: state.pinnedCards,
  state: state,
  modalState: state.modalState,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    addPinnedCard: (pinnedCard) => dispatch(addPinnedCard(addPinnedCard)),
    removePinnedCard: (pinnedCard, index) =>
      dispatch(removePinnedCard(removePinnedCard, index)),
    flipCards: (id) => dispatch(flipCards(id)),
    changeModalState: (newModalState) =>
      dispatch(changeModalState(newModalState)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicCard);
