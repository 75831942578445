import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import klimaxCircle from "../../assets/images/klimax-circle.svg";
import {
  addPinnedCard,
  changeModalState,
  removePinnedCard,
  closeSidebar,
} from "../../redux/actions/actions";

const PinModal = (props) => {
  const { modalState, pinnedCards, pinnedGoals } = props;
  const dispatch = useDispatch();

  return (
    <div
      className={`klimax-pin${
        modalState === "PinModal" ? " klimax-pin--open" : ""
      }`}
    >
      <div className="klimax-pin-dialog">
        <button
          className="klimax-pin__close"
          onClick={() => dispatch(changeModalState())}
        >
          <i className="icon-klimax-close"></i>
        </button>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="148"
            height="148"
            viewBox="0 0 148 148"
          >
            <g
              id="Group_291"
              data-name="Group 291"
              transform="translate(-646 -250)"
            >
              <circle
                id="Ellipse_38"
                data-name="Ellipse 38"
                cx="74"
                cy="74"
                r="74"
                transform="translate(646 250)"
                fill="#fff"
              />
              <g
                id="Group_290"
                data-name="Group 290"
                transform="translate(-20329.486 11023.514)"
              >
                <path
                  id="werkvorm_communicatie_rood"
                  data-name="werkvorm_communicatie_rood"
                  d="M87.681,64.679a28.285,28.285,0,0,1-49.305.3L22.817,74.042a46.279,46.279,0,0,0,80.327-.131Z"
                  transform="translate(20986.543 -10750.452)"
                  fill="#f2441a"
                  fillOpacity={
                    pinnedCards.some(
                      (el) =>
                        el.goalMethodCatId === 5 || el.goalMethodCatId === 6
                    )
                      ? "1"
                      : "0.2"
                  }
                />
                <path
                  id="werkvorm_kritisch-denken_geel"
                  data-name="werkvorm_communicatie_rood"
                  d="M36.015,64.324A28.341,28.341,0,0,1,64.324,36.015V18A46.289,46.289,0,0,0,24.2,87.428l15.56-9.064a28.119,28.119,0,0,1-3.743-14.04"
                  transform="translate(20985.162 -10763.838)"
                  fill="#f4cc27"
                  fillOpacity={
                    pinnedCards.some(
                      (el) =>
                        el.goalMethodCatId === 1 || el.goalMethodCatId === 2
                    )
                      ? "1"
                      : "0.2"
                  }
                />
                <path
                  id="werkvorm_actiegericht_oranje"
                  data-name="Path 1578"
                  d="M82.309,64.324a28.122,28.122,0,0,1-3.571,13.74L94.2,87.3A46.289,46.289,0,0,0,54,18V36.015A28.341,28.341,0,0,1,82.309,64.324"
                  transform="translate(20995.486 -10763.838)"
                  fill="#f78e34"
                  fillOpacity={
                    pinnedCards.some(
                      (el) =>
                        el.goalMethodCatId === 3 || el.goalMethodCatId === 4
                    )
                      ? "1"
                      : "0.2"
                  }
                />
                <path
                  id="doel_kritisch-denken_geel"
                  data-name="Path 1583"
                  d="M59.162,36A23.133,23.133,0,0,0,39.119,70.734L59.162,59.162Z"
                  transform="translate(20990.324 -10758.676)"
                  fill="#f4cc27"
                  fillOpacity={
                    pinnedGoals.some((el) => el.primaryColor === "yellow")
                      ? "1"
                      : "0.2"
                  }
                />
                <path
                  id="doel_communicatie_rood"
                  data-name="Path 1584"
                  d="M38.424,65.572a23.125,23.125,0,0,0,40.086,0L58.467,54Z"
                  transform="translate(20991.02 -10753.514)"
                  fill="#f2441a"
                  fillOpacity={
                    pinnedGoals.some((el) => el.primaryColor === "red")
                      ? "1"
                      : "0.2"
                  }
                />
                <path
                  id="doel_actiegericht_oranje"
                  data-name="Path 1585"
                  d="M54,36V59.162L74.043,70.734A23.133,23.133,0,0,0,54,36"
                  transform="translate(20995.486 -10758.676)"
                  fill="#f78e34"
                  fillOpacity={
                    pinnedGoals.some((el) => el.primaryColor === "orange")
                      ? "1"
                      : "0.2"
                  }
                />
                <path
                  id="theme1"
                  data-name="Path 1586"
                  class="pinned"
                  d="M19.3,54H0a69.512,69.512,0,0,0,34.964,60.265L44.61,97.557A50.2,50.2,0,0,1,19.3,54"
                  transform="translate(20980 -10753.514)"
                  fill="#3e76da"
                  fillOpacity={
                    pinnedCards.some((el) => el.sort === "theme") ? "1" : "0.2"
                  }
                />
                <path
                  id="theme2"
                  data-name="Path 1579"
                  d="M117.727,54h-19.3A50.2,50.2,0,0,1,74.083,97l9.646,16.707a69.512,69.512,0,0,0,34-59.709"
                  transform="translate(21001.246 -10753.514)"
                  fill="#3e76da"
                  fillOpacity={
                    pinnedCards.some((el) => el.sort === "theme") ? "1" : "0.2"
                  }
                />
                <path
                  id="theme3"
                  data-name="Path 1587"
                  d="M61.694,94.6a49.856,49.856,0,0,1-24.876-6.627l-9.646,16.707a69.264,69.264,0,0,0,70.01-.556L87.536,87.418A49.875,49.875,0,0,1,61.694,94.6"
                  transform="translate(20987.793 -10743.931)"
                  fill="#3e76da"
                  fillOpacity={
                    pinnedCards.some((el) => el.sort === "theme") ? "1" : "0.2"
                  }
                />
                <path
                  id="topic_oplossing"
                  data-name="Path 1580"
                  d="M98.64,67.431h19.3A69.512,69.512,0,0,0,82.978,7.166L73.332,23.873A50.2,50.2,0,0,1,98.64,67.431"
                  transform="translate(21001.029 -10766.945)"
                  fill="#66a357"
                  fillOpacity={
                    pinnedCards.some(
                      (el) => el.sort === "topic" && el.topicCatId === 3
                    )
                      ? "1"
                      : "0.2"
                  }
                />
                <path
                  id="topic_oorzaak"
                  data-name="Path 1588"
                  d="M0,67.307H19.3a50.2,50.2,0,0,1,24.342-43L34,7.6A69.512,69.512,0,0,0,0,67.307"
                  transform="translate(20980 -10766.821)"
                  fill="#287b14"
                  fillOpacity={
                    pinnedCards.some(
                      (el) => el.sort === "topic" && el.topicCatId === 1
                    )
                      ? "1"
                      : "0.2"
                  }
                />
                <path
                  id="topic_gevolg"
                  data-name="Path 1589"
                  d="M61.909,19.3a49.856,49.856,0,0,1,24.876,6.627L96.431,9.221a69.264,69.264,0,0,0-70.01.556l9.646,16.707A49.875,49.875,0,0,1,61.909,19.3"
                  transform="translate(20987.576 -10769)"
                  fill="#4f8e40"
                  fillOpacity={
                    pinnedCards.some(
                      (el) => el.sort === "topic" && el.topicCatId === 2
                    )
                      ? "1"
                      : "0.2"
                  }
                />
              </g>
            </g>
          </svg>
          <h1 className="klimax-pin__title">
            <b>Toegevoegd!</b>
          </h1>
        </div>

        <div className="klimax-pin-buttons">
          {/* <Link
            to="/inspiratie"
            className="klimax-button"
            onClick={() => dispatch(changeModalState("none"))}
            aria-label="nog een pin toevoegen"
          >
            <i className="icon-klimax-plus" /> Nog een pin toevoegen
          </Link> */}
          <Link
            to="/"
            className="klimax-button klimax-button--inverted"
            onClick={() => dispatch(changeModalState("none"))}
            aria-label="ga naar mijn bord"
          >
            <i className="icon-klimax-grid" /> Naar mijn bord
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  modalState: state.modalState,
  sidebarState: state.sidebarState,
  themeCategories: state.themeCategory,
  pinnedCards: state.pinnedCards,
  pinnedGoals: state.pinnedGoals,
  state: state,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    addPinnedCard: (pinnedCard) => dispatch(addPinnedCard(addPinnedCard)),
    changeModalState: (newModalState) =>
      dispatch(changeModalState(newModalState)),
    removePinnedCard: (pinnedCard, index) =>
      dispatch(removePinnedCard(removePinnedCard, index)),
    closeSidebar: () => dispatch(closeSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinModal);
