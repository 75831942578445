import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { connect, useDispatch } from "react-redux";
import { flipPinnedGoal } from "../redux/actions/actions";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

const GoalCard = (props) => {
  const dispatch = useDispatch();
  // meegekregen uit een pagina waar deze component wordt opgeroepen

  // meegekregen props uit reducer én uit parent
  const { pinnedGoals, key, cards, pinnedGoalsFlipstate } = props;

  // Nodig om rerender van child component (nl. CSSTransition) te triggeren/forceren
  const [updatedKey, setUpdatedKey] = useState(
    pinnedGoalsFlipstate.toString() + 1
  );

  // // Eén kaartje laten flippen + rerender van child component (nl. CSSTransition) triggeren
  const handleFlip = () => {
    dispatch(flipPinnedGoal());
    setUpdatedKey(pinnedGoalsFlipstate.toString() + "goal");
  };
  useEffect(() => {
    setUpdatedKey(updatedKey + 1);
  }, []);

  // const goalMethodCategory = props.goalMethodCategories.filter((category) => {
  //   return category.id === flipCard.goalMethodCatId;
  // })[0];

  // // Pinnen

  // // Nodig om error te voorkomen; don't ask why
  const nodeRef = React.useRef(null);
  console.log(pinnedGoals.length);

  return (
    <CSSTransition
      // Flipstate wordt uit de reducer gehaald, uit 'cards', NIET uit 'pinnedCards!
      in={!pinnedGoalsFlipstate}
      key={key}
      timeout={300}
      classNames="flip"
      nodeRef={nodeRef}
    >
      <div
        className={`klimax-climatecard klimax-climatecard--goal`}
        // id={flipCard.goalMethodCatId}
        ref={nodeRef}
        aria-label="Competenties-kaart"
      >
        <div className="klimax-climatecard__face klimax-climatecard__face--front">
          <div className="klimax-climatecard-wrapper">
            <div className="klimax-climatecard-pic"></div>
            <Link
              className="klimax-climatecard-flip"
              to="./inspiratie/competenties"
            >
              <i className="icon-klimax-internal"></i>
            </Link>
            <div className="klimax-climatecard-content">
              <div className="klimax-climatecard-label">
                <div>Competenties</div>
              </div>
              <div className="klimax-climatecard-title">
                {" "}
                {pinnedGoals.length +
                  (pinnedGoals.length == 1 ? " competentie" : " competenties")}
              </div>
              <div className="klimax-climatecard-baseline">
                <ul className="klimax-goals-list">
                  <li className="klimax-goals-list__item klimax-goals-list__item--yellow">
                    <span></span>
                    {
                      pinnedGoals.filter((item) => {
                        if (item.primaryColor === "yellow") {
                          return item;
                        }
                      }).length
                    }
                  </li>
                  <li className="klimax-goals-list__item klimax-goals-list__item--orange">
                    <span></span>
                    {
                      pinnedGoals.filter((item) => {
                        if (item.primaryColor === "orange") {
                          return item;
                        }
                      }).length
                    }
                  </li>
                  <li className="klimax-goals-list__item klimax-goals-list__item--red">
                    <span></span>
                    {
                      pinnedGoals.filter((item) => {
                        if (item.primaryColor === "red") {
                          return item;
                        }
                      }).length
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="klimax-climatecard__face klimax-climatecard__face--back">
          <button
            className="klimax-climatecard-flip"
            onClick={() => handleFlip()}
          >
            <i className="icon-klimax-flip"></i>
          </button>
          <div className="klimax-climatecard-wrapper">
            <div className="klimax-climatecard-title">Competenties</div>
            <p>Eventuele korte of lange tekst over de Competenties.</p>
            <ul className="klimax-goals-list">
              <li className="klimax-goals-list__item klimax-goals-list__item--yellow">
                <span></span>Onderzoeken
                <div className="klimax-goals-list__item-counter">
                  {
                    pinnedGoals.filter((item) => {
                      if (item.primaryColor === "yellow") {
                        return item;
                      }
                    }).length
                  }
                </div>
              </li>
              <li className="klimax-goals-list__item klimax-goals-list__item--orange">
                <span></span>Communiceren
                <div className="klimax-goals-list__item-counter">
                  {
                    pinnedGoals.filter((item) => {
                      if (item.primaryColor === "orange") {
                        return item;
                      }
                    }).length
                  }
                </div>
              </li>
              <li className="klimax-goals-list__item klimax-goals-list__item--red">
                <span></span>Creatief denken
                <div className="klimax-goals-list__item-counter">
                  {
                    pinnedGoals.filter((item) => {
                      if (item.primaryColor === "blue") {
                        return item;
                      }
                    }).length
                  }
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  goalMethodCategories: state.goalMethodCategory,
  pinnedGoals: state.pinnedGoals,
  pinnedGoalsFlipstate: state.pinnedGoalsFlipstate,
  state: state,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    // flipCards: (id) => dispatch(flipCards(id)),
    flipPinnedGoal: () => dispatch(flipPinnedGoal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalCard);
