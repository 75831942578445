import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  changeModalState,
  deletePinnedCards,
} from "../../redux/actions/actions";

const DeleteAllModal = (props) => {
  const { modalState } = props;
  const dispatch = useDispatch();
  const handleDeleteAll = () => {
    dispatch(deletePinnedCards());
    dispatch(changeModalState("none"));
  };

  return (
    <div
      className="klimax-modal"
      style={
        modalState === "DeleteAllModal"
          ? { display: "block" }
          : { display: "none" }
      }
    >
      <div className="klimax-modal-dialog">
        <i className="icon-klimax-delete" />
        <h1 className="klimax-modal__title">
          Ben je zeker dat je al je <br></br>klimaatpins wil <b>wissen</b>?
        </h1>

        <div className="klimax-modal-buttons">
          <button
            className="klimax-button"
            onClick={() => dispatch(changeModalState("none"))}
            aria-label="annuleer"
          >
            Annuleer
          </button>
          <button
            to="/"
            className="klimax-button klimax-button--inverted"
            onClick={() => handleDeleteAll()}
            aria-label="bevestig"
          >
            Bevestig
          </button>
        </div>
      </div>
      <div className="klimax-modal-backdrop"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  modalState: state.modalState,
  themeCategories: state.themeCategory,
  pinnedCards: state.pinnedCards,
  state: state,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    changeModalState: (newModalState) =>
      dispatch(changeModalState(newModalState)),
    deletePinnedCards: () => dispatch(deletePinnedCards()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAllModal);