import React, {useEffect, useState, Component} from "react";
import {Link} from "react-router-dom";

class Cookie extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="klimax-box">
                <div className="klimax-box-wrapper">
                    <Link
                        to="/"
                        className="klimax-button klimax-button--inline"
                    >
                        <i className="icon-klimax-grid"></i> terug naar Klimax
                    </Link>
                    <div className="container">
                        <h1 id="cookies">Cookieverklaring</h1>
                        <h3>Versie 1.0.0 - 3 maart 2021</h3>

                        <h2>Cookiebeleid</h2>

                        <p>
                            Departement Omgeving (verder ‘dOMG’) vindt het
                            belangrijk dat u op iedere plaats en elk ogenblik
                            haar dOMG-inhoud kan bekijken, beluisteren, lezen of
                            beleven via diverse mediaplatformen. dOMG wil ook
                            werken aan interactieve diensten en diensten op uw
                            maat. Op dOMG-onlinediensten worden technieken
                            gehanteerd om dit mogelijk te maken, bijvoorbeeld
                            met behulp van cookies en scripts. Deze technieken
                            worden hierna gemakkelijkheidshalve cookies genoemd.
                            In dit cookiebeleid wenst dOMG u te informeren welke
                            cookies worden gebruikt en waarom dit gebeurt.
                            Verder wordt toegelicht in welke mate u als
                            gebruiker het gebruik kan controleren. dOMG wil
                            namelijk graag uw privacy en de
                            gebruiksvriendelijkheid van haar onlinediensten
                            zoveel mogelijk waarborgen.
                        </p>

                        <p>
                            Dit cookiebeleid is van toepassing op alle
                            'dOMG-onlinediensten', met name alle websites,
                            (mobiele) applicaties en internetdiensten die dOMG
                            aanbiedt en die toegang geven tot dOMG-inhoud.
                        </p>

                        <p>
                            dOMG kan het cookiebeleid op elk moment veranderen.
                            Dat kan bijvoorbeeld gebeuren in het kader van
                            veranderingen aan haar diensten of de geldende
                            wetgeving. Het gewijzigde beleid wordt dan
                            bekendgemaakt op de relevante onlinediensten en
                            geldt vanaf het moment dat deze bekendgemaakt wordt.
                        </p>

                        <h2>Wat zijn cookies precies?</h2>

                        <p>
                            Cookies zijn kleine gegevens- of tekstbestanden die
                            op uw computer of mobiele apparaat worden
                            geïnstalleerd wanneer u een website bezoekt of een
                            (mobiele) toepassing gebruikt. Het cookiebestand
                            bevat een unieke code waarmee uw browser herkend kan
                            worden tijdens het bezoek aan de online service of
                            tijdens opeenvolgende, herhaalde bezoeken. Cookies
                            kunnen worden geplaatst door de server van de
                            website of applicatie die u bezoekt, maar ook door
                            servers van derden die al dan niet met deze website
                            of applicatie samenwerken.
                        </p>

                        <p>
                            Cookies maken over het algemeen de interactie tussen
                            de bezoeker en de website of applicatie
                            gemakkelijker en sneller en helpen de bezoeker om te
                            navigeren tussen de verschillende delen van een
                            website of applicatie.
                        </p>

                        <h2>
                            Hoe kan ik het gebruik van cookies op deze
                            onlinediensten weigeren of beheren?
                        </h2>

                        <p>
                            U kunt de installatie van cookies weigeren via uw
                            browserinstellingen. U kunt op elk gewenst moment
                            ook de reeds geïnstalleerde cookies van uw computer
                            of mobiele apparaat verwijderen. Instructies vindt u
                            op de website van uw browser:
                        </p>

                        <ul>
                            <li>
                                <a
                                    is="vl-link"
                                    href="https://support.microsoft.com/nl-be/help/17479/windows-internet-explorer-11-change-security-privacy-settings"
                                    target="_blank"
                                    class="vl-link"
                                >
                                    Microsoft Internet Explorer
                                    <span
                                        is="vl-icon"
                                        data-vl-icon="external"
                                        data-vl-after=""
                                        class="vl-vi-external vl-link__icon--after vl-link__icon--data-vl-after vl-link__icon vl-vi vl-icon--data-vl-after"
                                        link=""
                                        aria-hidden="true"
                                    ></span>
                                </a>
                            </li>
                            <li>
                                <a
                                    is="vl-link"
                                    href="https://support.microsoft.com/nl-nl/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
                                    target="_blank"
                                    class="vl-link"
                                >
                                    Microsoft Edge
                                    <span
                                        is="vl-icon"
                                        data-vl-icon="external"
                                        data-vl-after=""
                                        class="vl-vi-external vl-link__icon--after vl-link__icon--data-vl-after vl-link__icon vl-vi vl-icon--data-vl-after"
                                        link=""
                                        aria-hidden="true"
                                    ></span>
                                </a>
                            </li>
                            <li>
                                <a
                                    is="vl-link"
                                    href="http://support.google.com/chrome/bin/answer.py?hl=nl&amp;answer=95647"
                                    target="_blank"
                                    class="vl-link"
                                >
                                    Google Chrome
                                    <span
                                        is="vl-icon"
                                        data-vl-icon="external"
                                        data-vl-after=""
                                        class="vl-vi-external vl-link__icon--after vl-link__icon--data-vl-after vl-link__icon vl-vi vl-icon--data-vl-after"
                                        link=""
                                        aria-hidden="true"
                                    ></span>
                                </a>
                            </li>
                            <li>
                                <a
                                    is="vl-link"
                                    href="http://support.mozilla.org/nl/kb/cookies-in-en-uitschakelen-websites-voorkeuren?redirectlocale=nl&amp;redirectslug=Cookies+in-+en+uitschakelen"
                                    target="_blank"
                                    class="vl-link"
                                >
                                    Mozilla Firefox
                                    <span
                                        is="vl-icon"
                                        data-vl-icon="external"
                                        data-vl-after=""
                                        class="vl-vi-external vl-link__icon--after vl-link__icon--data-vl-after vl-link__icon vl-vi vl-icon--data-vl-after"
                                        link=""
                                        aria-hidden="true"
                                    ></span>
                                </a>
                            </li>
                            <li>
                                <a
                                    is="vl-link"
                                    href="http://support.apple.com/kb/PH5042"
                                    target="_blank"
                                    class="vl-link"
                                >
                                    Apple Safari
                                    <span
                                        is="vl-icon"
                                        data-vl-icon="external"
                                        data-vl-after=""
                                        class="vl-vi-external vl-link__icon--after vl-link__icon--data-vl-after vl-link__icon vl-vi vl-icon--data-vl-after"
                                        link=""
                                        aria-hidden="true"
                                    ></span>
                                </a>
                            </li>
                        </ul>

                        <p>
                            Wanneer u cookies uitschakelt, moet u er rekening
                            mee houden dat bepaalde grafische elementen er niet
                            mooi kunnen uitzien, of dat u bepaalde toepassingen
                            niet kunt gebruiken. Hieronder vindt u een
                            gedetailleerde lijst van alle cookies die in deze
                            website of toepassing worden gebruikt.
                        </p>

                        <h2>Gebruikte cookies</h2>

                        <p>
                            Er wordt op de KLIMAX website <b>geen</b> gebruik
                            gemaakt van cookies.
                        </p>

                        <Link
                            to="/"
                            className="klimax-button klimax-button--inline"
                        >
                            <i className="icon-klimax-grid"></i> terug naar
                            Klimax
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Cookie;
