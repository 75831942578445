import React from "react";
import mos from "../assets/images/logo-mos.svg";
import vlaanderen from "../assets/images/logo-vlaanderen-omgeving.svg";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <div className="klimax-footer" aria-label="footer">
      <div className="klimax-footer__logos">
        {/* <a
          href="https://www.mosvlaanderen.be/"
          target="_blank"
          aria-label="link naar website Mos Vlaanderen"
        >
          <img src={mos} />
  </a> */}
        <a
          href="https://www.vlaanderen.be/"
          target="_blank"
          aria-label="link naar website Vlaanderen"
        >
          <img src={vlaanderen} />
        </a>
      </div>

      <div className="klimax-footer-wrapper">
        <div className="klimax-footer__cooperation">
          In samenwerking met{" "}
          <a
            href="https://www.mosvlaanderen.be"
            target="_blank"
            rel="noopener"
            aria-label="link naar website MOS"
          >
            MOS
          </a>
          ,{" "}
          <a
            href="https://www.arteveldehogeschool.be/nl"
            target="_blank"
            rel="noopener"
            aria-label="link naar website Arteveldehogeschool"
          >
            Arteveldehogeschool
          </a>{" "}
          en{" "}
          <a
            href="https://oetang.be/"
            target="_blank"
            rel="noopener"
            aria-label="link naar website Oetang Learning Designers"
          >
            Oetang
          </a>
        </div>
        <div className="klimax-footer__copyright">
          © 2023 duurzaam educatiepunt vlaanderen
        </div>
      </div>

      <div
        className="klimax-footer-cookies"
        aria-label="privacy en cookies banner"
      >
        <Link to="/cookies-privacy">cookies &amp; privacy</Link>
        <Link to="/toegankelijkheid">toegankelijkheid</Link>
      </div>
    </div>
  );
};

export default Footer;
