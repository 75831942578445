import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { connect, useDispatch } from "react-redux";
import { flipPinnedGoal } from "../../redux/actions/actions";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

const GoalCard = (props) => {
  const dispatch = useDispatch();
  // meegekregen uit een pagina waar deze component wordt opgeroepen

  // meegekregen props uit reducer én uit parent
  const { pinnedGoals, key, cards, pinnedGoalsFlipstate } = props;

  // Nodig om rerender van child component (nl. CSSTransition) te triggeren/forceren
  const [updatedKey, setUpdatedKey] = useState(
    pinnedGoalsFlipstate.toString() + 1
  );

  // // Eén kaartje laten flippen + rerender van child component (nl. CSSTransition) triggeren
  const handleFlip = () => {
    dispatch(flipPinnedGoal());
    setUpdatedKey(pinnedGoalsFlipstate.toString() + "goal");
  };
  useEffect(() => {
    setUpdatedKey(updatedKey + 1);
  }, []);

  // const goalMethodCategory = props.goalMethodCategories.filter((category) => {
  //   return category.id === flipCard.goalMethodCatId;
  // })[0];

  // // Pinnen

  // // Nodig om error te voorkomen; don't ask why
  const nodeRef = React.useRef(null);

  // Goals weergeven en filteren

  return (
    <div
      className={`klimax-printcard klimax-printcard--goal`}
      // id={flipCard.goalMethodCatId}
      ref={nodeRef}
      aria-label="Competenties-kaart"
    >
      <div className="klimax-climatecards klimax-goals">
        <div className="klimax-goal">
          {pinnedGoals.map((goal, g) => (
            <div card={goal} key={g} pinnedGoals={pinnedGoals} className={`klimax-goal--${goal.primaryColor}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: goal.title,
                }}
              />
              <ul>
                <li>{goal.subgoal1}</li>
                <li>{goal.subgoal2}</li>
                <li>{goal.subgoal3}</li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  goalMethodCategory: state.goalMethodCategory,
  pinnedGoals: state.pinnedGoals,
  pinnedGoalsFlipstate: state.pinnedGoalsFlipstate,
  state: state,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    // flipCards: (id) => dispatch(flipCards(id)),
    flipPinnedGoal: () => dispatch(flipPinnedGoal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalCard);
