import React, {useEffect, useState} from "react";
import {HashLink} from "react-router-hash-link";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Card from "../../components/TopicCard";

const Topics = (props) => {
    const {pinnedCards, cards} = props;
    const showFronts = [];
    for (let i = 0; i < pinnedCards.length; i++) {
        showFronts.push(true);
    }
    const listTopics = props.topicCategory;

    const listTopicCards = props.cards
        .filter((topicCard) => {
            return topicCard.sort === "topic";
        })
        .sort((a, b) => (a.topicCatId > b.topicCatId ? 1 : -1));

    return (
        <div className="klimax-inspiration klimax-inspiration--picker klimax-inspiration--topics">
            <div className="klimax-inspiration-wrapper">
                <div>
                    <div className="klimax-inspiration__intro">
                        <Link to="/" className="klimax-inspiration__close" aria-label="Sluit en ga terug naar mijn bord">
                            <i className="icon-klimax-close-rounded"></i>
                        </Link>

                        <Link
                            to="/inspiratie"
                            className="klimax-inspiration__back"  aria-label="Terug naar inspiratiekeuze"
                        >
                            <i className="icon-klimax-arrow"></i>
                        </Link>
                        <h1 className="klimax-inspiration__title">
                            Hot topics
                        </h1>
                    </div>
                    <div className="klimax-inspiration-results">
                        <ul
                            className="klimax-toc"
                            aria-label="Inhoudstafel topics"
                        >
                            {listTopics.map((topic, t) => (
                                <li
                                    key={t}
                                    className={`klimax-toc__item klimax-toc__item--topic-${topic.primaryColor}`}
                                >
                                    <Link
                                        to={`#${topic.title}`}
                                    >
                                        {topic.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <div className="klimax-inspiration-results__wrapper">
                            <div className="klimax-climatecards" id="cards">
                                {listTopicCards.map((card, i) => (
                                    <Card
                                        card={card}
                                        pinnedCards={pinnedCards}
                                        key={card.id}
                                    ></Card>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    cards: state.cards,
    topicCategory: state.topicCategory,
    pinnedCards: state.pinnedCards,
});

export default connect(mapStateToProps)(Topics);
