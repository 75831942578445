import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import {
  addPinnedCard,
  addPinnedGoal,
  removeAllPinnedGoals,
  removePinnedCard,
  removePinnedGoal,
} from "../../redux/actions/actions";

import DocGoals from "../../assets/doc/Klimax_doelen_overzicht_Vlaanderen2023.pdf";

const Goals = (props) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [doelenSelect, setDoelenSelect] = useState("alle");

  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = (winScroll / height) * 100;

    setScrollTop(scrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // Goals weergeven en filteren
  const listGoals = props.goalMethodCategory;
  const { pinnedCards, pinnedGoals, goalMethodCategory, cards } = props;
  const listGoalsFiltered = props.goalMethodCategory.filter((category) =>
    pinnedCards.some((pinnedCard) => pinnedCard.goalMethodCatId === category.id)
  );

  // Pinnen
  const dispatch = useDispatch();
  const handlePinToggle = (goal, checkState) => {
    if (
      checkState === false &&
      pinnedGoals.filter((el) => el.id === goal.id)[0] !== -1
    ) {
      dispatch(addPinnedGoal(goal));
    } else {
      const index = pinnedGoals.indexOf(goal);
      dispatch(removePinnedGoal(goal, index));
    }
  };

  const handleSelectAll = () => {
    // pinnedGoals.map((el) => {
    //   const index = pinnedGoals.indexOf(el);
    //   dispatch(removePinnedGoal(el, index));
    // });
    if (doelenSelect === "enkel") {
      goalMethodCategory
        .filter((category) =>
          pinnedCards.some(
            (pinnedCard) => pinnedCard.goalMethodCatId === category.id
          )
        )
        .map((el) => {
          dispatch(addPinnedGoal(el));
        });
    } else {
      goalMethodCategory.map((el) => {
        dispatch(addPinnedGoal(el));
      });
    }
  };

  const handleRemoveAll = () => {
    // pinnedGoals.map((el) => {
    //   const index = pinnedGoals.indexOf(el);
    //   dispatch(removePinnedGoal(el, index));
    // });
    if (doelenSelect === "enkel") {
      listGoalsFiltered.map((goal) => {
        const index = pinnedGoals.indexOf(goal);
        dispatch(removePinnedGoal(goal, index));
      });
      // .filter((category) =>
      //   pinnedGoals.some(
      //     (pinnedGoal) => pinnedGoal.goalMethodCatId === category.id
      //   )
      // )
    } else {
      dispatch(removeAllPinnedGoals());
    }
  };

  return (
    <div className="klimax-inspiration klimax-inspiration--picker klimax-inspiration--goals">
      <div className="klimax-inspiration-wrapper">
        <div>
          <Link
            to="/"
            className="klimax-inspiration__close"
            aria-label="Sluit en ga terug naar mijn bord"
          >
            <i className="icon-klimax-close-rounded"></i>
          </Link>
          <Link
            to="/inspiratie"
            className="klimax-inspiration__back"
            aria-label="Terug naar inspiratiekeuze"
          >
            <i className="icon-klimax-arrow"></i>
          </Link>
          <div className="klimax-inspiration__intro">
            <h1 className="klimax-inspiration__title">Competenties</h1>

            <div className="klimax-radio-buttons">
              <fieldset>
                <div>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="selecteerWelkeDoelenRadio"
                    id="alleDoelenRadio"
                    value="alle"
                    defaultChecked
                    onClick={() => setDoelenSelect("alle")}
                  />
                  <label className="form-check-label" htmlFor="alleDoelenRadio">
                    Toon alle competenties
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="selecteerWelkeDoelenRadio"
                    id="enkelDoelenRadio"
                    value="enkel"
                    disabled={
                      pinnedCards.some((el) => el.sort === "method")
                        ? false
                        : true
                    }
                    onClick={() => setDoelenSelect("enkel")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="enkelDoelenRadio"
                    style={{
                      cursor: pinnedCards.some((el) => el.sort === "method")
                        ? "pointer"
                        : "default",
                    }}
                  >
                    Toon enkel competenties met linken naar reeds gepinde
                    werkvormen
                  </label>
                </div>
              </fieldset>
            </div>
          </div>

          <div className="klimax-inspiration-results">
            <div className="klimax-inspiration-results__wrapper">
              <div className="klimax-climatecards klimax-goals">
                <Accordion defaultActiveKey="1" className="klimax-goal">
                  {doelenSelect === "alle"
                    ? listGoals.map((goal, g) => (
                        <Accordion.Item
                          eventKey={goal.id}
                          key={g}
                          className={`accordion-item--${goal.primaryColor}`}
                        >
                          <Accordion.Header className="klimax-goal__item">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: goal.title,
                              }}
                            />
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              <li>{goal.subgoal1}</li>
                              <li>{goal.subgoal2}</li>
                              <li>{goal.subgoal3}</li>
                            </ul>
                          </Accordion.Body>
                          <div className="check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              onClick={(e) =>
                                handlePinToggle(
                                  goal,
                                  e.target.checked ? false : true
                                )
                              }
                              checked={
                                pinnedGoals.includes(goal) ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            ></label>
                          </div>
                        </Accordion.Item>
                      ))
                    : listGoalsFiltered.map((goal, g) => (
                        <Accordion.Item
                          eventKey={goal.id}
                          key={g}
                          className={`accordion-item--${goal.primaryColor}`}
                        >
                          <Accordion.Header className="klimax-goal__item">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: goal.title,
                              }}
                            />
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              <li>{goal.subgoal1}</li>
                              <li>{goal.subgoal2}</li>
                              <li>{goal.subgoal3}</li>
                            </ul>
                          </Accordion.Body>
                          <div className="check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              onClick={(e) =>
                                handlePinToggle(
                                  goal,
                                  e.target.checked ? false : true
                                )
                              }
                              checked={
                                pinnedGoals.includes(goal) ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            ></label>
                          </div>
                        </Accordion.Item>
                      ))}
                </Accordion>

                <div className="klimax-goals-buttons">
                  <button
                    className="klimax-button"
                    onClick={() => handleRemoveAll()}
                  >
                    <i className="icon-klimax-delete" /> <span>Wis alle</span>
                  </button>
                  <button
                    className="klimax-button"
                    onClick={() => handleSelectAll()}
                  >
                    <i className="icon-klimax-select-all" />{" "}
                    <span>Selecteer alle</span>
                  </button>

                  <a
                    className="klimax-button"
                    href={DocGoals}
                    target="_blank"
                  >
                    <i className="icon-klimax-download" />{" "}
                    <span>Download competentielijst</span>
                  </a>

                  <Link
                    to="/"
                    className="klimax-button klimax-button--inverted"
                  >
                    Bevestig
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  pinnedGoals: state.pinnedGoals,
  pinnedCards: state.pinnedCards,
  goalMethodCategory: state.goalMethodCategory,
});

const mapDispatchToProps = (dispatch) => ({
  addPinnedGoal: (pinnedGoal) => dispatch(addPinnedGoal(addPinnedGoal)),
  removePinnedGoal: (pinnedGoal, index) =>
    dispatch(removePinnedGoal(removePinnedGoal, index)),
  removeAllPinnedGoals: () =>
    dispatch(removeAllPinnedGoals(removeAllPinnedGoals)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Goals);
