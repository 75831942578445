import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Error = (props) => {
  return (
    <div className="error">
      <div className="container">
        <div className="error__logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 609.3 271">
            <path
              id="Path_2370"
              class="st1"
              d="M95.8,59.8c41.2-5.3,68.3,20.8,67.9,59.2s-29.2,71.9-70.4,77.2c-41.1,5.3-68.5-20.9-68.1-59.4
        C25.7,98.4,54.8,65.1,95.8,59.8 M94.7,167.3c23.8-3.1,37.6-21.3,37.8-43.2c0.2-22.9-13-38.5-37.3-35.4
        c-23.9,3.1-38.4,21.4-38.6,43.2C56.4,154.6,70.2,170.5,94.7,167.3"
            />
            <path
              id="Path_2371"
              class="st1"
              d="M247.6,40.3c41.2-5.3,68.3,20.8,67.9,59.2c-0.4,38.4-29.2,71.9-70.4,77.2
        c-41.1,5.3-68.5-20.9-68.1-59.4C177.4,78.9,206.5,45.6,247.6,40.3 M246.5,147.8c23.8-3.1,37.6-21.3,37.8-43.2
        c0.2-22.9-13-38.5-37.3-35.4c-23.9,3.1-38.4,21.4-38.6,43.2C208.1,135.1,221.9,151,246.5,147.8"
            />
            <path
              id="Path_2372"
              class="st1"
              d="M328.8,52.2c-0.8-1.2-1-4.2-0.9-7.4c0.1-5.3,0.8-11.3,1.9-12.1c0.9-0.6,11.5-2.5,20.9-3.7
        l26.2-3.4c50.6-6.5,70.3,4.7,70,33.9c-0.3,34.8-34.6,50.7-75.1,56.4l-0.2,19.6c4.2,0,8.3,0.4,12.4,1c0.7,1.2,1,4.2,1,7.4
        c-0.1,5.3-0.9,11.3-2,12.1c-0.9,0.6-12,2.5-21.6,3.8l-12.2,1.6c-9.4,1.2-19.9,2.1-20.8,1.7c-1-0.5-1.7-6.3-1.6-11.6
        c0-3.2,0.3-6.2,1.1-7.7c4-1.7,8.2-3.1,12.4-4.2l0.8-86.2C337,53.3,332.9,52.9,328.8,52.2 M378.9,54.3l-6.6,0.9l-0.3,32l6.6-0.9
        c24-3.1,36.9-9.7,37-20.3C415.8,54.4,402.5,51.3,378.9,54.3"
            />
            <path
              id="Path_2373"
              class="st1"
              d="M467.3,102.8c8.6-3.6,11.7-3.8,20.3-4.4c1.1,1.9,3.6,9.6,5.1,17c3.2,0,6.4-0.2,9.6-0.6
        c16.6-2.1,22.6-8.2,22.7-14.6c0.2-19.3-64.3-3.4-63.8-49.3C461.4,21,491.1,9,514.6,6c11.4-1.5,22.9-1.1,34.2,1.2l-0.1,10.3
        c-0.1,11-1.6,27.8-2.6,28.9c-8.6,3.6-11.8,3.8-20.3,4.4c-1.2-1.8-3.7-9.6-5.2-17.1c-1.3,0.1-2.7,0.2-3.9,0.4
        c-11.4,1.5-24.4,5.4-24.5,13.1c-0.2,18.9,64.4,6.3,64.1,47.8c-0.3,30.5-29.5,45.5-57.3,49.1c-13.8,1.8-26.5,0.1-34.3-5.7l0.1-6.8
        C464.7,120.5,466.2,103.9,467.3,102.8"
            />
            <path
              id="Path_2374"
              class="st1"
              d="M91.1,202.8c-15.6,0-29.4-4.8-39.7-14c-12.4-11-19.1-27.6-18.9-46.8
        c0.4-39.8,30.3-72.4,71.1-77.7c20-2.6,37.2,2,49.9,13.2c12.3,10.9,19,27.5,18.8,46.7c-0.4,39.9-30.2,72.6-70.9,77.8
        C97.9,202.6,94.5,202.8,91.1,202.8 M103.7,65l0.1,0.6c-40.2,5.2-69.6,37.3-70,76.4c-0.2,18.8,6.4,35,18.5,45.8
        c12.2,10.9,29.6,15.5,48.9,13c40.1-5.2,69.4-37.4,69.8-76.6c0.2-18.8-6.3-35-18.4-45.7c-12.3-10.9-29.2-15.4-48.8-12.9L103.7,65z
        M96.5,173.6c-8.6,0-16.1-2.6-21.7-7.6c-7.3-6.5-11.2-16.7-11.1-29c0.2-23.2,16-40.8,39.2-43.8c11.1-1.4,20.4,1,27.1,6.9
        c7.2,6.4,11,16.7,10.9,29.1c-0.2,23.7-15.3,40.9-38.3,43.9C100.6,173.4,98.5,173.6,96.5,173.6 M108.9,94.2c-2,0-3.9,0.1-5.9,0.4
        c-22.5,2.9-37.8,20-38,42.5c-0.1,11.8,3.7,21.8,10.7,28c6.6,5.9,15.9,8.3,26.8,6.9c22.4-2.9,37-19.6,37.2-42.6
        c0.1-12-3.6-22-10.5-28.1C123.9,96.5,117,94.2,108.9,94.2"
            />
            <path
              id="Path_2375"
              class="st1"
              d="M242.8,183.3c-15.6,0-29.4-4.8-39.7-14c-12.4-11-19.1-27.6-18.9-46.8
        c0.4-39.8,30.3-72.4,71.1-77.7c20-2.6,37.2,2,49.9,13.2c12.3,10.9,19,27.5,18.8,46.7c-0.4,39.9-30.2,72.6-70.9,77.8
        C249.7,183,246.2,183.3,242.8,183.3 M255.5,46.2c-40.2,5.2-69.6,37.3-70,76.4c-0.2,18.8,6.4,35,18.5,45.8
        c12.2,10.9,29.6,15.5,48.9,13c40.1-5.2,69.4-37.4,69.8-76.6c0.2-18.8-6.3-35-18.4-45.7C292,48.1,275.1,43.6,255.5,46.2 M248.3,154.1
        c-8.6,0-16.1-2.6-21.7-7.6c-7.3-6.5-11.2-16.7-11.1-29c0.2-23.2,16-40.8,39.2-43.8c11.1-1.4,20.4,1,27.1,6.9
        c7.2,6.4,11,16.7,10.9,29.1c-0.2,23.7-15.3,40.9-38.3,43.9C252.3,153.9,250.3,154.1,248.3,154.1 M260.7,74.6c-2,0-3.9,0.1-5.9,0.4
        c-22.5,2.9-37.8,20-38,42.5c-0.1,11.8,3.7,21.8,10.7,28c6.6,5.9,15.9,8.3,26.8,6.9c22.4-2.9,37-19.6,37.2-42.6
        c0.1-12-3.6-22-10.5-28.1C275.7,77,268.8,74.6,260.7,74.6"
            />
            <path
              id="Path_2376"
              class="st1"
              d="M337.5,169c-0.5,0-1,0-1.5-0.1c-2.1-1-2-12.1-2-12.2c0-4.1,0.4-6.7,1.2-8l0.1-0.2l0.2-0.1
        c3.9-1.7,7.9-3,12-4.1l0.8-85.1c-3.9-0.1-7.8-0.4-11.7-1.1l-0.3-0.1l-0.1-0.2c-0.8-1.2-1.1-3.5-1.1-7.8c0-3.1,0.5-11.4,2.1-12.6
        c1.2-0.8,13.1-2.8,21.2-3.8l26.2-3.4c31-4,50.4-1.4,61,8c6.7,5.9,9.9,14.6,9.7,26.5c-0.4,38.1-40.9,52-75.1,56.9l-0.2,18.4
        c4,0,8,0.4,11.9,1l0.3,0.1l0.2,0.3c1,1.6,1.1,5.5,1,7.7c0,3.6-0.6,11.5-2.2,12.6c-1.2,0.8-13.8,2.9-21.9,3.9l-12.2,1.6
        C350.5,168.1,341.2,169,337.5,169 M336.1,149.5c-0.6,1.2-0.9,3.5-0.9,7.2c-0.1,6,0.7,10.5,1.3,11c0.9,0.2,10.8-0.5,20.4-1.8
        l12.2-1.6c9.9-1.3,20.4-3.2,21.3-3.7c0.6-0.5,1.6-5.6,1.7-11.5c0.1-2.3-0.1-4.6-0.7-6.8c-4-0.6-8-0.9-12-0.9l-0.6,0l0.2-20.8
        l0.6-0.1c33.8-4.8,74.1-18.3,74.5-55.7c0.1-11.5-2.9-19.9-9.3-25.6c-10.3-9.2-29.4-11.6-60-7.7l-26.2,3.4
        c-9.8,1.3-19.7,3.1-20.6,3.6c-0.6,0.5-1.5,5.3-1.6,11.5c0,3.5,0.2,5.8,0.7,6.8c3.9,0.7,7.9,1,11.8,1.1l0.6,0l-0.8,87.3l-0.5,0.1
        C344.1,146.5,340.1,147.8,336.1,149.5 M379.1,93.1l0.3-33.3l7.2-0.9c17.5-2.3,28.6-1.1,33.9,3.7c2.5,2.2,3.8,5.4,3.6,8.7
        c-0.1,10.8-12.4,17.7-37.6,20.9L379.1,93.1z M380.7,60.9l-0.3,30.7l5.9-0.8c24.1-3.1,36.4-9.7,36.5-19.7c0.2-2.9-1-5.8-3.2-7.7
        c-5-4.4-15.7-5.5-32.8-3.3L380.7,60.9z"
            />
            <path
              id="Path_2377"
              class="st1"
              d="M496.5,150.6c-10.2,0-18.7-2.2-24.5-6.5l-0.3-0.2l0.1-7.2c0.1-9.9,1.5-27.8,2.8-29.2l0.2-0.2
        c8.6-3.6,11.8-3.9,20.3-4.4l0.6,0l0.2,0.3c1.1,1.9,3.5,9.4,5.1,16.7c3,0,6-0.2,9-0.6c14.2-1.8,22.1-6.8,22.1-14
        c0.1-7-9.4-9.1-21.4-11.7c-19-4.1-42.7-9.2-42.5-37.7c0.1-13.6,6.3-24.7,18.3-32.9c9.2-6.3,21.9-10.7,35.7-12.5
        c11.5-1.5,23.1-1.1,34.4,1.2l0.5,0.1l-0.1,10.9c-0.1,10-1.5,27.9-2.8,29.3l-0.2,0.2c-8.7,3.6-11.9,3.9-20.4,4.5l-0.4,0l-0.3-0.3
        c-1.3-2-3.7-9.7-5.2-16.7c-1.1,0.1-2.3,0.2-3.3,0.4c-5.6,0.7-23.9,3.7-23.9,12.4C500.6,59.5,511,62,523,64.9
        c18.7,4.5,41.9,10.1,41.7,35.3c-0.1,14.1-6.6,26.2-18.6,35.2c-10,7.4-24,12.6-39.3,14.6C503.4,150.4,499.9,150.6,496.5,150.6
        M473,143.3c7.3,5.2,19.5,7.2,33.6,5.4c27.2-3.5,56.5-18.1,56.8-48.4c0.2-24.2-22.5-29.7-40.7-34.1c-12.6-3-23.4-5.6-23.3-13.7
        c0.1-9.5,17.6-12.8,25.1-13.7c1.2-0.2,2.6-0.3,3.9-0.4l0.6,0l0.1,0.5c1.5,7.2,3.8,14.3,5,16.5c8.1-0.6,11.3-0.8,19.5-4.3
        c0.9-1.8,2.3-17.2,2.4-28.4l0.1-9.8c-11-2.2-22.3-2.6-33.4-1.1c-48.8,6.3-52.9,35.4-52.9,44.1c-0.3,27.4,22.9,32.4,41.5,36.4
        c12.1,2.6,22.5,4.8,22.4,12.9c0,4.6-3.1,12.7-23.3,15.3c-3.2,0.4-6.4,0.6-9.7,0.6h-0.5l-0.1-0.5c-1.5-7.3-3.8-14.3-4.9-16.4
        c-8.1,0.6-11.2,0.8-19.4,4.3c-0.9,1.9-2.3,17.2-2.4,28.3L473,143.3z"
            />
            <g class="st2">
              <path
                class="st3"
                d="M4,240.3l9.3-1.1c4.2-0.5,7.7,0.5,10.4,2.5c2.6,2,4.3,5.1,4.8,8.5c0.4,3.2-0.3,6.5-2.2,9.2
            c-1.9,2.7-5,4.7-9.6,5.3l-9.5,1.2L4,240.3z M12.3,259.6l2.5-0.3c3.1-0.4,5.1-1.7,6.3-3.3c1.2-1.6,1.6-3.5,1.4-5
            c-0.2-1.8-1.3-3.5-3-4.6c-1.7-1.1-4-1.8-6.8-1.4l-2.2,0.3L12.3,259.6z"
              />
              <path
                class="st3"
                d="M49.2,254.9l0.6,4.8c-1,0.6-2.1,1.1-3.3,1.5c-1.1,0.4-2.2,0.6-3.1,0.7c-2.7,0.3-5.4-0.2-7.6-1.7
            c-2.2-1.5-3.7-4-4.2-7.7c-0.4-2.9,0.1-5.5,1.5-7.6c1.3-2,3.6-3.4,6.7-3.8c3.2-0.4,5.4,0.5,6.8,2c1.4,1.5,2.1,3.6,2.3,5.6l0.4,3.3
            l-11.5,1.4c0.8,2.8,2.7,4.2,5.1,3.9c0.9-0.1,2.2-0.5,3.3-1C47.3,255.9,48.5,255.3,49.2,254.9L49.2,254.9z M37.5,249.1l5.6-0.7
            c-0.2-0.8-0.5-1.6-1-2.1c-0.5-0.5-1-0.8-1.8-0.7c-0.7,0.1-1.3,0.5-1.8,1.1C38.1,247.3,37.7,248.2,37.5,249.1z"
              />
              <path
                class="st3"
                d="M60.1,243.7l-8.8,1.1l-0.6-4.7l17.6-2.2l0.3,0.3l-7.4,16.3l8.8-1.1l0.6,4.7l-17.7,2.2l-0.3-0.2L60.1,243.7z"
              />
              <path
                class="st3"
                d="M89.8,249.9l0.6,4.8c-1,0.6-2.1,1.1-3.3,1.5c-1.1,0.4-2.2,0.6-3.1,0.7c-2.7,0.3-5.4-0.2-7.6-1.7
            c-2.2-1.5-3.7-4-4.2-7.7c-0.4-2.9,0.1-5.5,1.5-7.6c1.3-2,3.6-3.4,6.7-3.8c3.2-0.4,5.4,0.5,6.8,2c1.4,1.5,2.1,3.6,2.3,5.6l0.4,3.3
            l-11.5,1.4c0.8,2.8,2.7,4.2,5.1,3.9c0.9-0.1,2.2-0.5,3.3-1C87.9,250.9,89,250.3,89.8,249.9L89.8,249.9z M78.1,244.1l5.6-0.7
            c-0.2-0.8-0.5-1.6-1-2.1c-0.5-0.5-1-0.8-1.8-0.7c-0.7,0.1-1.3,0.5-1.8,1.1C78.6,242.3,78.3,243.2,78.1,244.1z"
              />
              <path
                class="st3"
                d="M107.9,233.1l1,1.4c1.3-1.3,2.9-2.3,5.2-2.6c2.6-0.3,5.1,0.4,7.1,2c1.9,1.6,3.3,4,3.7,7.1c0.4,3-0.3,5.8-2,7.9
            s-4.3,3.6-7.8,4.1c-1,0.1-2,0.1-3,0.1l1.1,8.7l-5.9,0.7l-3.6-29L107.9,233.1z M110.4,238.9l1.1,9.2c1,0.3,2,0.2,2.7,0.1
            c1.6-0.2,2.8-0.9,3.6-2.1c0.8-1.2,1.1-2.7,0.9-4.6c-0.2-1.5-0.7-2.8-1.5-3.6c-0.8-0.9-2-1.3-3.6-1.1
            C112.2,237,111.1,237.7,110.4,238.9z"
              />
              <path
                class="st3"
                d="M148,244.9l-2.1,4.3c-1.6,0.2-2.8,0.1-3.7-0.1c-0.9-0.3-1.6-0.8-2.2-1.5c-1.2,1.6-3.1,2.7-5.5,3
            c-1.3,0.2-3.1,0.1-4.5-0.7c-1.5-0.8-2.6-2.2-2.9-4.8c-0.2-1.9,0.1-3.5,1.3-4.6c1.1-1.2,3-1.9,5.8-2.3c1-0.1,2-0.2,3.5-0.3l0-0.1
            c-0.2-1.5-0.7-2.5-1.4-3c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-2.1,0.5-3.3,1c-1.1,0.5-2.2,1-2.9,1.5l-1.8-4.3
            c2.4-1.4,4.9-2.5,7.8-2.9c1.8-0.2,4-0.2,5.9,0.6c1.9,0.8,3.4,2.3,4,5l0.8,6.8c0.1,0.9,0.2,1.5,0.7,2
            C145.6,244.5,146.4,244.8,148,244.9z M138.2,241.4l-1.7,0.2c-0.8,0.1-1.7,0.3-2.4,0.6c-0.7,0.4-1.1,1-1,2c0.1,0.9,0.6,1.3,1.3,1.5
            c0.6,0.2,1.3,0.2,1.7,0.2c0.5-0.1,1.2-0.3,1.7-0.7c0.5-0.4,0.8-0.9,0.8-1.3L138.2,241.4z"
              />
              <path
                class="st3"
                d="M166.1,228.7l-0.1,0.3c-0.9,0.3-1.9,0.7-3,1.4c0.4,0.7,0.6,1.4,0.7,2.3c0.2,1.9-0.3,3.7-1.5,5
            c-1.2,1.4-2.9,2.3-5.2,2.6c-0.5,0.1-1,0.1-1.4,0.1c-0.1,0.4-0.2,0.7-0.2,1c0.1,0.4,0.3,0.8,0.9,0.9c0.6,0.2,1.5,0.2,2.9,0
            c2.8-0.3,4.9,0.1,6.3,0.9c1.4,0.8,2.2,2.1,2.4,3.4c0.3,2.3-0.8,4.5-2.6,6.2c-1.8,1.7-4.3,2.9-6.8,3.2c-2.3,0.3-4.2-0.2-5.7-1.2
            c-1.4-1-2.3-2.5-2.6-4.3c-0.2-1.6,0.4-3.7,1.8-5.4c-1.2-0.5-1.9-1.4-2-2.5c-0.1-1.1,0.2-2.4,1-3.7c-1.3-1-2.1-2.5-2.3-4.2
            c-0.2-1.9,0.3-3.7,1.5-5c1.2-1.4,2.9-2.3,5.1-2.6c1.3-0.2,2.6-0.1,3.7,0.3c1.8-1.2,3.6-2,5-2.1L166.1,228.7z M153.9,233.8
            c0.2,1.5,1.1,2.2,2.8,2c1.6-0.2,2.3-1.1,2.1-2.5c-0.2-1.5-1-2.2-2.7-2C154.4,231.5,153.8,232.4,153.9,233.8z M158,246.8l-1.2,0.2
            c-0.5,0.8-0.8,1.6-0.7,2.4c0.2,1.3,1,2.2,3,2c1.1-0.1,2.1-0.6,2.7-1.2c0.6-0.6,0.9-1.3,0.8-2c-0.1-0.9-0.6-1.2-1.5-1.4
            C160.3,246.6,159.2,246.7,158,246.8z"
              />
              <path
                class="st3"
                d="M168,220.4c-0.1-0.9,0.1-1.7,0.7-2.4s1.3-1.1,2.2-1.3c0.9-0.1,1.8,0.2,2.4,0.7s1.1,1.3,1.3,2.2
            c0.1,0.9-0.2,1.8-0.7,2.4c-0.5,0.7-1.3,1.1-2.2,1.2c-0.9,0.1-1.8-0.1-2.4-0.7C168.5,222.1,168.1,221.3,168,220.4z M174.9,224.9
            l2.5,20.1l-5.9,0.7l-2.5-20.1L174.9,224.9z"
              />
              <path
                class="st3"
                d="M184.2,223.7l1,1.9c1.1-0.9,2.1-1.7,3.2-2.2c1.1-0.5,2.2-0.9,3.6-1c4.5-0.5,7.1,1.4,7.8,6.8l1.6,12.9l-5.9,0.7
            l-1.3-10.9c-0.4-3.4-1.7-4.7-4-4.4c-1.2,0.1-2.2,0.6-3.5,1.7l1.8,14.5l-5.9,0.7l-2.5-20.1L184.2,223.7z"
              />
              <path
                class="st3"
                d="M224.7,235.5l-2.1,4.3c-1.6,0.2-2.8,0.1-3.7-0.1c-0.9-0.3-1.6-0.8-2.2-1.5c-1.2,1.6-3.1,2.7-5.5,3
            c-1.3,0.2-3.1,0.1-4.5-0.7c-1.5-0.8-2.6-2.2-2.9-4.8c-0.2-1.9,0.1-3.5,1.3-4.6c1.1-1.2,3-1.9,5.8-2.3c1-0.1,2-0.2,3.5-0.3l0-0.1
            c-0.2-1.5-0.7-2.5-1.4-3c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-2.1,0.5-3.3,1c-1.1,0.5-2.2,1-2.9,1.5l-1.8-4.3
            c2.4-1.4,4.9-2.5,7.8-2.9c1.8-0.2,4-0.2,5.9,0.6c1.9,0.8,3.4,2.3,4,5l0.8,6.8c0.1,0.9,0.2,1.5,0.7,2
            C222.3,235.1,223.1,235.3,224.7,235.5z M214.9,232l-1.7,0.2c-0.8,0.1-1.7,0.3-2.4,0.6c-0.7,0.4-1.1,1-1,2c0.1,0.9,0.6,1.3,1.3,1.5
            c0.6,0.2,1.3,0.2,1.7,0.2c0.5-0.1,1.2-0.3,1.7-0.7c0.5-0.4,0.8-0.9,0.8-1.3L214.9,232z"
              />
              <path
                class="st3"
                d="M261.8,234.6l-6,0.7l-4.2-9.3l-2,10.1l-6,0.7l-8.7-19.4l6.3-0.8l4.6,10l1.7-11.2l5.5-0.7l4.4,10.4l2.1-10.8
            l6.3-0.8L261.8,234.6z"
              />
              <path
                class="st3"
                d="M286,225.8l0.6,4.8c-1,0.6-2.1,1.1-3.3,1.5c-1.1,0.4-2.2,0.6-3.1,0.7c-2.7,0.3-5.4-0.2-7.6-1.7
            c-2.2-1.5-3.7-4-4.2-7.7c-0.4-2.9,0.1-5.5,1.5-7.6c1.3-2,3.6-3.4,6.7-3.8c3.2-0.4,5.4,0.5,6.8,2c1.4,1.5,2.1,3.6,2.3,5.6l0.4,3.3
            l-11.5,1.4c0.8,2.8,2.7,4.2,5.1,3.9c1-0.1,2.2-0.5,3.3-1C284.1,226.8,285.2,226.2,286,225.8L286,225.8z M274.3,220l5.6-0.7
            c-0.2-0.8-0.5-1.6-1-2.1c-0.5-0.5-1-0.8-1.8-0.7c-0.7,0.1-1.3,0.5-1.8,1.1C274.8,218.2,274.5,219.1,274.3,220z"
              />
              <path
                class="st3"
                d="M292.6,210.4l0.9,1.9c1.3-1.7,2.6-2.7,4.5-2.9c1.5-0.2,2.8,0.2,3.7,0.4l-0.8,4.8c-0.8-0.3-1.8-0.4-2.6-0.3
            c-1.4,0.2-2.5,0.9-3.3,2l1.7,14l-5.9,0.7l-2.5-20.1L292.6,210.4z"
              />
              <path
                class="st3"
                d="M320.3,227.4l-1-1.5c-1.3,1.3-2.9,2.3-5.2,2.6c-2.6,0.3-5.1-0.4-7.1-2c-1.9-1.6-3.3-4-3.7-7.1
            c-0.4-3,0.3-5.8,2-7.9c1.6-2.1,4.3-3.6,7.7-4c1.1-0.1,2.1-0.1,3.1-0.1l-0.9-7.3l5.1-1.2l0.8-0.1l3.4,28.1L320.3,227.4z
            M317.8,221.6l-1.1-9.2c-1-0.3-2-0.2-2.7-0.1c-1.6,0.2-2.8,0.9-3.6,2c-0.8,1.1-1.1,2.7-0.9,4.5c0.2,1.5,0.7,2.8,1.5,3.6
            c0.8,0.9,2,1.4,3.6,1.2C316,223.5,317.2,222.8,317.8,221.6z"
              />
              <path
                class="st3"
                d="M342.7,204.3l1,1.9c1.1-0.9,2.1-1.7,3.2-2.2c1.1-0.5,2.2-0.9,3.6-1c4.5-0.5,7.1,1.4,7.8,6.8l1.6,12.9l-5.9,0.7
            l-1.3-10.9c-0.4-3.4-1.7-4.7-4-4.4c-1.2,0.1-2.2,0.6-3.5,1.7l1.8,14.5l-5.9,0.7l-2.5-20.1L342.7,204.3z"
              />
              <path
                class="st3"
                d="M361.1,196.7c-0.1-0.9,0.1-1.7,0.7-2.4c0.5-0.7,1.3-1.1,2.2-1.3c0.9-0.1,1.8,0.2,2.4,0.7
            c0.7,0.5,1.1,1.3,1.3,2.2s-0.2,1.8-0.7,2.4c-0.5,0.7-1.3,1.1-2.2,1.2c-0.9,0.1-1.8-0.1-2.4-0.7
            C361.7,198.3,361.2,197.6,361.1,196.7z M368.1,201.1l2.5,20.1l-5.9,0.7l-2.5-20.1L368.1,201.1z"
              />
              <path
                class="st3"
                d="M390.8,212.9l0.6,4.8c-1,0.7-2.1,1.1-3.3,1.5c-1.1,0.4-2.2,0.6-3.1,0.7c-2.7,0.3-5.4-0.2-7.6-1.7
            c-2.2-1.5-3.7-4-4.2-7.7c-0.4-2.9,0.1-5.5,1.5-7.6c1.3-2,3.6-3.4,6.7-3.8c3.2-0.4,5.4,0.5,6.8,2c1.4,1.5,2.1,3.6,2.3,5.6l0.4,3.3
            l-11.5,1.4c0.8,2.8,2.7,4.2,5.1,3.9c0.9-0.1,2.2-0.5,3.3-1C388.9,213.9,390,213.4,390.8,212.9L390.8,212.9z M379.1,207.2l5.6-0.7
            c-0.2-0.8-0.5-1.6-1-2.1c-0.5-0.5-1-0.8-1.8-0.7c-0.7,0.1-1.3,0.5-1.8,1.1C379.6,205.4,379.3,206.2,379.1,207.2z"
              />
              <path
                class="st3"
                d="M398.9,193.2l0.5,4.1l5.4-0.7l0.5,4.3l-5.4,0.7l0.8,6.3c0.2,2,0.8,3.3,1.4,3.9c0.7,0.7,1.5,0.9,2.5,0.7
            c1-0.1,2.1-0.7,3.2-1.6l2.3,3.9c-1.9,1.4-4.1,2.4-6.4,2.7c-2.1,0.3-4.1-0.1-5.7-1.2c-1.6-1.1-2.7-2.9-3.1-5.7l-1-8.3l-2,0.2
            l-0.5-4.3l2-0.2l-0.4-3.6l5.6-1.2L398.9,193.2z"
              />
              <path
                class="st3"
                d="M439.6,195.1l-0.1,0.3c-0.9,0.3-1.9,0.7-3,1.4c0.4,0.7,0.6,1.4,0.7,2.3c0.2,1.9-0.3,3.7-1.5,5
            c-1.2,1.4-2.9,2.3-5.2,2.6c-0.5,0.1-1,0.1-1.4,0.1c-0.1,0.4-0.2,0.7-0.2,1c0.1,0.4,0.3,0.8,0.9,0.9c0.6,0.2,1.5,0.2,2.9,0
            c2.8-0.3,4.9,0.1,6.3,0.9c1.4,0.8,2.2,2.1,2.4,3.4c0.3,2.3-0.8,4.5-2.6,6.2c-1.8,1.7-4.3,2.9-6.8,3.2c-2.3,0.3-4.2-0.2-5.7-1.2
            c-1.4-1-2.3-2.5-2.6-4.3c-0.2-1.6,0.4-3.7,1.8-5.4c-1.2-0.5-1.9-1.4-2-2.5c-0.1-1.1,0.2-2.4,1-3.7c-1.3-1-2.1-2.5-2.3-4.2
            c-0.2-1.9,0.3-3.7,1.5-5c1.2-1.4,2.9-2.3,5.1-2.6c1.3-0.2,2.6-0.1,3.7,0.3c1.8-1.2,3.6-2,5-2.1L439.6,195.1z M427.4,200.2
            c0.2,1.5,1.1,2.2,2.8,2c1.6-0.2,2.3-1.1,2.1-2.5c-0.2-1.5-1-2.2-2.7-2C427.9,197.9,427.3,198.8,427.4,200.2z M431.5,213.2l-1.2,0.2
            c-0.5,0.8-0.8,1.6-0.7,2.4c0.2,1.3,1,2.2,3,2c1.1-0.1,2.1-0.6,2.7-1.2c0.6-0.6,0.9-1.3,0.8-2c-0.1-0.9-0.6-1.2-1.4-1.4
            C433.8,213,432.6,213.1,431.5,213.2z"
              />
              <path
                class="st3"
                d="M459.4,204.5l0.6,4.8c-1,0.6-2.1,1.1-3.3,1.5c-1.1,0.4-2.2,0.6-3.1,0.7c-2.7,0.3-5.4-0.2-7.6-1.7
            c-2.2-1.5-3.7-4-4.2-7.7c-0.4-2.9,0.1-5.5,1.5-7.6c1.3-2,3.6-3.4,6.7-3.8c3.2-0.4,5.4,0.5,6.8,2c1.4,1.5,2.1,3.6,2.3,5.6l0.4,3.3
            l-11.5,1.4c0.8,2.8,2.7,4.2,5.1,3.9c0.9-0.1,2.2-0.5,3.3-1S458.7,204.9,459.4,204.5L459.4,204.5z M447.7,198.7l5.6-0.7
            c-0.2-0.8-0.5-1.6-1-2.1c-0.5-0.5-1-0.8-1.8-0.7c-0.7,0.1-1.3,0.5-1.8,1.1C448.3,196.9,447.9,197.8,447.7,198.7z"
              />
              <path
                class="st3"
                d="M475.3,208.4l-7.2,0.9l-9.3-19.3l6.3-0.8l5.6,11.9l2.6-12.9l6.2-0.8L475.3,208.4z"
              />
              <path
                class="st3"
                d="M481.8,197.4c-0.4-3.1,0.3-5.8,2-7.9c1.6-2.1,4.2-3.5,7.5-3.9c3.3-0.4,6.1,0.4,8.1,2c2.1,1.6,3.4,4.1,3.7,7.1
            c0.4,3.1-0.3,5.8-2,7.9c-1.7,2.1-4.2,3.5-7.5,3.9c-3.3,0.4-6-0.3-8.1-2C483.6,202.9,482.2,200.5,481.8,197.4z M487.9,196.6
            c0.2,1.6,0.8,3,1.7,3.9c0.9,1,2.2,1.5,3.7,1.3c1.5-0.2,2.5-0.9,3.2-2c0.6-1.1,0.8-2.5,0.7-4.1c-0.2-1.6-0.8-3-1.6-3.9
            c-0.9-1-2.1-1.5-3.7-1.3c-1.5,0.2-2.6,1-3.2,2C487.9,193.6,487.7,195,487.9,196.6z"
              />
              <path
                class="st3"
                d="M510.1,183.7l1,1.9c1.1-0.9,2.1-1.7,3.2-2.2c1.1-0.5,2.2-0.9,3.6-1c4.5-0.5,7.1,1.4,7.8,6.8l1.6,12.9l-5.9,0.7
            l-1.3-10.9c-0.4-3.4-1.7-4.7-4-4.4c-1.2,0.1-2.2,0.6-3.5,1.7l1.8,14.5l-5.9,0.7l-2.5-20.1L510.1,183.7z"
              />
              <path
                class="st3"
                d="M546.5,199.7l-1-1.5c-1.3,1.3-2.9,2.3-5.2,2.6c-2.6,0.3-5.1-0.4-7.1-2c-1.9-1.6-3.3-4-3.7-7.1
            c-0.4-3,0.3-5.8,2-7.9c1.6-2.1,4.3-3.6,7.7-4c1.1-0.1,2.1-0.1,3.1-0.1l-0.9-7.3l5.1-1.2l0.8-0.1l3.4,28.1L546.5,199.7z M544,193.8
            l-1.1-9.2c-1-0.3-2-0.2-2.7-0.1c-1.6,0.2-2.8,0.9-3.6,2c-0.8,1.1-1.1,2.7-0.9,4.5c0.2,1.5,0.7,2.8,1.5,3.6c0.8,0.9,2,1.4,3.6,1.2
            C542.2,195.7,543.4,195,544,193.8z"
              />
              <path
                class="st3"
                d="M570.7,190.8l0.6,4.8c-1,0.7-2.1,1.1-3.3,1.5c-1.1,0.4-2.2,0.6-3.1,0.7c-2.7,0.3-5.4-0.2-7.6-1.7
            c-2.2-1.5-3.7-4-4.2-7.7c-0.4-2.9,0.1-5.5,1.5-7.6c1.3-2,3.6-3.4,6.7-3.8c3.2-0.4,5.4,0.5,6.8,2c1.4,1.5,2.1,3.6,2.3,5.6l0.4,3.3
            l-11.5,1.4c0.8,2.8,2.7,4.2,5.1,3.9c1-0.1,2.2-0.5,3.3-1S569.9,191.3,570.7,190.8L570.7,190.8z M559,185.1l5.6-0.7
            c-0.2-0.8-0.5-1.6-1-2.1c-0.5-0.5-1-0.8-1.8-0.7c-0.7,0.1-1.3,0.5-1.8,1.1C559.5,183.3,559.2,184.1,559,185.1z"
              />
              <path
                class="st3"
                d="M577.4,175.4l1,1.9c1.1-0.9,2.1-1.7,3.2-2.2c1.1-0.5,2.2-0.9,3.6-1c4.5-0.5,7.1,1.4,7.8,6.8l1.6,12.9l-5.9,0.7
            l-1.3-10.9c-0.4-3.4-1.7-4.7-4-4.4c-1.2,0.1-2.2,0.6-3.5,1.7l1.8,14.5l-5.9,0.7L573,176L577.4,175.4z"
              />
              <path
                class="st3"
                d="M598.6,190.3c-0.1-0.9,0.2-1.8,0.7-2.5c0.5-0.7,1.3-1.1,2.2-1.3s1.8,0.2,2.5,0.7c0.7,0.5,1.2,1.3,1.3,2.2
            c0.1,0.9-0.2,1.8-0.7,2.5c-0.5,0.7-1.3,1.2-2.3,1.3s-1.8-0.2-2.5-0.7C599.2,192,598.7,191.2,598.6,190.3z"
              />
            </g>
          </svg>
        </div>
        <div className="error__button">
          <Link to="/" className="klimax-button klimax-button--inline">
            <i className="icon-klimax-grid" />
            terug naar overzicht
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
