import React, {useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";
import {connect, useDispatch} from "react-redux";
import {
    addPinnedCard,
    changeModalState,
    flipCards,
    removePinnedCard,
} from "../../redux/actions/actions";
import Dropdown from "react-bootstrap/Dropdown";
import {Link, useLocation} from "react-router-dom";

const ThemeCard = (props) => {
    // meegekregen uit een pagina waar deze component wordt opgeroepen
    const flipCard = props.card;

    // meegekregen props uit reducer én uit parent
    const {pinnedCards, key, cards, modalState} = props;

    const themeCategory = props.themeCategories.filter((category) => {
        return category.id === flipCard.themeCatId;
    })[0];

    // Nodig om error te voorkomen; don't ask why
    const nodeRef = React.useRef(null);
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            className={`klimax-printcard klimax-printcard--${flipCard.sort}`}
            id={themeCategory.titleInCode}
            ref={nodeRef}
            aria-label="Klimaatkaart"
        >
            <div className="klimax-printcard-wrapper">
                <img
                    className="klimax-printcard-pic"
                    src={`https://oetang.be/klimax/assets/images/inspiratie/thema/${flipCard.pic1}`}
                    alt={`Foto ${flipCard.title}`}
                />
                <div className="klimax-printcard-content">
                    <div className="klimax-printcard-label">{themeCategory.title}</div>
                    <div
                        className="klimax-printcard-title"
                        dangerouslySetInnerHTML={{__html: flipCard.title}}
                    ></div>
                    <div
                        className="klimax-printcard-baseline"
                        dangerouslySetInnerHTML={{__html: flipCard.teaser}}
                    ></div>
                    <p
                        dangerouslySetInnerHTML={{__html: flipCard.description}}
                    ></p>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    cards: state.cards,
    modalState: state.modalState,
    themeCategories: state.themeCategory,
    pinnedCards: state.pinnedCards,
    state: state,
});
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching actions returned by action creators
        addPinnedCard: (pinnedCard) => dispatch(addPinnedCard(addPinnedCard)),
        changeModalState: (newModalState) =>
            dispatch(changeModalState(newModalState)),
        removePinnedCard: (pinnedCard, index) =>
            dispatch(removePinnedCard(removePinnedCard, index)),
        flipCards: (id) => dispatch(flipCards(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeCard);
