import React, {useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";
import {connect, useDispatch} from "react-redux";
import {
    addPinnedCard,
    changeModalState,
    flipCards,
    removePinnedCard,
} from "../../redux/actions/actions";
import {useLocation} from "react-router";

const MethodCard = (props) => {
    // meegekregen uit een pagina waar deze component wordt opgeroepen
    const flipCard = props.card;

    // meegekregen props uit reducer én uit parent
    const {pinnedCards, key, cards} = props;

    const goalMethodCategory = props.goalMethodCategories.filter((category) => {
        return category.id === flipCard.goalMethodCatId;
    })[0];

    // Nodig om error te voorkomen; don't ask why
    const nodeRef = React.useRef(null);

    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div
            className={`klimax-printcard klimax-printcard--method-${goalMethodCategory.primaryColor}`}
            id={goalMethodCategory.titleInCode}
            ref={nodeRef}
            aria-label="Klimaatkaart"
        >
            <div className="klimax-printcard-wrapper">
                <img
                    className="klimax-printcard-pic"
                    src={`https://oetang.be/klimax/assets/images/inspiratie/werkvorm/${flipCard.pic1}`}
                    alt={`Foto ${flipCard.title}`}
                />
                <div className="klimax-printcard-content">
                    <div className="klimax-printcard-label">
                        {goalMethodCategory.label}
                    </div>
                    <div
                        className="klimax-printcard-title"
                        dangerouslySetInnerHTML={{__html: flipCard.title}}
                    ></div>
                    <div
                        className="klimax-printcard-baseline"
                        dangerouslySetInnerHTML={{__html: flipCard.teaser}}
                    ></div>
                    <p
                        dangerouslySetInnerHTML={{__html: flipCard.description}}
                    ></p>
                    <ol>
                        {flipCard.explanation.map((step, i) => (
                            <li key={i} dangerouslySetInnerHTML={{ __html: step }}></li>
                        ))}
                    </ol>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    cards: state.cards,
    goalMethodCategories: state.goalMethodCategory,
    pinnedCards: state.pinnedCards,
    state: state,
    modalState: state.modalState,
});
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching actions returned by action creators
        addPinnedCard: (pinnedCard) => dispatch(addPinnedCard(addPinnedCard)),
        removePinnedCard: (pinnedCard, index) =>
            dispatch(removePinnedCard(removePinnedCard, index)),
        flipCards: (id) => dispatch(flipCards(id)),
        changeModalState: (newModalState) =>
            dispatch(changeModalState(newModalState)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MethodCard);
