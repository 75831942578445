import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { connect, useDispatch } from "react-redux";
import {
  addPinnedCard,
  changeModalState,
  flipCards,
  removePinnedCard,
} from "../redux/actions/actions";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation } from "react-router-dom";

const ThemeCard = (props) => {
  // meegekregen uit een pagina waar deze component wordt opgeroepen
  const flipCard = props.card;

  // meegekregen props uit reducer én uit parent
  const { pinnedCards, key, cards, modalState } = props;

  // Nodig om rerender van child component (nl. CSSTransition) te triggeren/forceren
  const [updatedKey, setUpdatedKey] = useState(
    cards.filter((el) => flipCard.id === el.id)[0].flipstate.toString() +
      flipCard.id.toString()
  );

  // Eén kaartje laten flippen + rerender van child component (nl. CSSTransition) triggeren
  const handleFlip = () => {
    dispatch(flipCards(flipCard.id));
    setUpdatedKey(
      cards.filter((el) => flipCard.id === el.id)[0].flipstate.toString() +
        flipCard.id.toString()
    );
  };

  const themeCategory = props.themeCategories.filter((category) => {
    return category.id === flipCard.themeCatId;
  })[0];

  // Pinnen
  const dispatch = useDispatch();
  const handlePinToggle = (pinnedCard, pinnedState, modalState) => {
    if (pinnedState === false) {
      if (pinnedCards.filter((el) => el.id === pinnedCard.id)[0] !== -1) {
        dispatch(addPinnedCard(pinnedCard));
      }
    } else {
      const index = pinnedCards.indexOf(pinnedCard);
      dispatch(removePinnedCard(pinnedCard, index));
    }

    dispatch(changeModalState(modalState));
    setTimeout(() => dispatch(changeModalState("none")), 5000);
  };

  // Nodig om error te voorkomen; don't ask why
  const nodeRef = React.useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CSSTransition
      // Flipstate wordt uit de reducer gehaald, uit 'cards', NIET uit 'pinnedCards!
      in={!cards.filter((el) => flipCard.id === el.id)[0].flipstate}
      key={key}
      timeout={300}
      classNames="flip"
      nodeRef={nodeRef}
    >
      <div
        className={`klimax-climatecard klimax-climatecard--${flipCard.sort}`}
        id={themeCategory.titleInCode}
        ref={nodeRef}
        aria-label="Klimaatkaart"
      >
        <div className="klimax-climatecard__face klimax-climatecard__face--front">
          <div className="klimax-climatecard-wrapper">
            <div
              className="klimax-climatecard-pic"
              style={{
                background: `url('https://oetang.be/klimax/assets/images/inspiratie/thema/${flipCard.pic1}')`,
              }}
              alt={`afbeelding van ${flipCard.titleInCode}`}
            />
            <button
              className="klimax-climatecard-flip"
              onClick={() => handleFlip()}
              s
            >
              <i className="icon-klimax-flip"></i>
            </button>
            <div className="klimax-climatecard-content">
              {flipCard.id !== 2045 && (
                <div className="klimax-climatecard-label">
                  <div>
                    <span
                      style={{
                        display: pathname.includes("inspiratie")
                          ? "none"
                          : "block",
                      }}
                    >
                      thema:{" "}
                    </span>
                    {themeCategory.title}
                  </div>
                </div>
              )}

              <div
                className="klimax-climatecard-title"
                dangerouslySetInnerHTML={{ __html: flipCard.title }}
              ></div>
              <div
                className="klimax-climatecard-baseline"
                dangerouslySetInnerHTML={{ __html: flipCard.teaser }}
              ></div>

              {/* swicht tussen 2 knoppen. Ik weet dat er een model tussenkomt, maar dan kan je de handle-functie daar ook gebruiken. */}
              {pinnedCards.some((el) => el.id === flipCard.id) ? (
                <div>
                  <button
                    className="klimax-unpin klimax-climatecard-pin klimax-button"
                    onClick={() => handlePinToggle(flipCard, true, "none")}
                  >
                    <i className="icon-klimax-plus"></i> Unpin
                  </button>
                  <Dropdown className="klimax-dropdown klimax-climatecard-pin">
                    <Dropdown.Toggle aria-label="Open opties">
                      <i className="icon-klimax-dots"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handlePinToggle(flipCard, true, "none")}
                        aria-label="Verwijder pin"
                      >
                        <i className="icon-klimax-delete" />
                        Verwijder pin
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <button
                  className="klimax-climatecard-pin klimax-button"
                  onClick={() => handlePinToggle(flipCard, false, "PinModal")}
                >
                  <i className="icon-klimax-plus"></i> Pin
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="klimax-climatecard__face klimax-climatecard__face--back">
          <button
            className="klimax-climatecard-flip"
            onClick={() => handleFlip()}
          >
            <i className="icon-klimax-flip"></i>
          </button>
          <div
            className="klimax-climatecard-wrapper"
            style={{
              overflow: flipCard.flipstate === false ? "clip" : "scroll",
            }}
          >
            <div
              className="klimax-climatecard-title"
              dangerouslySetInnerHTML={{ __html: flipCard.title }}
            ></div>

            <p dangerouslySetInnerHTML={{ __html: flipCard.description }}></p>
            {flipCard.id !== 2045 && (
              <p style={{ display: flipCard.id === 2045 && "none" }}>
                Mogelijke links met hot topics:
              </p>
            )}
            {flipCard.id !== 2045 && (
              <ul>
                <li>
                  <span>Oorzaak:</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: flipCard.inspirationCause,
                    }}
                  ></div>
                </li>
                <li>
                  <span>Gevolg:</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: flipCard.inspirationEffect,
                    }}
                  ></div>
                </li>
                <li>
                  <span>Oplossing:</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: flipCard.inspirationSolution,
                    }}
                  ></div>
                </li>
              </ul>
            )}

            {flipCard.link1 && flipCard.id !== 2045 && <p>Meer info:</p>}

            <ul className="external-links">
              {flipCard.link1 && (
                <li>
                  <a
                    className="klimax-climatecard-more-info"
                    href={flipCard.link1}
                    target="_blank"
                    rel="noopener"
                    dangerouslySetInnerHTML={{ __html: flipCard.link1Text }}
                  ></a>
                </li>
              )}
              {flipCard.link2 && (
                <li>
                  <a
                    className="klimax-climatecard-more-info"
                    href={flipCard.link2}
                    target="_blank"
                    rel="noopener"
                    dangerouslySetInnerHTML={{ __html: flipCard.link2Text }}
                  ></a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  modalState: state.modalState,
  themeCategories: state.themeCategory,
  pinnedCards: state.pinnedCards,
  state: state,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    addPinnedCard: (pinnedCard) => dispatch(addPinnedCard(addPinnedCard)),
    changeModalState: (newModalState) =>
      dispatch(changeModalState(newModalState)),
    removePinnedCard: (pinnedCard, index) =>
      dispatch(removePinnedCard(removePinnedCard, index)),
    flipCards: (id) => dispatch(flipCards(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeCard);
