import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import MobileHeader from "../components/MobileHeader";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  flipPinnedCards,
  setPlaceholdersNeeded,
  unflipPinnedCards,
} from "../redux/actions/actions";
import ThemeCard from "../components/ThemeCard";
import TopicCard from "../components/TopicCard";
import Sidebar from "../components/Sidebar";
import MobileMenu from "../components/MobileMenu";
import MethodCard from "../components/MethodCard";
import GoalCard from "../components/GoalCard";

const Home = (props) => {
  const dispatch = useDispatch();
  const { pinnedCards, cards, pinnedGoals, placeholdersNeeded } = props;

  // const [placeholdersNeeded, setPlaceholdersNeeded] = useState(
  Array.from(
    { length: 5 - (pinnedCards.length + (pinnedGoals.length > 0 ? 1 : 0)) },
    () => "placeholder"
  );
  // );

  useEffect(() => {
    const newPlaceholders = Array.from(
      { length: 5 - (pinnedCards.length + (pinnedGoals.length > 0 ? 1 : 0)) },
      () => "placeholder"
    );
    dispatch(setPlaceholdersNeeded(newPlaceholders));
    dispatch(unflipPinnedCards());
  }, []);

  return (
    <div>
      <Header></Header>
      <MobileHeader></MobileHeader>
      <MobileMenu></MobileMenu>
      <div className="klimax-cardboard">
        <div className="container">
          <div className="klimax-climatecards">
            {pinnedCards.map((card, i) =>
              card.sort === "theme" ? (
                <ThemeCard card={card} key={i}></ThemeCard>
              ) : card.sort === "topic" ? (
                <TopicCard card={card} key={i}></TopicCard>
              ) : card.sort === "method" ? (
                <MethodCard card={card} key={i}></MethodCard>
              ) : (
                <div key={i} style={{ display: "none" }}></div>
              )
            )}
            {pinnedGoals.length === 0 ? (
              <div style={{ display: "none" }}></div>
            ) : (
              <GoalCard></GoalCard>
            )}

            <Link
              to="/inspiratie"
              className="klimax-climatecard klimax-climatecard--add"
            >
              <div className="klimax-climatecard__face klimax-climatecard__face--front">
                <div className="klimax-climatecard-wrapper">
                  <div className="klimax-climatecard-content">
                    <div className="klimax-climatecard-title">
                      {" "}
                      <i className="icon-klimax-plus"></i>
                      Zoek en pin <span>klimaatinspiratie</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>

            {placeholdersNeeded.map((placeholder, i) => (
              <div
                key={i}
                className="klimax-climatecard klimax-climatecard--placeholder"
              ></div>
            ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
      <Sidebar></Sidebar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  pinnedCards: state.pinnedCards,
  pinnedGoals: state.pinnedGoals,
  placeholdersNeeded: state.placeholdersNeeded,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    flipPinnedCards: () => dispatch(flipPinnedCards()),
    setPlaceholdersNeeded: (newPlaceholdersNeeded) =>
      dispatch(setPlaceholdersNeeded(newPlaceholdersNeeded)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
