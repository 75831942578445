// import { initialState } from "../reducers/reducers";

export const changeModalState = (newModalState) => {
  return function (dispatch) {
    dispatch({
      type: "CHANGE_MODAL_STATE",
      payload: { newModalState },
    });
  };
};
export const toggleHighContrast = () => {
  return function (dispatch) {
    dispatch({
      type: "TOGGLE_HIGH_CONTRAST",
    });
  };
};

export const setPlaceholdersNeeded = (newPlaceholdersNeeded) => {
  return function (dispatch) {
    dispatch({
      type: "SET_PLACEHOLDERS_NEEDED",
      payload: { newPlaceholdersNeeded },
    });
  };
};

export const addPinnedCard = (newPinnedCard) => {
  return function (dispatch) {
    dispatch({
      type: "ADD_PINNED_CARD",
      payload: { newPinnedCard },
    });
  };
};

export const removePinnedCard = (oldPinnedCard, index) => {
  return function (dispatch) {
    dispatch({
      type: "REMOVE_PINNED_CARD",
      payload: { oldPinnedCard, index },
    });
  };
};

export const flipPinnedCards = () => {
  return function (dispatch) {
    dispatch({
      type: "FLIP_PINNED_CARDS",
      payload: {},
    });
  };
};
export const unflipPinnedCards = () => {
  return function (dispatch) {
    dispatch({
      type: "UNFLIP_PINNED_CARDS",
      payload: {},
    });
  };
};

export const deletePinnedCards = () => {
  return function (dispatch) {
    dispatch({
      type: "DELETE_PINNED_CARDS",
    });
  };
};

export const flipCards = (id) => {
  return function (dispatch) {
    dispatch({
      type: "FLIP_CARDS",
      payload: { id },
    });
  };
};

export const toggleSidebar = () => {
  return function (dispatch) {
    dispatch({
      type: "TOGGLE_SIDEBAR",
      payload: {},
    });
  };
};
export const closeSidebar = () => {
  return function (dispatch) {
    dispatch({
      type: "TOGGLE_SIDEBAR",
      payload: {},
    });
  };
};

export const togglePrint = () => {
    return function (dispatch) {
      dispatch({
        type: "TOGGLE_PRINT",
        payload: {},
      });
    };
  };
  export const closePrint = () => {
    return function (dispatch) {
      dispatch({
        type: "TOGGLE_PRINT",
        payload: {},
      });
    };
  };

export const toggleMobileMenu = () => {
  return function (dispatch) {
    dispatch({
      type: "TOGGLE_MOBILEMENU",
      payload: {},
    });
  };
};
export const closeMobileMenu = () => {
  return function (dispatch) {
    dispatch({
      type: "TOGGLE_MOBILEMENU",
      payload: {},
    });
  };
};

// Goals
export const addPinnedGoal = (newPinnedGoal) => {
  return function (dispatch) {
    dispatch({
      type: "ADD_PINNED_GOAL",
      payload: { newPinnedGoal },
    });
  };
};

export const removePinnedGoal = (oldPinnedGoal, index) => {
  return function (dispatch) {
    dispatch({
      type: "REMOVE_PINNED_GOAL",
      payload: { oldPinnedGoal, index },
    });
  };
};
export const removeAllPinnedGoals = () => {
  return function (dispatch) {
    dispatch({
      type: "REMOVE_ALL_PINNED_GOALS",
      payload: {},
    });
  };
};

export const flipPinnedGoal = () => {
  return function (dispatch) {
    dispatch({
      type: "FLIP_PINNED_GOAL",
      payload: {},
    });
  };
};

export const deletePinnedGoals = () => {
  return function (dispatch) {
    dispatch({
      type: "DELETE_PINNED_GOALS",
    });
  };
};

/*
VOORBEELDEN


export const fetchPageInfo = () => {
  return function (dispatch) {
    dispatch({
      type: "FETCH_PAGE_INFO",
      payload: initialState,
    });
  };
};

export const changeSector = (newSector) => {
  return function (dispatch) {
    dispatch({
      type: "CHANGE_SECTOR",
      payload: { newSector },
    });
  };
};

*/
