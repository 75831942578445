export const initialState = {
  sidebarState: false,
  printState: false,
  highContrast: false,
  mobilemenuState: false,
  pinnedCards: [],
  pinnedGoals: [],
  pinnedGoalsFlipstate: false,
  placeholdersNeeded: [
    "placeholder",
    "placeholder",
    "placeholder",
    "placeholder",
    "placeholder",
  ],
  modalState: "none",
  themeCategory: [
    {
      id: 0,
      title: "",
      titleInCode: "",
      primaryColor: "blue",
    },
    {
      id: 1,
      title: "School",
      titleInCode: "school",
      primaryColor: "blue",
    },
    {
      id: 2,
      title: "Op je bord",
      titleInCode: "op-je-bord",
      primaryColor: "blue",
    },
    {
      id: 3,
      title: "Winkelen",
      titleInCode: "winkelen",
      primaryColor: "blue",
    },
    {
      id: 4,
      title: "Feestje bouwen",
      titleInCode: "feestje-bouwen",
      primaryColor: "blue",
    },
    {
      id: 5,
      title: "Wonen",
      titleInCode: "wonen",
      primaryColor: "blue",
    },
    {
      id: 6,
      title: "(school)Tuin",
      titleInCode: "(school)tuin",
      primaryColor: "blue",
    },
    {
      id: 7,
      title: "Reizen",
      titleInCode: "reizen",
      primaryColor: "blue",
    },
    {
      id: 8,
      title: "Eigen idee",
      titleInCode: "eigen-idee",
      primaryColor: "blue",
    },
  ],
  topicCategory: [
    {
      id: 1,
      title: "oorzaken",
      primaryColor: "darkgreen",
      secondaryColor: "white",
    },
    {
      id: 2,
      title: "gevolgen",
      primaryColor: "midgreen",
      secondaryColor: "white",
    },
    {
      id: 3,
      title: "oplossingen",
      primaryColor: "lightgreen",
      secondaryColor: "white",
    },
  ],
  goalMethodCategory: [
    {
      id: 1,
      label: "systeemdenken",
      title:
        "Systeemdenken <span>rond oorzaken, gevolgen en het grotere geheel; in diverse contexten gerelateerd aan de klimaatcrisis.</span>",
      titleInCode: "systeemdenken",
      primaryColor: "yellow",
      secondaryColor: "black",
      pic1: "systeemdenken.png",
      subgoal1:
        "De leerlingen leren om de complexiteit van systemen in zijn geheel in kaart te brengen.",
      subgoal2:
        "De leerlingen leren naar de werkelijkheid kijken vanuit verschillende perspectieven en invalshoeken (economisch, sociaal en ecologisch).",
      subgoal3:
        "De leerlingen leren om de uitdagingen van vandaag in historisch perspectief te plaatsen.",
    },
    {
      id: 2,
      label: "toekomstdenken",
      title:
        "Toekomstdenken <span>om diverse toekomstscenario's m.b.t. de klimaatcrisis te kunnen verbeelden en bedenken.</span>",
      titleInCode: "toekomstdenken",
      primaryColor: "yellow",
      secondaryColor: "black",
      pic1: "toekomstdenken.png",
      subgoal1:
        "De leerlingen leren inzien dat de toekomst bestaat uit een verscheidenheid aan alternatieven.",
      subgoal2:
        "De leerlingen leren verschillende (duurzame) toekomstscenario's bedenken en verbeelden.",
      subgoal3:
        "De leerlingen leren inzien dat de toekomst open en collectief kan worden vormgegeven.",
    },
    {
      id: 3,
      label: "strategisch denken",
      title:
        "Strategisch denken <span>om diverse toekomststrategiën m.b.t. de klimaatcrisis te onderzoeken en vorm te geven.</span>",
      titleInCode: "strategisch-denken",
      primaryColor: "orange",
      secondaryColor: "white",
      pic1: "strategisch-denken.png",
      subgoal1:
        "De leerlingen leren mogelijke duurzame toekomstvisies grondig en systematisch te onderzoeken.",
      subgoal2:
        "De leerlingen leren kwalitatieve bronnen selecteren, analyseren en vergelijken.",
      subgoal3:
        "De leerlingen leren, na kritisch onderzoek, maatregelen (transitiestrategieën) uit te stippelen om een duurzaam toekomstscenario te realiseren.",
    },
    {
      id: 4,
      label: "(samen) actie ondernemen",
      title:
        "(Samen) actie ondernemen <span>om gedeelde doelstellingen rond de klimaatcrisis te bereiken.</span>",
      titleInCode: "(samen)-actie-ondernemen",
      primaryColor: "orange",
      secondaryColor: "white",
      pic1: "actie.png",
      subgoal1:
        "De leerlingen leren over, door en van actie, in samenwerking met anderen.",
      subgoal2:
        "De leerlingen leren een initiatief/actie uitwerken en uitvoeren op individueel en/of collectief niveau en/of die invloed uitoefent op politiek niveau.",
      subgoal3:
        "De leerlingen leren de impact van hun actie/initiatief inschatten en evalueren.",
    },
    {
      id: 5,
      label: "omgaan met waarden en normen",
      title:
        "Omgaan met waarden en normen <span>(van jezelf en van anderen) bij de klimaatcrisis.</span>",
      titleInCode: "omgaan-met-waarden-en-normen",
      primaryColor: "red",
      secondaryColor: "white",
      pic1: "waarden-normen.png",
      subgoal1:
        "De leerlingen leren hun eigen waarden en normen kritisch onderzoeken.",
      subgoal2:
        "De leerlingen leren hun eigen waarden en normen aftoetsen aan de sociale minimum voorwaarden en aan de grenzen van de ecologische draagkracht van de planeet (SDGs/donutmodel).",
      subgoal3:
        "De leerlingen leren op een respectvolle manier in dialoog gaan over verschillende (duurzaamheids)waarden en -normen.",
    },
    {
      id: 6,
      label: "omgaan met emoties",
      title:
        "Omgaan met emoties <span>(van jezelf en van anderen) bij de klimaatcrisis.</span>",
      titleInCode: "omgaan-met-gevoelens",
      primaryColor: "red",
      secondaryColor: "white",
      pic1: "gevoelens.png",
      subgoal1:
        "De leerlingen leren omgaan met emoties (van zichzelf en van anderen) in veranderende en/of onzekere situaties.",
      subgoal2:
        "De leerlingen leren op respectvolle manier uitdrukking te geven aan emoties en gevoelens.",
      subgoal3:
        "De leerlingen leren met elkaar in dialoog gaan met respect voor diverse standpunten en emoties.",
    },
  ],
  ageRange: [
    {
      id: 1,
      title: "2,5 - 8",
      titleInCode: "2,5 - 8",
      primaryColor: "grey",
    },
  ],
  cards: [
    {
      id: 201,
      sort: "theme",
      themeCatId: 1,
      title: "Naar school",
      titleInCode: "naar-school",
      teaser: "Laat je (niet) vervoeren!",
      description:
        "Laat je je vervoeren, of kom je zelf in beweging? Kies je ervoor om te stappen of om te trappen? Ga je met trein, bus of auto? Is elektrisch rijden wel een duurzaam alternatief voor fossiele brandstoffen (auto) of benensport (step, fiets)?",
      pic1: "Klimax_thema_school__naar-school.jpg",
      inspirationCause: "Verbranden van fossiele brandstoffen",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Verplaatsen volgens het STO(e)P-principe",
      flipstate: false,
      link1:
        "https://climatechallenge.be/nl/themas/hoe-ons-klimaatvriendelijk-verplaatsen",
      link1Text: "Climate challenge: hoe ons klimaatvriendelijk verplaatsen",
      link2: "",
      link2Text: "",
    },
    {
      id: 202,
      sort: "theme",
      themeCatId: 1,
      title: "Schoolmateriaal",
      titleInCode: "schoolmateriaal",
      teaser: "Hoe schoon is je schrift?",
      description:
        "Hoe groen is je boekentas? En de spullen erin? Schrijf je met een hervulbare vulpen, of liever een balpen? Gebruik je een potlood en gom, of zijn inktwisser, corrigeerlint en blanco stickers jouw redding bij een foutje? Kleurpotloden of stiften: wat gaat het langst mee?",
      pic1: "Klimax_thema_school__schoolmateriaal.jpg",
      inspirationCause: "Industriële processen",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 203,
      sort: "theme",
      themeCatId: 1,
      title: "Papier",
      titleInCode: "papier",
      teaser: "Hoeveel klad zit er in het papier?",
      description:
        "Papieren of digitale agenda? Schermen of papier? Denk je na voor je print? Gebruik je weleens de achterzijde van een brief of print? Is gerecycleerd papier even mooi als spierwit papier? Hoeveel bomen zitten er in jouw cursussen en handboeken?",
      pic1: "Klimax_thema_school__papier.jpg",
      inspirationCause: "Industriële processen",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1:
        "https://magazine.knack.be/makr/voor-abonnees/grafisch-nieuws/gotoPDF?articleId=6226388",
      link1Text: "Hoe duurzaam is de papierindustrie?",
      link2: "",
      link2Text: "",
    },
    {
      id: 204,
      sort: "theme",
      themeCatId: 1,
      title: "Klaslucht",
      titleInCode: "klaslucht",
      teaser: "Zit er een luchtje aan?",
      description:
        "Waarom moeten we ventileren? Wat gebeurt er met de CO<sub>2</sub> die we uitademen? Is er meer CO<sub>2</sub> meetbaar in de klas dan in open lucht? Zorgt dat ook in de klas voor een mini-broeikaseffect? Is CO<sub>2</sub> hoe dan ook slecht? Wist je dat CO<sub>2</sub> ook geproduceerd wordt (voor bruisdranken, om rijping van groenten en fruit te vertragen, ...)?",
      pic1: "Klimax_thema_school__klaslucht.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Informeer jezelf",
      flipstate: false,
      link1:
        "https://www.mosvlaanderen.be/aan-de-slag/actiefiches/mos-actiefiche-een-gezond-en-energiezuinig-binnenklimaat",
      link1Text: "MOS-actiefiche: Een gezond en energiezuinig binnenklimaat",
      link2: "",
      link2Text: "",
    },
    {
      id: 205,
      sort: "theme",
      themeCatId: 1,
      title: "Tussendoortje",
      titleInCode: "tussendoortje",
      teaser: "Groenten, fruit of chocola?",
      description:
        "Kies je voor fruit of koek? Moet een appel of peer in een doosje of zakje, of neem je het zo mee? Waarom zijn koekjes vaak apart verpakt? Bak je soms zelf koekjes? Kan je koekjes bakken zonder boter of ei? En kan het ook zonder palmolie?",
      pic1: "Klimax_thema_school__tussendoortje.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Meer plantaardige voeding",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 206,
      sort: "theme",
      themeCatId: 1,
      title: "Technologie",
      titleInCode: "technologie",
      teaser: "Kan technologie ons redden?",
      description:
        "Kunnen technologische toepassingen bomen en algen vervangen om CO<sub>2</sub> op te slaan? Zijn elektrische wagens een duurzaam alternatief? Vervangen schermen (computer, smartphone) papier en schrift? Moet het altijd 'meer' zijn, of zijn er grenzen aan ons energieverbruik? Kan technologie overal ingezet worden ten dienste van iedereen?",
      pic1: "Klimax_thema_school__technologie.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Meer extreem weer",
      inspirationSolution: "CO<sub>2</sub>-captatie en -opslag",
      flipstate: false,
      link1:
        "https://climatechallenge.be/nl/themas/into-the-future-out-of-the-box",
      link1Text: "Climate challenge: out of the box",
      link2: "",
      link2Text: "",
    },
    {
      id: 207,
      sort: "theme",
      themeCatId: 1,
      title: "Klasgenoten",
      titleInCode: "klasgenoten",
      teaser: "Verre of dichte buur?",
      description:
        "Ga je naar de dichtstbijzijnde school? Wat betekent dat voor je dagelijks transport ernaartoe? Zijn er anderstalige nieuwkomers in de klas? Kunnen zij ervaringen delen rond klimaatproblemen in hun land?",
      pic1: "Klimax_thema_school__klasgenoten.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Klimaatonrechtvaardigheid",
      inspirationSolution: "Informeer jezelf",
      flipstate: false,
      link1: "https://klimaat.be/klimaatverandering/gevolgen/klimaatmigratie",
      link1Text: "Klimaatmigratie",
      link2: "",
      link2Text: "",
    },
    {
      id: 208,
      sort: "theme",
      themeCatId: 1,
      title: "Schooluitstap",
      titleInCode: "schooluitstap",
      teaser: "Naar een (sub)tropisch zwembad of een dagje aan zee?",
      description:
        "Zijn er leuke bestemmingen dicht bij je school? Hoe ga je liefst op uitstap: trein, bus, auto of fiets? Heb je een 'reisgevoel' als je langer onderweg bent? Verkies je een uitstap naar een (sub)tropisch zwembad, of een dagje aan zee? Wat is de klimaatimpact van een pretpark (energieverbruik), zoo (transport en voeding dieren), ...?",
      pic1: "Klimax_thema_school__schooluitstap.jpg",
      inspirationCause: "Verbranden van fossiele brandstoffen",
      inspirationEffect: "Meer extreem weer",
      inspirationSolution: "Onnodig energieverbruik vermijden",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 209,
      sort: "theme",
      themeCatId: 2,
      title: "Voedselverlies",
      titleInCode: "voedselverlies",
      teaser: "Red de restjes!",
      description:
        "Eet je je bord/brooddoos altijd leeg? Hoe red je eten uit de vuilbak? Koop je soms meer dan je tijdig op kan? Ken je het verschil tussen THT en TGT? Hoe bewaar je voedseloverschotjes?",
      pic1: "Klimax_thema_bord__voedselverlies.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "https://velt.nu/keukenpraatje/keukenpraatjes-koken-met-restjes",
      link1Text: "Koken met restjes",
      link2: "",
      link2Text: "",
    },
    {
      id: 2010,
      sort: "theme",
      themeCatId: 2,
      title: "Groenten en fruit",
      titleInCode: "groenten-en-fruit",
      teaser: "AardBLIJ of framBOOS?",
      description:
        "Zijn aardbeien in februari even lekker als in juni? Komen appels uit de winkel steeds uit België? Zijn biologisch gekweekte groenten en fruit ook beter voor het klimaat? Hoeveel energie was er nodig om dit stuk groente/fruit op jouw bord te brengen? Waar gaat jouw groenafval naartoe? Wat doe je met een bruine banaan?",
      pic1: "Klimax_thema_bord__groenten-en-fruit.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Meer plantaardige voeding",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2011,
      sort: "theme",
      themeCatId: 2,
      title: "Vlees en zuivel",
      titleInCode: "vlees-en-zuivel",
      teaser: "(On)schuldige koeienscheten en machtige melkveebedrijven?",
      description:
        "Gebruik je dagelijks zuivelproducten (boter, yoghurt, melk, kaas)? Zijn alternatieven voor melk even lekker, en duurzamer? Wat weet je over de impact van vlees- en zuivelindustrie op het klimaat? Zou je insecten eten als alternatief voor biefstuk of kip?",
      pic1: "Klimax_thema_bord__vlees-en-zuivel.jpg",
      inspirationCause: "Veeteelt",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Meer plantaardige voeding",
      flipstate: false,
      link1:
        "https://climatechallenge.be/nl/themas/hoe-klimaatvriendelijk-eten#1-welke-impact-heeft-de-landbouw-op-het-klimaat",
      link1Text:
        "Climate challenge: welke impact heeft de landbouw op het klimaat?",
      link2: "",
      link2Text: "",
    },
    {
      id: 2012,
      sort: "theme",
      themeCatId: 2,
      title: "Exotisch fruit",
      titleInCode: "exotisch-fruit",
      teaser: "Appel of banaan?",
      description:
        "Wat eet je het vaakst: appel of banaan? Komt exotisch fruit per boot of per vliegtuig? Hoeveel ervan haalt ons bord? Hoe wordt het onderweg bewaard en hoeveel energie kost dat? Hoelang blijft het verkoopbaar en hoeveel wordt verspild?",
      pic1: "Klimax_thema_bord__exotisch-fruit.jpg",
      inspirationCause: "Verbranden van fossiele brandstoffen",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Onnodig energieverbruik vermijden",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2013,
      sort: "theme",
      themeCatId: 2,
      title: "Koken",
      titleInCode: "koken",
      teaser: "Bakken of braden?",
      description:
        "Kook je op gas, elektriciteit of hout? Maakt een deksel op de pan/kookpot een verschil? Is water opwarmen voordeliger in de microgolfoven, kookpot of waterkoker? Heb je al eens rijst gegaard in je slaapzak (hooikist-principe)?",
      pic1: "Klimax_thema_bord__koken.jpg",
      inspirationCause: "Verbranden van fossiele brandstoffen",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Hernieuwbare energiebronnen",
      flipstate: false,
      link1: "https://velt.nu/ecologisch-koken",
      link1Text: "Ecologisch koken",
      link2: "",
      link2Text: "",
    },
    {
      id: 2014,
      sort: "theme",
      themeCatId: 3,
      title: "Online shoppen",
      titleInCode: "online-shoppen",
      teaser: "Wat kost dit pakje echt?",
      description:
        "Koop je wel eens iets online? Stuurde je al eens iets terug zonder dat het defect was? Vind je het logisch dat je een pakket gratis kan terugsturen?  Stond je er al eens bij stil hoe je pakketje zo snel tot bij jou komt? Is kopen in een fysieke winkel beter dan online shoppen?",
      pic1: "Klimax_thema_Winkelen__online-Shoppen.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2015,
      sort: "theme",
      themeCatId: 3,
      title: "Kledij",
      titleInCode: "kledij",
      teaser: "Fast of fair?",
      description:
        "Waar wordt jouw broek gemaakt? Draag je je schoenen tot ze versleten zijn? Wil je graag het nieuwste van het nieuwste? Word je gelukkig als je iets nieuws koopt? En kan iemand er ongelukkig door worden? Stop je wel eens kleren in een inzamelbox? Wordt kledij écht gerecycleerd?",
      pic1: "Klimax_thema_winkelen__mode-kledij.jpg",
      inspirationCause: "Industriële processen",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Informeer jezelf",
      flipstate: false,
      link1: "https://en.wikipedia.org/wiki/The_True_Cost",
      link1Text: "The True Cost",
      link2: "",
      link2Text: "",
    },
    {
      id: 2016,
      sort: "theme",
      themeCatId: 3,
      title: "Black Friday",
      titleInCode: "black-friday",
      teaser: "Wie betaalt de rekening ?",
      description:
        "Maakt kopen je gelukkig? Koop je soms iets impulsief tijdens een uitverkoop/solden? Deel je soms iets met vrienden/familie of buurt? Staan er toestellen in de klas/thuis die je amper gebruikt? Kan je teveel spullen hebben? Hebben toestellen recht op reparatie?",
      pic1: "Klimax_thema_winkelen__black-friday.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Meer extreem weer",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2017,
      sort: "theme",
      themeCatId: 3,
      title: "Verpakking",
      titleInCode: "verpakking",
      teaser: "Mij pak je niet in!",
      description:
        "Kies je voor fruit in een papieren zak, een plastic zak of herbruikbare katoenen tas? Of neem je ze zonder verpakking mee uit de winkel? Geef je je broodzak een tweede leven? Zijn alle plastics recycleerbaar? Moet er statiegeld komen op verpakkingen?",
      pic1: "Klimax_thema_winkelen__verpakking.jpg",
      inspirationCause: "Industriële processen",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2018,
      sort: "theme",
      themeCatId: 3,
      title: "Tweedehands",
      titleInCode: "tweedehands",
      teaser: "Zo goed als nieuw!",
      description:
        "Ben je ooit al eens een kringwinkel binnengestapt? Geef je oud speelgoed een nieuw leven? Moet je alle spullen zelf bezitten, of kan je ze ook gebruiken van iemand anders?",
      pic1: "Klimax_thema_winkelen__tweedehands.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2019,
      sort: "theme",
      themeCatId: 3,
      title: "Naar de markt",
      titleInCode: "naar-de-markt",
      teaser: "Merkloze markten?",
      description:
        "Koop je wel eens op de markt? Is alles wat je op de markt vindt lokaal gekweekt? Zijn producten van de markt steeds korte keten? Zorgt winkelen op de markt voor minder verpakking? Zijn producten die je op de markt vindt 'merkloos'? Wat betekent dat m.b.t. reclame? Mag ik de appels uit mijn boomgaard op de markt verkopen?",
      pic1: "Klimax_thema_winkelen__naardemarkt.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2020,
      sort: "theme",
      themeCatId: 3,
      title: "Elektronica",
      titleInCode: "elektronica",
      teaser: "Vervangen of herstellen?",
      description:
        "Vervang je toestellen pas als ze niet meer werken? Kunnen toestellen hersteld worden door een niet-professional? Ging je al eens langs in een repair café? Wist je dat de levensduur van toestellen vaak op voorhand bepaald wordt? Wat gebeurt er met een kapot toestel? Wat betekenen de energielabels van huishoudtoestellen?",
      pic1: "Klimax_thema_winkelen__Electronica.jpg",
      inspirationCause: "Industriële processen",
      inspirationEffect: "Klimaatonrechtvaardigheid",
      inspirationSolution: "Informeer jezelf",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2021,
      sort: "theme",
      themeCatId: 3,
      title: "Geld",
      titleInCode: "geld",
      teaser: "Geld is macht?",
      description:
        "Is elektronisch betalen beter dan contant? Maakte je al eens gebruik van alternatieve betalingsmiddelen (LETS, Torekes, ...)? Ruil je soms appels in voor peren? Gratis fruit laten plukken in ruil voor een bakje? Weet je wat de bank doet met jouw geld?",
      pic1: "Klimax_thema_winkelen__geld.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Informeer jezelf",
      flipstate: false,
      link1: "https://www.fairfin.be",
      link1Text: "Fairfin",
      link2:
        "https://stad.gent/nl/meer-dan-slimme-stad/wat-een-smart-city/slimme-burgers/torekes",
      link2Text: "Torekes",
    },
    {
      id: 2022,
      sort: "theme",
      themeCatId: 4,
      title: "Cadeautjes",
      titleInCode: "cadeautjes",
      teaser: "Pakjestijd!?",
      description:
        "Is je aanwezigheid op zich genoeg, of vind je het 'not-done' om met lege handen aan te komen op een feestje? Vraag je op voorhand cadeautips of ga je liever voor een originele verrassing? Kan je in de kringwinkel iets leuks scoren? Gaf je al eens een doe-activiteit of een zelfgemaakt cadeau? Ga je voor online bestellen en aan huis laten leveren?",
      pic1: "Klimax_hottopic_feest__cadeautjes.jpg",
      inspirationCause: "Industriële processen",
      inspirationEffect: "Klimaatonrechtvaardigheid",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1:
        "https://www.mosvlaanderen.be/aan-de-slag/actiefiches/mos-actiefiche-cadeautjes-anders-bekeken",
      link1Text: "MOS-Actiefiche: cadeautjes anders bekeken",
      link2: "",
      link2Text: "",
    },
    {
      id: 2023,
      sort: "theme",
      themeCatId: 4,
      title: "Catering",
      titleInCode: "catering",
      teaser: "Een natje en een droogje.",
      description:
        "Welke hapjes en/of drankjes serveer je? Kies je voor het gemak van blikjes frisdrank, of tap je water van de kraan?  Trek je een zak chips open of maak je fruitspiesjes? Een lekkere BBQ? Een lokale cateraar? Mag het iets exotischer zijn?",
      pic1: "Klimax_hottopic_feest__catering.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Meer plantaardige voeding",
      flipstate: false,
      link1:
        "https://climatechallenge.be/nl/themas/hoe-klimaatvriendelijk-eten",
      link1Text: "Climate challenge: hoe klimaatvriendelijk eten",
      link2: "",
      link2Text: "",
    },
    {
      id: 2024,
      sort: "theme",
      themeCatId: 4,
      title: "Maak je mooi",
      titleInCode: "maak-je-mooi",
      teaser: "Blinken zonder stinken.",
      description:
        "Ga je voor een compleet nieuwe feest-outfit? Welke make-up gebruik je? Een spuitbus met haarlak, of gel? Ga je all-the-way voor een themafeestje? Ontschminken met herbruikbare doekjes of wattenschijfjes?",
      pic1: "Klimax_hottopic_feest__maak-je-mooi.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "https://detransformisten.be/artikel/de-piraminder",
      link1Text: "De piraminder",
      link2: "",
      link2Text: "",
    },
    {
      id: 2025,
      sort: "theme",
      themeCatId: 4,
      title: "Versiering",
      titleInCode: "versiering",
      teaser: "Dat siert jou en de planeet.",
      description:
        "Hoe breng je sfeer op een feestje? Chinese lampionnetjes, ledlichtjes, een lavalamp en discobal voor de sfeer? Een huis vol ballonnen, serpentines en confetti? Een videoloop met leuke foto's? Vaasjes met versgeplukte veldbloemen?",
      pic1: "Klimax_hottopic_feest__versiering.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Klimaatonrechtvaardigheid",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "https://detransformisten.be/artikel/de-piraminder",
      link1Text: "De piraminder",
      link2: "",
      link2Text: "",
    },
    {
      id: 2026,
      sort: "theme",
      themeCatId: 4,
      title: "Muziek",
      titleInCode: "muziek",
      teaser: "Music maestro!",
      description:
        "Stream je je muziek via internet? Heb je je playlist (illegaal) gedownload? Ga je voor een zangsessie met kampvuurliedjes? Kan je via een vriend aan een mengtafel geraken?",
      pic1: "Klimax_hottopic_feest__muziek.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Onnodig energieverbruik vermijden",
      flipstate: false,
      link1:
        "https://downtoearthmagazine.nl/klimaatvriendelijk-muziek-luisteren-kan-dat/",
      link1Text: "Klimaatvriendelijk muziek luisteren, kan dat?",
      link2:
        "https://www.gla.ac.uk/explore/sustainability/research/costofmusic/",
      link2Text: "The cost of music",
    },
    {
      id: 2027,
      sort: "theme",
      themeCatId: 5,
      title: "Huisdieren",
      titleInCode: "huisdieren",
      teaser: "Grote pootafdruk?",
      description:
        "Een goudvis of een pony? Ga je met je hond naar de kapper en de dierenpsycholoog? Aangekochte plastic speeltjes of een stok? Welke ruimte en tijd voorzie je best voor je huisdier?",
      pic1: "Klimax_thema_wonen__huisdieren.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link2:
        "https://www.milieucentraal.nl/bewust-winkelen/uitgelichte-producten/tips-voor-huisdieren/",
      link2Text: "Tips voor huisdieren",
      link1: "https://www.huisdierinfo.be/",
      link1Text: "Huisdierinfo",
    },
    {
      id: 2028,
      sort: "theme",
      themeCatId: 5,
      title: "De buurt",
      titleInCode: "de-buurt",
      teaser: "Beter een goeie buur dan een verre vriend.",
      description:
        "Ben je tevreden over de buurt waar je woont? Wat zou je graag veranderen? Kreeg je de mogelijkheid om mee voorstellen te doen over publieke ruimte in je buurt? Hoe kan je participeren als burger?",
      pic1: "Klimax_thema_wonen__buurt.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Doeltreffend klimaatbeleid",
      flipstate: false,
      link1: "https://omgeving.vlaanderen.be/mobiscore",
      link1Text: "Mobiscore",
      link2: "",
      link2Text: "",
    },
    {
      id: 2029,
      sort: "theme",
      themeCatId: 5,
      title: "In de badkamer",
      titleInCode: "in-de-badkamer",
      teaser: "Hier begint de zee.",
      description:
        "Welke zeep/shampoo gebruik je om je te wassen? Weet je wat er in zit? Wat gebeurt er met de zeepresten die je doorspoelt naar de riool en verder? Wat smeer je allemaal op je gezicht? Zijn je producten getest op dieren? Ga je voor grote familieverpakkingen of handige reisporties?",
      pic1: "Klimax_thema_wonen__badkamer.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1:
        "https://detransformisten.be/artikel/natuurcosmetica-uit-je-keukenkast-0",
      link1Text: "Natuurcosmetica uit je keukenkast",
      link2: "",
      link2Text: "",
    },
    {
      id: 2030,
      sort: "theme",
      themeCatId: 5,
      title: "Afval",
      titleInCode: "afval",
      teaser: 'Is iets "weg" als je het weggooit?',
      description:
        "Hebben jullie thuis verschillende sorteerfracties? Waar gaat het afval heen nadat je de vuilniszak hebt buitengezet? Let je bewust op verpakkingen? Smijt je iets weg als het stuk is? Wat met speelgoed/kleren waar je uitgegroeid bent?",
      pic1: "Klimax_thema_wonen__afval.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Feedback loops",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "https://ovam.vlaanderen.be/afval-materialen",
      link1Text: "Afvalmaterialen",
      link2: "",
      link2Text: "",
    },
    {
      id: 2031,
      sort: "theme",
      themeCatId: 5,
      title: "Verwarming",
      titleInCode: "verwarming",
      teaser: "Hoe maken we het aangenaam warm?",
      description:
        "Loop je thuis rond in T-shirt? Hoe is je huis geïsoleerd? Lekker gezellig onder een dekentje voor de TV? Centrale verwarming of een pellet- of speksteenkachel? Kies je voor een wamtepomp?",
      pic1: "Klimax_thema_wonen__verwarming.jpg",
      inspirationCause: "Verbranden van fossiele brandstoffen",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Hernieuwbare energiebronnen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2032,
      sort: "theme",
      themeCatId: 5,
      title: "Woning",
      titleInCode: "woning",
      teaser: "Huis of thuis?",
      description:
        "Hoe ziet jouw (droom)huis er uit? Hoeveel ruimte hebben we nodig om 'goed' te wonen? Ieder zijn eigen kamer? Kies je voor nieuwbouw of verbouw je een bestaand huis? Een rijhuis of een villa, en hoe  isoleer je die best? Deel je ruimtes of je tuin met je buren zoals bv. in een co-housing?",
      pic1: "Klimax_thema_wonen__woning.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Zeespiegelstijging",
      inspirationSolution: "Hernieuwbare energiebronnen",
      flipstate: false,
      link1:
        "https://climatechallenge.be/nl/themas/hoe-klimaatvriendelijk-wonen",
      link1Text: "Climate challenge: hoe klimaatvriendelijk wonen",
      link2: "",
      link2Text: "",
    },
    {
      id: 2033,
      sort: "theme",
      themeCatId: 5,
      title: "Huishouden",
      titleInCode: "huishouden",
      teaser: "Kan poetsen ook vies zijn?",
      description:
        "Hoe was je je kleren, hoe droog je ze? Is strijken nodig? Welke producten gebruik je om te poetsen? Lang leve wegwerpdoekjes of ga je voor een ander alternatief?",
      pic1: "Klimax_thema_wonen__huishouden.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2034,
      sort: "theme",
      themeCatId: 5,
      title: "Waterverbruik",
      titleInCode: "waterverbruik",
      teaser: "Elke druppel telt.",
      description:
        "Drinkwater uit de kraan of liever je favoriete flessenwater? Zou jij  regenwater gebruiken om kleren te wassen of te douchen? Is elke dag douchen nodig? Wat denk je van een composttoilet? Een zwembad in de tuin?",
      pic1: "Klimax_thema_wonen__waterverbruik.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Meer extreem weer",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "https://www.vmm.be/water/waterbesparing",
      link1Text: "Waterbesparing",
      link2: "",
      link2Text: "",
    },
    {
      id: 2035,
      sort: "theme",
      themeCatId: 6,
      title: "Ontharding",
      titleInCode: "ontharding",
      teaser: "Botten of beton?",
      description:
        "Waar kunnen jullie enkele tegels wippen? Is het ok om met bemodderde schoenen/kleren thuis te komen? Rolschaatsen of een klimheuvel?",
      pic1: "Klimax_thema_schooltuin__ontharding.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Meer extreem weer",
      inspirationSolution: "Natuurlijke CO<sub>2</sub>-opslag",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2036,
      sort: "theme",
      themeCatId: 6,
      title: "Regenwater",
      titleInCode: "regenwater",
      teaser: "Waterpret of -ellende?",
      description:
        "Plassen op de speelplaats: waterpret of ellende? Water snel afvoeren naar de riool, of tegelwippen en laten infiltreren? Regenwater opvangen in een ton en gebruiken in de moestuin of laten insijpelen via een wadi?",
      pic1: "Klimax_thema_school__regenwater.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Meer extreem weer",
      inspirationSolution: "Klimaatadaptatie",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2037,
      sort: "theme",
      themeCatId: 6,
      title: "Bomen en struiken",
      titleInCode: "bomen-en-struiken",
      teaser: "Klauterplezier of bladerenlast?",
      description:
        "Zie je een boom op de speelplaats als een natuurlijke parasol, of eerder als een obstakel voor je voetbalmatch? Deed je al een speelplaatssafari op zoek naar beestjes? Wie zorgt voor het snoeien van de struiken of de afgevallen bladeren? Kiezen jullie voor een natuurlijke afsluiting/geluidsscherm? Hoe maak je van je speelplaats een natuurlijke spons?",
      pic1: "Klimax_thema_schooltuin__bomen-en-struiken.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Meer extreem weer",
      inspirationSolution: "Natuurlijke CO<sub>2</sub>-opslag",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2038,
      sort: "theme",
      themeCatId: 6,
      title: "Gazon",
      titleInCode: "gazon",
      teaser: "Zaaien en maaien of groeien en bloeien?",
      description:
        "Laten groeien of net veel snoeien? Een strak voetbalveld of een bloemenweide? Maai je het hele grasveld, of enkel wandelpaadjes?",
      pic1: "Klimax_thema_school__gazon.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Natuurlijke CO<sub>2</sub>-opslag",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2039,
      sort: "theme",
      themeCatId: 6,
      title: "Moestuin",
      titleInCode: "moestuin",
      teaser: "Meestemest of beestemest?",
      description:
        "Haal je graag het maximum uit je moestuin en bemest je hiervoor? Mogen beestjes met een deel van je opbrengst aan de haal gaan? Hoe hou je slakken of insecten op afstand? Wat met onkruid? Bewater je extra bij droogte?",
      pic1: "Klimax_thema_school__moestuin.jpg",
      inspirationCause: "Veranderingen in landgebruik",
      inspirationEffect: "Meer extreem weer",
      inspirationSolution: "Klimaatadaptatie",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2040,
      sort: "theme",
      themeCatId: 7,
      title: "Logement",
      titleInCode: "logement",
      teaser: "Een vijfsterren-tentje.",
      description:
        "Is een all-in-vijf-sterren-formule garantie voor een prachtvakantie? Ging je al eens ergens couchsurfen? Zou je je huis voor de vakantie ruilen met een andere familie? Kies je liefst uit een all-you-can-eat buffet, of proef je graag de lokale keuken in de bar om de hoek?",
      pic1: "Klimax_thema_reizen__logement.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Zeespiegelstijging",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2041,
      sort: "theme",
      themeCatId: 7,
      title: "Bestemming",
      titleInCode: "bestemming",
      teaser: "Vacation, staycation of gewoon onderweg?",
      description:
        "Start de vakantie van zodra je uit de deur stapt en is onderweg zijn ook je bestemming (bv. een fietsvakantie)? Kan je genieten van een staycation? Strand, stad of bergen? Wat met virussen of ongewenste diertjes die we ongewild mee terugnemen van op reis?",
      pic1: "Klimax_thema_reizen__bestemming.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Klimaatonrechtvaardigheid",
      inspirationSolution: "Informeer jezelf",
      flipstate: false,
      link1:
        "https://decorrespondent.nl/10792/de-grote-klimaatgids-dit-kan-jij-zelf-doen-tegen-klimaatverandering/e497384d-81d7-0052-1cd6-f5ad1dc7599a#reizen",
      link1Text: "De grote klimaatgids",
      link2: "",
      link2Text: "",
    },
    {
      id: 2042,
      sort: "theme",
      themeCatId: 7,
      title: "Reisprogramma",
      titleInCode: "reisprogramma",
      teaser: "Chillen of drillen?",
      description:
        "Wat zet je op je reisprogramma? Op zoek naar kicks en is the sky the limit? Gewoon genieten van het Insta-waardig uitzicht? Is slow travelling iets voor jou? Wat betekent duurzaam reizen?",
      pic1: "Klimax_thema_reizen__reisprogramma.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Natuur en mens onder druk",
      inspirationSolution: "Zuinig met grondstoffen",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2043,
      sort: "theme",
      themeCatId: 7,
      title: "Transport",
      titleInCode: "transport",
      teaser: "Hoe geraak ik van A naar B?",
      description:
        'Hou je rekening met de bereikbaarheid van je bestemming voor je iets boekt? Kies je voor lekker ver weg van alle drukte of de bedrijvigheid van een stad? Ken je "Zomer zonder vliegen" en de nieuwste generatie lange afstands- en nachttreinen?',
      pic1: "Klimax_thema_reizen__transport.jpg",
      inspirationCause: "Verbranden van fossiele brandstoffen",
      inspirationEffect: "Temperatuurstijging",
      inspirationSolution: "Verplaatsen volgens het STO(e)P-principe",
      flipstate: false,
      link1:
        "https://climatechallenge.be/nl/themas/hoe-ons-klimaatvriendelijk-verplaatsen#4-klimaatvriendelijk-op-vakantie",
      link1Text: "Climate challenge: hoe ons klimaatvriendelijk verplaatsen",
      link2: "",
      link2Text: "",
    },
    {
      id: 2044,
      sort: "theme",
      themeCatId: 7,
      title: "Op kamp",
      titleInCode: "op-kamp",
      teaser: "Leven op het ritme van de wind en van de zon.",
      description:
        "Een kampterrein met een bosje (klimbomen en kampen bouwen!) of zit je liever aan een beekje of meer (dammen bouwen! bommetje!)? Al eens greppels moeten graven tegen het water, of siësta moeten houden wegens té warm weer? Heimwee naar je schermpjes?",
      pic1: "Klimax_thema_reizen__op-kamp.jpg",
      inspirationCause: "Bevolkings- en welvaartsgroei",
      inspirationEffect: "Meer extreem weer",
      inspirationSolution: "Klimaatadaptatie",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 2045,
      sort: "theme",
      themeCatId: 8,
      title: "Eigen idee",
      titleInCode: "eigen-idee",
      teaser: "Bedenk zelf een spetterend thema.",
      description:
        "Welk thema houdt je leerlingen bezig, is brandend actueel of schuift je leerplan naar voor? Formuleer enkele boeiende triggervragen i.v.m. je eigen thema die je leerlingen en jezelf breder aan het denken zetten. Vanuit deze triggervragen kan je verder kijken hoe het thema linkt aan de klimaat hot topics: met welke oorzaken, gevolgen en mogelijke oplossingen heeft je thema te maken? Veel succes!",
      pic1: "Klimax_thema__Eigen-idee.jpg",
      inspirationCause: "Met welke oorzaken heeft jouw thema te maken?",
      inspirationEffect: "Met welke gevolgen heeft jouw thema te maken?",
      inspirationSolution: "Met welke oplossingen heeft jouw thema te maken?",
      flipstate: false,
      link1: "",
      link1Text: "",
      link2: "",
      link2Text: "",
    },
    {
      id: 100,
      topicCatId: 1,
      sort: "topic",
      title: "Verbranden van fossiele brandstoffen",
      titleInCode: "verbranden-van-fossiele-brandstoffen",
      teaser: "zorgt voor meer broeikasgassen.",
      description:
        "De verbranding van fossiele brandstoffen heeft een aandeel van 71% in het versterken van het broeikaseffect. Fossiele brandstoffen zoals steenkool, aardolie en aardgas, vinden hun oorsprong in de resten van plantaardig en dierlijk leven van tientallen miljoenen tot honderden miljoenen jaren geleden. Ze zijn eenvoudig te winnen en zitten boordevol energie. Ze worden gebruikt om energie op te wekken, ons te verplaatsen en elektriciteit op te wekken. Van de drie fossiele brandstoffen is steen-/bruinkool de meest vervuilende brandstof. Steenkool produceert twee keer zoveel CO<sub>2</sub> per eenheid energie als aardgas.",
      pic1: "Klimax_hottopic_verbranden-van-fossiele-brandstoffen.jpg",
      link1Text: "De essentie: we moeten af van fossiele brandstoffen",
      link1:
        "https://sogetinformed.com/nl/information/de-essentie-we-moeten-af-van-de-fossiele-brandstoffen",
      link2Text: "Wat is het probleem met fossiele brandstoffen?",
      link2:
        "https://climatechallenge.be/nl/themas/naar-100-hernieuwbare-energie",
      link3Text: "De oorzaken van klimaatverandering",
      link3: "https://klimaat.be/klimaatverandering/oorzaken",
      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 101,
      topicCatId: 1,
      sort: "topic",
      title: "Veranderingen in landgebruik",
      titleInCode: "veranderingen-in-landgebruik",
      teaser: "zoals ontbossing of droogleggen van veenmoerassen.",
      description:
        "Veranderingen in landgebruik door bvb. ontbossing en het droogleggen van veenmoerassen, zorgen voor een aandeel van ongeveer 9% binnen de totale uitstoot van de broeikasgassen. Dit gebeurt meestal om plaats te maken voor akkerbouw (bv voor monocultuur van soja als veevoeder of palmolie), veeteelt, mijnbouw, grote infrasturctuurwerken zoals stuwmeren,… Bossen en veengebieden hebben heel wat CO<sub>2</sub> in de bodem opgeslagen. Bij het ontginnen van deze gebieden komt die CO<sub>2</sub> vrij. Ze spelen ook een belangrijke rol in de opname van broeikasgassen.",
      pic1: "Klimax_hottopic_veranderingen-in-landgebruik.jpg",
      link1Text:
        "Wereldwijde veranderingen in landgebruik zijn groter dan gedacht",
      link1:
        "https://www.wur.nl/nl/onderzoek-resultaten/onderzoeksinstituten/environmental-research/show-wenr/wereldwijde-veranderingen-in-landgebruik-zijn-groter-dan-gedacht.htm",
      link2Text: "Bodem, land en klimaatverandering",
      link2:
        "https://www.eea.europa.eu/nl/ema-signalen/signalen-2019/artikelen/bodem-land-en-klimaatverandering",
      link3Text:
        "Mensen veranderden een derde van het landoppervlak van de aarde in 60 jaar tijd",
      link3:
        "https://www.mo.be/nieuws/mensen-veranderden-een-derde-van-het-landoppervlak-van-de-aarde-60-jaar-tijd",
      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 102,
      sort: "topic",
      topicCatId: 1,
      title: "Veeteelt",
      titleInCode: "veeteelt",
      teaser: 'Dierlijke "uitlaatgassen".',
      description:
        "Herkauwers (koeien, schapen, geiten en buffels) produceren bij hun vertering speciale gassen (vooral methaan) in de vorm van het laten van boeren en (in mindere mate) scheten. Dit methaan vinden we niet terug bij de vertering door pluimvee of varkens, en daardoor is het vlees van deze dieren een stuk klimaatvriendelijker. De veeteelt zorgt voor 11 à 14,5% van het versterkt broeikaseffect. Hierin is de ontbossing, productie van veevoeder en transport inbegrepen.",
      pic1: "Klimax_hottopic_veeteelt.jpg",
      link1Text: "Veeteelt is een belangrijke vorm van methaan",
      link1:
        "https://climatechallenge.be/nl/themas/hoe-klimaatvriendelijk-eten#veeteelt-is-een-belangrijke-vorm-van-methaan",
      link2Text: "Vleesverbruik",
      link2: "https://klimaat.be/dagelijkse-acties/thuis/koken",
      link3Text: "Klimaat en veehouderij",
      link3: "https://www.vlaanderen.be/publicaties/klimaat-en-veehouderij",
      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 103,
      sort: "topic",
      topicCatId: 1,
      title: "Industriële processen",
      titleInCode: "industriële-processen",
      teaser: "Productie van materialen komt niet zonder kost.",
      description:
        "De meeste industriële processen, zoals de productie van materialen maar ook de verwerking van afval, verbruiken veel energie die veelal wordt opgewekt door de verbranding van fossiele brandstoffen. De industriële sector die wereldwijd het meest bijdraagt aan de klimaatverandering is de kalkproductie en -verwerking. Deze veroorzaakt 3,5% van de huidige klimaatopwarming. In de productie van cement worden kalk en klei onder hoge temperaturen samengevoegd. Bij de verhitting van kalk komt CO<sub>2</sub> vrij. In Vlaanderen zijn ijzer/staal, chemie en raffinaderijen de industriële sectoren met de hoogste CO<sub>2</sub>-uitstoot.",
      pic1: "Klimax_hottopic_Industriele-processen.jpg",
      link1Text: "Materialen en klimaat",
      link1:
        "https://vlaanderen-circulair.be/nl/kennis/wat-is-het/materialen-en-klimaat",
      link2Text: "",
      link2: "",
      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 104,
      sort: "topic",
      topicCatId: 1,
      title: "Bevolkings- en welvaartsgroei",
      titleInCode: "(bevolkings--en-welvaarts)groei",
      teaser: "Grenzen aan de groei.",
      description:
        "Hoe meer mensen er op de wereld zijn, hoe meer voedsel en energie er nodig is. Vooral de bevolking van rijke landen blijkt, door haar (groeiende) consumptie, een impact te hebben op de klimaatverandering. Een combinatie van bevolkingsgroei, welvaartstoename en groeiende consumptie per persoon zorgt, zonder een drastische verandering in voedsel- en energieproductie, voor een versterking van het broeikaseffect.",
      pic1: "Klimax_hottopic_bevolkings-en-welvaartsgroei.jpg",
      link1Text:
        "Zijn er qua uitstoot van broeikasgassen niet gewoon te veel mensen op aarde?",
      link1:
        "https://www.klimaathelpdesk.org/answers/zijn-er-niet-gewoon-te-veel-mensen-op-aarde/",
      link2Text: "",
      link2: "",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 105,
      sort: "topic",
      topicCatId: 2,
      title: "Temperatuurstijging",
      titleInCode: "temperatuurstijging",
      teaser: "De gemiddelde temperatuur van de aarde loopt op.",
      description:
        "Zowel continenten als oceanen absorberen warmte. Dat zorgt ervoor dat hun temperatuur gemiddeld oploopt en dat klimaatzones (en bijhorende vegetatietypes) langzaam verschuiven. Er zit een vertraging op het proces. Klimaatwijzigingen die we nu zien, zijn vooral het gevolg van de uitstoot in de tweede helft van de vorige eeuw. Temperatuursveranderingen kunnen lokaal heel verschillend zijn. Bepaalde gebieden warmen sneller op, andere helemaal niet.",
      pic1: "Klimax_hottopic_temperatuurstijging.jpg",
      link1Text: "De opwarming van de aarde",
      link1:
        "https://klimaat.be/klimaatverandering/waargenomen-veranderingen/opwarming-van-de-aarde",
      link2Text: "",
      link2: "",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 106,
      sort: "topic",
      topicCatId: 2,
      title: "Zeespiegelstijging",
      titleInCode: "zeespiegelstijging",
      teaser: "Water aan de lippen?",
      description:
        "Door de hogere temperatuur van het zeewater zet het water uit (thermische expansie) en ontstaat er een zeespiegelstijging. In tweede instantie draagt ook het smelten van landijs bij tot de zeespiegelstijging.",
      pic1: "Klimax_hottopic_zeespiegelstijging.jpg",
      link1Text: "Impact op oceanen",
      link1:
        "https://klimaat.be/klimaatverandering/waargenomen-veranderingen/oceanen",
      link2Text: "Zeespiegelstijging",
      link2: "https://klimaat.vmm.be/themas/zeespiegelstijging",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 107,
      sort: "topic",
      topicCatId: 2,
      title: "Meer extreem weer",
      titleInCode: "meer-extreem-weer",
      teaser: "Té droog, té heet, té nat, té veel wind,...",
      description:
        "De invloed van klimaatverandering op het weer kan lokaal en in de tijd heel sterk verschillen. Algemeen is het duidelijk dat klimaatverandering zorgt voor een hogere kans op extreem weer. De kans op hittegolven en lange, droge periodes wordt groter. Neerslag zal op kortere periodes overvloedig vallen, met overstromingen of modderstromen tot gevolg. Ook de kans op de ontwikkeling van sterkere tropische stormen (orkanen, cyclonen, tyfoons) neemt toe. Als gevolg van deze extreme weersomstandigheden zullen oogsten vaker mislukken, wordt drinkwater in bepaalde gebieden schaarser en zullen we vaker overstromingen meemaken.",
      pic1: "Klimax_hottopic_meer-extreem-weer.jpg",
      link1Text: "Extreem weer, wat is dat eigenlijk?",
      link1:
        "https://www.scientists4climate.be/extreem-weer-wat-is-dat-eigenlijk/?lang=nl",
      link2Text: "Extreem weer en klimaatverandering",
      link2:
        "https://www.knmi.nl/kennis-en-datacentrum/achtergrond/extreem-weer-en-klimaatverandering",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 108,
      sort: "topic",
      topicCatId: 2,
      title: "Natuur en mens onder druk",
      titleInCode: "natuur-en-mens-onder-druk",
      teaser: "Aanpassen of opkrassen?",
      description:
        "Naar geologische maatstaven gaat de huidige klimaatopwarming razendsnel: minstens tienmaal sneller dan de snelste natuurlijke temperatuurstijging die ooit werd geregistreerd. De natuur en de mens proberen zich aan te passen maar dit lukt niet altijd omdat het zo snel gebeurt. Daarom sterven plant- en diersoorten uit. Gebieden die te maken krijgen met hitte en droogte of overstromingen, worden bovendien gevoelig voor schaarste en conflicten wat op hun beurt zorgt voor klimaatvluchtelingen.",
      pic1: "Klimax_hottopic_natuur-en-mens-onder-druk.jpg",
      link1Text: "Impact op biodiversiteit",
      link1: "https://klimaat.be/klimaatverandering/gevolgen/biodiversiteit",
      link2Text:
        "Klimaatverandering versterkt de migraties van bevolkingsgroepen",
      link2: "https://klimaat.be/klimaatverandering/gevolgen/klimaatmigratie",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 109,
      sort: "topic",
      topicCatId: 2,
      title: "Klimaatonrechtvaardigheid",
      titleInCode: "klimaatonrechtvaardigheid",
      teaser: "Wie wordt het hardst getroffen?",
      description:
        "De gevolgen van de klimaatverandering treffen niet iedereen even hard. Regio's met de hoogste historische uitstoot worden voorlopig minder hard getroffen. Hun welvaart, die ze verworven door de uitstoot van broeikasgassen, helpt hen om meer weerstand te bieden tegen gevolgen of om zich vlotter te kunnen aanpassen aan veranderende omstandigheden. Deze onrechtvaardigheid is op nagenoeg elke internationale klimaattop een belangrijk item dat wordt besproken. Aan landen met hoge historische uitstoot wordt gevraagd om hun klimaatverantwoordelijkheid op te nemen door financiële middelen te storten in een internationaal klimaatfonds.",
      pic1: "Klimax_hottopic_klimaatonrechtvaardigheid.jpg",
      link1Text: "Klimaatverandering bedreigt de armste mensen ter wereld",
      link1: "https://klimaat.be/klimaatverandering/gevolgen/klimaatmigratie",
      link2Text:
        "Zijn alle landen verantwoordelijk voor de klimaatverandering?",
      link2: "https://klimaatbrigade.be/themas/klimaatverandering",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1010,
      sort: "topic",
      topicCatId: 2,
      title: "Feedback loops",
      titleInCode: "feedback-loops",
      teaser: "Versterkende terugkoppelingsmechanismen.",
      description:
        "De aarde kent een aantal zelfversterkende kringlopen. Deze worden positieve terugkoppelingsmechanismen of feedback loops genoemd. Verandert het klimaat (afkoeling of opwarming), dan gaan bepaalde gevolgen die verandering versterken. De hoeveelheid ijs is hiervan het bekendste voorbeeld. Is er minder ijsoppervlakte door opwarming, dan wordt er meer lichtstraling geabsorbeerd (ipv gereflecteerd), waardoor de opwarming versterkt wordt. Zo zijn er tientallen bekende positieve terugkoppelingsmechanismen. Een relatief kleine klimaatverandering kan daardoor omslaan tot een grote, moeilijk te controleren verandering. Wetenschappers schatten in dat er vanaf een globale opwarming van 2°C heel veel positieve terugkoppelingsmechanismen geactiveerd worden.",
      pic1: "Klimax_hottopic_versterkende-terugkoppelingsmechanismen.jpg",
      link1Text: "Feedback mechanismen en de koolstofcyclus.",
      link1: "https://www.grootoudersvoorhetklimaat.be/klimaatles5/",
      link2Text:
        "Vanaf welke temperatuurstijging zijn de klimaatveranderingen onomkeerbaar?",
      link2:
        "https://www.klimaathelpdesk.org/answers/vanaf-welke-temperatuurstijging-zijn-de-klimaatveranderingen-onomkeerbaar/",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1011,
      sort: "topic",
      topicCatId: 3,
      title: "Hernieuwbare energiebronnen",
      titleInCode: "omschakelen-naar-hernieuwbare-energiebronnen",
      teaser: "Van grijze naar groene energie.",
      description:
        "We moeten af van energieproductie op basis van fossiele bandstoffen en moeten evolueren naar een maatschappij die draait op elektriciteit, opgewekt door hernieuwbare energie (zoals zonne- en windenergie). Investeer in hernieuwbare energie en kies voor een groene elektriciteitsleverancier. Kernenergie door kernsplijting is niet hernieuwbaar en heeft een aantal andere nadelen (kernafval). Bovendien belemmeren altijd draaiende kerncentrales de overgang naar hernieuwbare energie (die andere eisen stelt aan het energienetwerk). Op korte termijn kan kernenergie wel een rol spelen om energie te produceren zonder directe CO<sub>2</sub>-uitstoot.",
      pic1: "Klimax_hottopic_omschakelen-naar-hernieuwbare-energiebronnen.jpg",
      link1Text: "Groene stroom kiezen",
      link1: "https://klimaat.be/dagelijkse-acties/thuis/groene-stroom-kiezen",
      link2Text: "",
      link2: "",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1012,
      sort: "topic",
      topicCatId: 3,
      title: "Natuurlijke CO<sub>2</sub>-opslag",
      titleInCode: "natuurlijke-CO<sub>2</sub>-opslag",
      teaser: "Bomen en andere planten slaan CO<sub>2</sub> op.",
      description:
        "Planten halen CO<sub>2</sub> uit de lucht en slaan deze op in biomassa. Help dit proces een handje door zoveel mogelijk groen aan te planten en te zorgen voor een duurzaam (bos)beheer (bvb. kies voor papier en houten meubels met een erkend label voor duurzaam bosbeheer). Bebossing en meer groen kan op grote schaal veel CO<sub>2</sub> opvangen en de CO<sub>2</sub> -concentratie doen dalen.",
      pic1: "Klimax_hottopic_natuurlijke-CO2opslag.jpg",
      link1Text: "CO<sub>2</sub>-opslag",
      link1: "https://mijntuinlab.be/page/CO<sub>2</sub>-opslag/",
      link2Text: "Wat legt het beste CO₂ vast in de natuur?",
      link2:
        "https://www.klimaathelpdesk.org/answers/wat-legt-het-beste-CO<sub>2</sub>-vast-de-natuur/",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1013,
      sort: "topic",
      topicCatId: 3,
      title: "CO<sub>2</sub>-captatie en -opslag",
      titleInCode: "co2-captatie-en-opslag",
      teaser: "Ondergrondse opslag van opgevangen CO<sub>2</sub>.",
      description:
        "Het opvangen van CO<sub>2</sub> gebeurt volgens verschillende technologische processen. Je kan enerzijds CO<sub>2</sub> rechtstreeks uit de lucht onttrekken voor opslag. Dit proces vraagt veel energie, waardoor het momenteel in de praktijk nog niet vaak wordt toegepast. Anderzijds kan je CO<sub>2</sub> capteren uit gesloten industriële processen, wat in de praktijk al vaker gedaan wordt. In Vlaanderen bestaan geen opslagmogelijkheden voor CO<sub>2</sub>, dus moet die opgevangen CO<sub>2</sub> vervoerd worden naar landen die wel over stockagemogelijkheden beschikken. Industriële CO<sub>2</sub>-captatie voor opslag mag niet primeren boven initiatieven om CO<sub>2</sub>-uitstoot te verminderen, maar is wel nodig om sectoren waarin CO<sub>2</sub>-uitstoot niet te vermijden is, te decarboniseren.",
      pic1: "Klimax_hottopic_technologische-CO2-opslag.jpg",
      link1Text: "CO<sub>2</sub>-captatie als wapen tegen de klimaatopwarming",
      link1:
        "https://vito.be/nl/van-broeikasgas-tot-grondstof-co2-captatie-als-wapen-tegen-de-klimaatopwarming#:~:text=tegen%20de%20klimaatopwarming-,Van%20broeikasgas%20tot%20grondstof%3A%20CO2-captatie%20als%20wapen%20tegen%20de,bouwmaterialen%20en%20vele%20andere%20toepassingen.",
      link2Text: "CO<sub>2</sub>-afvangen… en er iets anders mee doen",
      link2: "https://vito.be/nl/co2-afvangen-gebruiken-en-opslaan",
      link3Text: "De Vlaamse CCUS-strategie",
      link3:
        "https://www.vlaanderen.be/veka/energie-en-klimaatbeleid/energie-en-klimaatbeleid-voor-ondernemingen/carbon-capture-utilisation-and-storage-ccus",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1014,
      sort: "topic",
      topicCatId: 3,
      title: "Onnodig energieverbruik vermijden",
      titleInCode: "onnodig-energieverbruik-vermijden",
      teaser: "De beste energie is de energie die je niet verbruikt.",
      description:
        "Door in alle sectoren en thuis, verstandig en efficiënt om te gaan met energie is veel besparing mogelijk. Dat kan via gedragsverandering en via technologische hulpmiddelen. Als we minder energie nodig hebben, moet er minder geproduceerd worden.  Als ooit al de energie die we verbruiken van hernieuwbare bronnen komt, lijkt dat minder relevant, maar ook dan zal het van belang blijven om verstandig met energie om te gaan. Er zullen immers altijd ruimte en grondstoffen nodig zijn voor energieproductie en verdeling.",
      pic1: "Klimax_hottopic_onnodig-energieverbruik-vermijden.jpg",
      link1Text: "Thuis energie besparen",
      link1: "https://klimaat.be/dagelijkse-acties/thuis/energie-besparen",
      link2Text: "",
      link2: "",

      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1015,
      sort: "topic",
      topicCatId: 3,
      title: "Informeer jezelf",
      titleInCode: "informeer-jezelf",
      teaser: "over de klimaatcrisis en inspireer anderen.",
      description:
        "Schiet zelf of samen met anderen in actie. Informeer jezelf, ga op onderzoek uit, bekijk hoe je klimaatvriendelijker kan gaan leven, praat er verbindend over met je omgeving en stimuleer ook anderen. Motiveer beslissingsnemers, jouw bank, ondernemingen, je gemeente, de overheid,... om acties te ondernemen. Zo rijkt jouw invloedsfeer veel verder dan je denkt.",
      pic1: "Klimax_hottopic_informeer-jezelf.jpg",
      link1Text: "So get informed",
      link1: "https://www.sogetinformed.com/nl",
      link2Text: "Actie 1 - informeer jezelf",
      link2: "https://10klimaatacties.be/hoeveel-tijd-hebben-we-nog/",
      link3Text: "Syntheserapport van het IPCC",
      link3:
        "https://klimaat.be/klimaatverandering/waargenomen-veranderingen/ipcc-rapporten/2023-syntheserapport",
      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1016,
      sort: "topic",
      topicCatId: 3,
      title: "Doeltreffend klimaatbeleid",
      titleInCode: "doeltreffend-klimaatbeleid",
      teaser: "Ambitieuze doelstellingen nastreven.",
      description:
        "Een transitie naar een klimaatneutrale samenleving is onmogelijk zonder doelgericht klimaatbeleid. Overheden kunnen verscheidene instrumenten, zoals wetgeving, investeringen, handhaving,... inzetten om vooropgestelde doelstellingen te bereiken.",
      pic1: "Klimax_hottopic_doeltreffend-klimaatbeleid.jpg",
      link1Text: "Klimaatbeleid",
      link1: "https://klimaat.be/",
      link2Text: "Ingrediënten voor een beter klimaatbeleid",
      link2: "https://www.klimaatpanel.be/nl/resultaten",
      link3Text: "Energie- en klimaatbeleid",
      link3:
        "https://www.vlaanderen.be/natuur-milieu-en-klimaat/klimaatverandering-en-energie-en-klimaatbeleid",
      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1017,
      sort: "topic",
      topicCatId: 3,
      title: "Klimaatadaptatie",
      titleInCode: "klimaatadaptatie",
      teaser: "Omgaan met de gevolgen.",
      description:
        "Klimaatadaptatie is het proces waarbij de samenleving zich aanpast aan de actuele en verwachte klimaatverandering en de effecten ervan. Meer gevarieerde natuur en weinig verharding zijn bijvoorbeeld belangrijk om bepaalde gevolgen van klimaatverandering af te remmen: groen en water werken immers verkoelend en groene gebieden zijn een spons die de negatieve impact van overstromingen op andere plaatsten kan milderen.",
      pic1: "Klimax_hottopic_klimaatadaptatie.jpg",
      link1Text: "Aanpassen aan de gevolgen van klimaatverandering",
      link1:
        "https://climatechallenge.be/nl/themas/hoe-lossen-we-de-klimaatverandering-op",
      link2Text: "Klimaat en adaptatie",
      link2: "https://www.vmm.be/klimaat",
      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1018,
      sort: "topic",
      topicCatId: 3,
      title: "Meer plantaardige voeding",
      titleInCode: "meer-plantaardige-voeding",
      teaser: "Minder producten van herkauwers.",
      description:
        "Rundvlees of lamsvlees en dierlijke melkproducten zijn allemaal afkomstig van herkauwers die een enorm grote klimaatimpact hebben. Eet zoveel mogelijk plantaardig en kies bij het eten van vlees vooral voor kip en in mindere mate varkensvlees. Deze hebben een aanzienlijk kleinere klimaatimpact dan rundsvlees.",
      pic1: "Klimax_hottopic_meer-plantaardige-voeding.jpg",
      link1Text: "Hoe klimaatvriendelijk eten?",
      link1: "https://klimaatbrigade.be/themas/voeding",
      link2Text: "Wat is een eiwitshift?",
      link2: "https://omgeving.vlaanderen.be/wat-is-een-eiwitshift",
      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1019,
      sort: "topic",
      topicCatId: 3,
      title: "Verplaatsen volgens het STO(e)P-principe",
      titleInCode: "verplaatsen-volgens-het-sto(e)p-principe",
      teaser: "Verbrand caloriën ipv brandstof.",
      description:
        "STO(e)P= Eerst stappen, dan trappen, dan gebruik maken van het openbaar vervoer en tot slot, als het echt niet anders kan, gebruik dan een (elektrische) personenwagen. Deel wagens zoveel mogelijk, beperk je vliegtuigreizen en neem zoveel mogelijk de trein binnen Europa. Als je toch het vliegtuig moet nemen, compenseer je vlucht dan door te investeren in een gecertificeerd CO<sub>2</sub> -compensatieproject.",
      pic1: "Klimax_hottopic_verplaatsen-volgens-het-STOePprincipe.jpg",
      link1Text: "STO(e)P-principe",
      link1:
        "https://www.duurzame-mobiliteit.be/nieuws/stop-principe-stappers-trappers-openbaar-vervoer-en-prive-gemotoriseerd-vervoer",
      link2Text: "Maak duurzame verplaatsingen de gemakkelijkste keuze",
      link2:
        "https://www.gezondleven.be/settings/gezonde-gemeente/gezonde-publieke-ruimte/gezond-mobiliteitssysteem",
      flipstate: false,
      __1: "",
      __2: "",
    },
    {
      id: 1020,
      sort: "topic",
      topicCatId: 3,
      title: "Zuinig met grondstoffen",
      titleInCode: "zuinig-met-grondstoffen",
      teaser: "Minder is meer.",
      description:
        "De ontginning van grondstoffen en de productie van goederen hebben een hoge energievraag. Mijnbouw zorgt bovendien voor grote veranderingen in landgebruik (oa. ontbossing). Daarom is het belangrijk om in te zetten op zaken zoals: afval vermijden, meer circulair omgaan met materialen, meer diensten ipv bezittingen, hergebruik, tweedehands, lenen, delen, repareren, ...",
      pic1: "Klimax_hottopic_zuinig-met-grondstoffen.jpg",
      link1Text: "Wat is de circulaire economie",
      link1: "https://vlaanderen-circulair.be/nl/kennis",
      link2Text: "Piraminder",
      link2: "https://detransformisten.be/artikel/de-piraminder",
      flipstate: false,
      __1: "",
      __2: "",
    },

    {
      "": "Werkvorm 1",
      id: 301,
      sort: "method",
      goalMethodCatId: 1,
      goalMethodCategory: "Systeemdenken",
      title: "Staartvertelling",
      titleInCode: "staartvertelling",
      teaser: "Verbind oorzaken en gevolgen.",
      explanation: [
        "Kies een verhaal waarin oorzakelijke verbanden kunnen worden gelegd.",
        "Gebruik prenten om het verhaal te ondersteunen en leg ze in de juiste volgorde.",
        "Neem één van de prenten weg en bespreek: welke prent is weg? Sta stil bij de oorzakelijke verbanden: 'Als ..., dan ....', 'hierdoor ...', 'Hoe komt het dat ...', 'Wat gebeurt er als ...'",
        "Voeg bijkomende prenten toe (die niet voorkomen in het verhaal) en verken oorzakelijke verbanden.",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "kleuters",
      leeftijdscategorie2: "bao onderbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["kleuters", "bao onderbouw"],
      flipstate: false,
      link1: "https://djapo.be/werkvorm-staartvertelling/",
      link2: "",
      link3: "",
      links: "[https://djapo.be/werkvorm-staartvertelling/ ]",
    },
    {
      "": "Werkvorm 2",
      id: 302,
      sort: "method",
      goalMethodCatId: 1,
      goalMethodCategory: "Systeemdenken",
      title: "Terug- en vooruitdenken",
      titleInCode: "terug--en-vooruitdenken",
      teaser: "Ontrafel een probleem.",
      explanation: [
        "Vertrek vanuit een concrete situatie of foto en omschrijf wat je ziet.",
        "Vooruitdenken: denk na over de gevolgen, tot wat leidt dit probleem?",
        "Terugdenken: denk na over de oorzaken, hoe is dit probleem ontstaan?",
        "Visualiseer verbindingen tussen oorzaken en gevolgen.",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "so bovenbouw",

      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://djapo.be/methode-systeemdenken/",
      link2:
        "https://www.edoschool.be/static/media/download_methodiek_terug%20en%20vooruitdenken.259e0539.pdf",
      link3: "",
      links:
        "[Bron: Djapo (2014) Methode systeemdenken: 40 lessen en meer dan 50 werkvormen. Acco, Leuven., https://www.edoschool.be/static/media/download_methodiek_terug%20en%20vooruitdenken.259e0539.pdf]",
    },
    {
      "": "Werkvorm 3",
      id: 303,
      sort: "method",
      goalMethodCatId: 1,
      goalMethodCategory: "Systeemdenken",
      title: "De duurzaamheidsbril",
      titleInCode: "de-duurzaamheidsbril",
      teaser: "Kijk vanuit diverse invalshoeken.",
      explanation: [
        "Benader een thema vanuit de 3P's (People, Planet, Profit), tijds- en ruimtedimensie:",
        " Wat is de invloed van/op de mens?",
        "Wat is de invloed van/op de planeet?",
        "Wat is de invloed van/op de economie?",
        "Is het een lokaal of globaal thema?",
        "Is het altijd zo geweest en hoe zal het verder evolueren?",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.edoschool.be/static/media/download_alle%20fiches.4533f7d0.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.edoschool.be/static/media/download_alle%20fiches.4533f7d0.pdf]",
    },
    {
      "": "Werkvorm 4",
      id: 304,
      sort: "method",
      goalMethodCatId: 1,
      goalMethodCategory: "Systeemdenken",
      title: "Klimaatcollage",
      titleInCode: "klimaatcollage",
      teaser: "Verken oorzaken en gevolgen op een visuele manier.",
      explanation: [
        "Download de 42 (oorzaak-gevolg-oplossing) kaarten op basis van de IPPC-klimaatrapporten.",
        "Ga samen op zoek naar verbanden tussen de kaarten.",
        "Maak een collage die inzicht geeft in de complexe relaties tussen oorzaken en gevolgen.",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.klascement.net/downloadbaar-lesmateriaal/147619/the-climate-fresk-of-klimaatcollage-spelkaarten/?previous",
      link2: "",
      link3: "",
      links:
        "[https://www.klascement.net/downloadbaar-lesmateriaal/147619/the-climate-fresk-of-klimaatcollage-spelkaarten/?previous]",
    },
    {
      "": "Werkvorm 5",
      id: 305,
      sort: "method",
      goalMethodCatId: 1,
      goalMethodCategory: "Systeemdenken",
      title: "De SDG bril",
      titleInCode: "de-sdg-bril",
      teaser: "Onderzoek onderdelen en het groter geheel.",
      explanation: [
        "Kies een concrete situatie of thema uit de leefwereld.",
        "Verken de Duurzame Ontwikkelingsdoelstellingen (SDG's). Je kan van gelijk welke SDG vertrekken.",
        "Ga op zoek naar verbanden: op welke manier is het thema gelinkt aan de SDG, wat is de impact?",
        "Visualiseer verbindingen tussen verschillende SDG's en het gekozen thema (bv. in een mindmap).",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.edoschool.be/static/media/download_alle%20fiches.4533f7d0.pdf ",
      link2: "",
      link3: "",
      links:
        "[https://www.edoschool.be/static/media/download_alle%20fiches.4533f7d0.pdf ]",
    },
    {
      "": "Werkvorm 6",
      id: 306,
      sort: "method",
      goalMethodCatId: 1,
      goalMethodCategory: "Systeemdenken",
      title: "Wicked Problems Plaza",
      titleInCode: "wicked-problems-plaza",
      teaser: "Verken vanuit verschillende perspectieven.",
      explanation: [
        "De Wicked Problems Plaza is een reis doorheen verschillende ‘ruimtes’ waarbij vanuit een specifieke rol gekeken wordt naar een duurzaamheidsuitdaging.",
        "Verken controversen en standpunten van andere stakeholders.",
        "Ga samen op zoek naar oplossingen.",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "so bovenbouw",
      leeftijdscategorie2: "",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so bovenbouw"],
      flipstate: false,
      link1:
        "https://duurzaamheidseducatie.paddlecms.net/sites/default/files/2021-01/wickedproblemplaza.pdf",
      link2: "",
      link3: "",
      links:
        "[https://duurzaamheidseducatie.paddlecms.net/sites/default/files/2021-01/wickedproblemplaza.pdf]",
    },
    {
      "": "Werkvorm 7",
      id: 307,
      sort: "method",
      goalMethodCatId: 2,
      goalMethodCategory: "Toekomstdenken",
      title: "Droom van Terra",
      titleInCode: "droom-van-terra",
      teaser: "Denk na over een droomwereld.",
      explanation: [
        "Lees het KaMOShibai-verhaal 'Terra is ziek'.",
        "Je kan samen met de kinderen nadenken hoe fijn het is om in de wereld van Terra te leven.",
        "Bedenk samen hoe die droomwereld er zou uitzien.",
        "Je kan deze droom samen met tekeningen, werkjes van de kinderen en ander beeldmateriaal omzetten in een beeldmontage die de kinderen kunnen delen met andere leerlingen, ouders, ...",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "kleuters",
      leeftijdscategorie2: "",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["kleuters"],
      flipstate: false,
      link1:
        "https://www.mosvlaanderen.be/aan-de-slag/kamoshibais/kamoshiwijzer-bij-terra-is-ziek#hoofdeen",
      link2: "",
      link3: "",
      links:
        "[https://www.mosvlaanderen.be/aan-de-slag/kamoshibais/kamoshiwijzer-bij-terra-is-ziek#hoofdeen]",
    },
    {
      "": "Werkvorm 8",
      id: 308,
      sort: "method",
      goalMethodCatId: 2,
      goalMethodCategory: "Toekomstdenken",
      title: "Praatplaat 'de ideale wereld'",
      titleInCode: "praatplaat-'de-ideale-wereld'",
      teaser: "Laat je inspireren en denk vooruit.",
      explanation: [
        "Vertrek van een plaat die 'de ideale wereld' verbeeldt: een wereld zonder klimaatverandering (bv. 'Groeten uit Transitië') of zonder (gender)ongelijkheid (bv. plaat Equiterra).",
        "Zoek een aspect op de kaart dat je aantrekt (bv. iets geassocieerd met het ideaalbeeld voor een betere wereld).",
        "Verken hoe dit gelinkt is aan klimaatverandering.",
        "Bedenk wat we kunnen doen om bij te dragen aan dit aspect (= actie bedenken).",
      ],
      pic1: "Klimax_werkvorm__Toekomst_denken.jpg",
      leeftijdscategorie1: "so onderbouw",
      leeftijdscategorie2: "so bovenbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.unwomen.org/en/digital-library/multimedia/2020/2/illustration-equiterra-gender-equality-utopia",
      link2: "",
      link3: "",
      links:
        "[https://www.unwomen.org/en/digital-library/multimedia/2020/2/illustration-equiterra-gender-equality-utopia]",
    },
    {
      "": "Werkvorm 9",
      id: 309,
      sort: "method",
      goalMethodCatId: 2,
      goalMethodCategory: "Toekomstdenken",
      title: "Lego Serious Play",
      titleInCode: "lego-serious-play",
      teaser: "Bouw aan een gedeelde toekomstvisie.",
      explanation: [
        "Verdeel de klas in groepjes.",
        "Introduceer een hoofdvraag, bv. 'hoe ziet de stad van de toekomst eruit?'.",
        "Bouw een Lego-model gelinkt aan de hoofdvraag.",
        "Presenteer en bespreek de verschillende bouwmodellen.",
      ],
      pic1: "Klimax_werkvorm__Toekomst_denken.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://duurzaamheidseducatie.paddlecms.net/sites/default/files/2021-01/legoseriousplay.pdf",
      link2: "https://www.climateplay.org/",
      link3: "",
      links:
        "[https://duurzaamheidseducatie.paddlecms.net/sites/default/files/2021-01/legoseriousplay.pdf, https://www.climateplay.org/]",
    },
    {
      "": "Werkvorm 10",
      id: 310,
      sort: "method",
      goalMethodCatId: 2,
      goalMethodCategory: "Toekomstdenken",
      title: "Scenario-ontwerper",
      titleInCode: "scenario-ontwerper",
      teaser: "Creëer je eigen scenario's.",
      explanation: [
        "Surf naar de My 2050-webtool en bekijk de inleidende animatie die kort de klimaatproblematiek en <nobr>-uitdagingen</nobr> uitlegt.",
        "Werk een eigen toekomstscenario uit.",
        "Bekijk de resultaten van je scenario, vergelijk met anderen en ga hierover in dialoog.",
      ],
      pic1: "Klimax_werkvorm__Toekomst_denken.jpg",
      leeftijdscategorie1: "so bovenbouw",
      leeftijdscategorie2: "",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so bovenbouw"],
      flipstate: false,
      link1: "http://webtool.my2050.be/index.html?levers=1111211111111/nl",
      link2: "",
      link3: "",
      links: "[http://webtool.my2050.be/index.html?levers=1111211111111/nl]",
    },
    {
      "": "Werkvorm 11",
      id: 311,
      sort: "method",
      goalMethodCatId: 2,
      goalMethodCategory: "Toekomstdenken",
      title: "Brief naar de toekomst",
      titleInCode: "brief-naar-de-toekomst",
      teaser: "Schrijf een brief naar jezelf.",
      explanation: [
        "Vertrek vanuit een concrete vraag of probleem.",
        "Schrijf een brief naar jezelf (in de toekomst) waarin je beschrijft hoe het probleem is aangepakt.",
        "Lees de brieven hardop voor, bespreek ze en overweeg ze te bewaren voor de toekomst.",
      ],
      pic1: "Klimax_werkvorm__Toekomst_denken.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://www.funretrospectives.com/letters-to-the-future/",
      link2: "https://www.futureme.org/",
      link3: "",
      links:
        "[https://www.funretrospectives.com/letters-to-the-future/, https://www.futureme.org/]",
    },
    {
      "": "Werkvorm 12",
      id: 312,
      sort: "method",
      goalMethodCatId: 2,
      goalMethodCategory: "Toekomstdenken",
      title: "Dubbele cirkel",
      titleInCode: "dubbele-cirkel",
      teaser: "Maak een tijdreis in je hoofd.",
      explanation: [
        "Deze werkvorm speelt zich af 7 jaar vooruit in de tijd.",
        "Ga in een dubbele cirkel zitten, met de gezichten naar elkaar. De binnenste cirkel leeft zich in in het perspectief van iemand die al 7 jaar met het specifieke (klimaat)probleem bezig is, de buitenste cirkel is niet betrokken.",
        "De buitenste cirkel stelt vragen aan de binnen-cirkel. Na elke vraag schuift de buitenste cirkel één plaats op. Mogelijke vragen kan je via de link vinden.",
        "Afronden kan met een plenair nagesprek.",
      ],
      pic1: "Klimax_werkvorm__Toekomst_denken.jpg",
      leeftijdscategorie1: "so onderbouw",
      leeftijdscategorie2: "so bovenbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://sites.arteveldehogeschool.be/pronet/pronet/aan-de-slag/domeinoverzicht/ambitie-en-belangen-hanteren/toekomst-denken-werkvorm",
      link2: "",
      link3: "",
      links:
        "[https://sites.arteveldehogeschool.be/pronet/pronet/aan-de-slag/domeinoverzicht/ambitie-en-belangen-hanteren/toekomst-denken-werkvorm]",
    },
    {
      "": "Werkvorm 13",
      id: 313,
      sort: "method",
      goalMethodCatId: 2,
      goalMethodCategory: "Toekomstdenken",
      title: "Op grootmoeders schoot",
      titleInCode: "op-grootmoeders-schoot",
      teaser: "Flits naar de toekomst.",
      explanation: [
        "Flits jezelf 60 jaar verder in de toekomst. De mensheid heeft de klimaatverandering overleefd, de toekomst ziet er positief uit.",
        "Verbeeld je dat je kleinkind bij jou op schoot zit. Je kleinkind vraagt jou hoe je dit voor elkaar hebt gekregen. Welke acties hebben beleidsmakers, organisaties, burgers genomen om hier te geraken? Vertel!",
      ],
      pic1: "Klimax_werkvorm__Toekomst_denken.jpg",
      leeftijdscategorie1: "so onderbouw",
      leeftijdscategorie2: "so bovenbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "",
      link2: "",
      link3: "",
      links: [],
    },
    {
      "": "Werkvorm 14",
      id: 314,
      sort: "method",
      goalMethodCatId: 2,
      goalMethodCategory: "Toekomstdenken",
      title: "Backcasting",
      titleInCode: "backcasting",
      teaser: "Verbeeld je toekomst en denk terug.",
      explanation: [
        "Formuleer een droom gelinkt aan een centraal thema: Hoe ziet de ideale wereld er in de toekomst uit? Hoe leven we? Hoe ziet de omgeving eruit?",
        "Denk terug vanuit die gedroomde situatie: Wat is er nodig om die droom te realiseren? Wie dien je daarvoor te betrekken?",
        "Verzamel concrete ideeën om mee (stapjes richting) de wereld van morgen te realiseren: Wat kunnen we nu al doen om de wereld te veranderen in onze droomwereld?",
      ],
      pic1: "Klimax_werkvorm__Toekomst_denken.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.edoschool.be/static/media/download_fiche_backcasting.07d77e0e.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.edoschool.be/static/media/download_fiche_backcasting.07d77e0e.pdf]",
    },
    {
      "": "Werkvorm 16",
      id: 315,
      sort: "method",
      goalMethodCatId: 3,
      goalMethodCategory: "Strategisch denken",
      title: "Rangschikking",
      titleInCode: "rangschikking",
      teaser: "Leg toekomstscenario's in de balans.",
      explanation: [
        "Rangschik verschillende scenario’s of maatregelen op een lijn van slecht tot goed (bv. 'Alle mensen mogen maximum één keer per week vlees eten.')",
        "Leg uit waarom je voor deze rangschikking kiest. Argumenteer.",
        "Bespreek: Wat zou iemand zeggen die het niet met jou eens is?",
      ],
      pic1: "Klimax_werkvorm__Strategische_Competentie.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://www.klimaatburgers.be/materiaal",
      link2: "",
      link3: "",
      links: "[https://www.klimaatburgers.be/materiaal]",
    },
    {
      "": "Werkvorm 17",
      id: 316,
      sort: "method",
      goalMethodCatId: 3,
      goalMethodCategory: "Strategisch denken",
      title: "Feitencheckers",
      titleInCode: "feitencheckers",
      teaser: "Trek de betrouwbaarheid van bronnen in twijfel.",
      explanation: [
        "Verzamel verschillende bronnen (bv. artikel uit de krant, bericht op TikTok, wetenschappelijk artikel, filmpje van YouTube, uitspraak van een buurman, het journaal, speech van een minister, ...)",
        "Verken de bronnen en geef een kleur: Waar= groen / Niet waar= rood / Twijfel= oranje.",
        "Vergelijk de resultaten en besteed aandacht aan de twijfels en verschillen: Waarom twijfel je aan deze bron? Waarom is deze bron volgens jou waar en volgens anderen niet?",
      ],
      pic1: "Klimax_werkvorm__Strategische_Competentie.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://kritikat.be/materiaal/inspiratiegids",
      link2: "https://www.feitencheckers.be",
      link3: "",
      links:
        "[https://kritikat.be/materiaal/inspiratiegids, www.feitencheckers.be]",
    },
    {
      "": "Werkvorm 18",
      id: 317,
      sort: "method",
      goalMethodCatId: 3,
      goalMethodCategory: "Strategisch denken",
      title: "Aan de slag met cijfers",
      titleInCode: "aan-de-slag-met-cijfers",
      teaser: "Duik in data en maak toekomstvoorspellingen.",
      explanation: [
        "Kies een onderzoeksvraag gelinkt aan de impact van klimaatverandering in de eigen buurt/gemeente/ ...",
        "Surf naar de online impacttool, met deze tool zie je de gevolgen van de klimaatverandering in jouw buurt (op vlak van hitte, droogte, wateroverlast, ...).",
        "Selecteer een thema en focusgebied, verken op de kaart en laat de grafieken spreken.",
        "Ga verder aan de slag met de cijfers en kaarten, en bekijk de toekomstvoorspellingen.",
        "De inzichten uit deze oefening kunnen gebruikt worden in een vervolgactiviteit rond systeemdenken of (samen) actie ondernemen.",
      ],
      pic1: "Klimax_werkvorm__Strategische_Competentie.jpg",
      leeftijdscategorie1: "so bovenbouw",
      leeftijdscategorie2: "",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so bovenbouw"],
      flipstate: false,
      link1: "https://provincies.incijfers.be/dashboard/dashboard",
      link2: "https://klimaat.vmm.be/tools/impact",
      link3: "",
      links:
        "[https://provincies.incijfers.be/dashboard/dashboard, https://klimaat.vmm.be/tools/impact]",
    },
    {
      "": "Werkvorm 19",
      id: 318,
      sort: "method",
      goalMethodCatId: 3,
      goalMethodCategory: "Strategisch denken",
      title: "True or false",
      titleInCode: "true-or-false",
      teaser: "Word een fake news detective.",
      explanation: [
        "Verzamel verschillende nieuwsberichten (zowel echte als fake).",
        "Lees individueel 1 nieuwsbericht, wissel in duo uit waarover het artikel gaat. Schuif door naar een nieuwe duo-partner en herhaal de oefening op basis van wat je hoorde in het vorige gesprek.",
        "Nabespreking: Wat valt er op? Hoe kan je echte nieuwsberichten van fake berichten onderscheiden?",
      ],
      pic1: "Klimax_werkvorm__Strategische_Competentie.jpg",
      leeftijdscategorie1: "so onderbouw",
      leeftijdscategorie2: "so bovenbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.klascement.net/downloadbaar-lesmateriaal/158733/true-or-false-spelvorm-fake-news/?previous",
      link2: "https://shop.aanstokerij.be/632-true-or-false-.html",
      link3: "",
      links:
        "[https://www.klascement.net/downloadbaar-lesmateriaal/158733/true-or-false-spelvorm-fake-news/?previous, https://shop.aanstokerij.be/632-true-or-false-.html]",
    },
    {
      "": "Werkvorm 20",
      id: 319,
      sort: "method",
      goalMethodCatId: 3,
      goalMethodCategory: "Strategisch denken",
      title: "Bronnenlawine",
      titleInCode: "bronnenlawine",
      teaser: "Raak bedolven door bronnen.",
      explanation: [
        "Vertrek vanuit een concrete (onderzoeks)vraag of probleem.",
        "Zoek individueel bronnen gelinkt aan de vraag/probleem (rond oorzaken, gevolgen, oplossingen, ...).",
        "Vergelijk en inventariseer alle bronnen: Welke bronnen bevestigen elkaar? Welke bronnen geven tegenstrijdige informatie? Vanuit welke\ncontext is de bron opgesteld?…",
      ],
      pic1: "Klimax_werkvorm__Strategische_Competentie.jpg",
      leeftijdscategorie1: "so onderbouw",
      leeftijdscategorie2: "so bovenbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://edoschool.be/static/media/download_alle%20fiches.4533f7d0.pdf#page=18",
      link2: "",
      link3: "",
      links:
        "[https://edoschool.be/static/media/download_alle%20fiches.4533f7d0.pdf#page=18]",
    },
    {
      "": "Werkvorm 21",
      id: 320,
      sort: "method",
      goalMethodCatId: 3,
      goalMethodCategory: "Strategisch denken",
      title: "Gedachte-experiment",
      titleInCode: "gedachte-experiment",
      teaser: "Maak strategische keuzes.",
      explanation: [
        "Vertrek vanuit een stelling, bv.: 'Stel dat je burgemeester bent van jouw dorp of stad en je hebt een onbeperkt budget... Welke drie veranderingen zou jij voorstellen om het leven te verbeteren?'",
        "Neem vijf minuten de tijd om over dit gedachte-experiment na te denken.",
        "Bespreek daarna de verschillende antwoorden in kleine groepjes. Vergelijk de verschillende antwoorden: Wat komt terug? Welke verschillen vallen op?",
        "Klassikaal gesprek. Welke interessante ideeën uit de kleine groepjes blijven hangen. Lijst deze op en bespreek deze. Wat zijn achterliggende redeneringen bij de verschillende toekomstscenario's? Waarop zijn deze gebaseerd?",
      ],
      pic1: "Klimax_werkvorm__Strategische_Competentie.jpg",
      leeftijdscategorie1: "so onderbouw",
      leeftijdscategorie2: "so bovenbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://www.klimaatburgers.be/materiaal",
      link2: "",
      link3: "",
      links: "[https://www.klimaatburgers.be/materiaal]",
    },
    {
      "": "Werkvorm 22",
      id: 321,
      sort: "method",
      goalMethodCatId: 3,
      goalMethodCategory: "Strategisch denken",
      title: "Storytelling",
      titleInCode: "storytelling",
      teaser: "Verbeeld toekomstideeën.",
      explanation: [
        "Vertrek vanuit een tekst of een reportage rond een centraal thema. Onderbreek of pauzeer het verhaal op het moment dat er over mogelijke oplossingen wordt gesproken.",
        "Denk na over mogelijke oplossingen en verzamel de ideeën.",
        "Werk in kleine groepjes een verhaal uit op basis van één oplossing. Gebruik een vast sjabloon waarbij het idee diepgaand wordt verkend (Wat is de impact op de gebruiker, de omgeving, ...?).",
        "Klassikaal uitwisselen en kritische reflectie (Wat is het gevolg van het idee? Is het een haalbaar idee? Zal dit idee het probleem deels of volledig oplossen? Welke maatregelen zijn hiervoor nodig?)",
      ],
      pic1: "Klimax_werkvorm__Strategische_Competentie.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.digitaldestiny.eu/_files/ugd/e7b5cf_48700731fad749c692c22a0857328836.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.digitaldestiny.eu/_files/ugd/e7b5cf_48700731fad749c692c22a0857328836.pdf]",
    },
    {
      "": "Werkvorm 24",
      id: 322,
      sort: "method",
      goalMethodCatId: 4,
      goalMethodCategory: "(Samen) actie ondernemen",
      title: "Bucketlist van de klas",
      titleInCode: "bucketlist-van-de-klas",
      teaser: "Maak een overzicht van acties die je samen wil uitvoeren.",
      explanation: [
        "Verzamel in kleine groepjes ideeën voor een concreet probleem. Alle ideeën zijn welkom!",
        "Maak per groepje een selectie van 5 topideeën.",
        "Verzamel de top 5 van alle groepjes en verken welke ideeën haalbaar zijn. Gebruik hiervoor categorieën: gewoon - onhaalbaar - nieuw en realiseerbaar.",
        "Bedenk een plan van aanpak voor nieuwe en realiseerbare ideeën.",
      ],
      pic1: "Klimax_werkvorm__Samen_Actie_Ondernemen.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "so bovenbouw",
      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://www.gezondleven.be/happysnacks/bucketlist",
      link2: "",
      link3: "",
      links: "[https://www.gezondleven.be/happysnacks/bucketlist]",
    },
    {
      "": "Werkvorm 25",
      id: 323,
      sort: "method",
      goalMethodCatId: 4,
      goalMethodCategory: "(Samen) actie ondernemen",
      title: "4 in 4",
      titleInCode: "4-in-4",
      teaser: "Denk out of the box en kom snel tot nieuwe ideeën.",
      explanation: [
        "Verdeel een blad in 4 gelijke vakken. ",
        "Teken of werk per vakje in 1 minuut een idee uit. Alle ideeën zijn welkom, ook ‘zotte’ ideeën zijn op hun plaats!",
        "Verzamel alle ideeën.",
        "Bespreek en combineer ideeën in functie van haalbaarheid.",
      ],
      pic1: "Klimax_werkvorm__Samen_Actie_Ondernemen.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "so bovenbouw",
      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.edoschool.be/static/media/download_methodiek_creatief%20denken.ebb635ba.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.edoschool.be/static/media/download_methodiek_creatief%20denken.ebb635ba.pdf]",
    },
    {
      "": "Werkvorm 26",
      id: 324,
      sort: "method",
      goalMethodCatId: 4,
      goalMethodCategory: "(Samen) actie ondernemen",
      title: "Posterbeurs",
      titleInCode: "posterbeurs",
      teaser: "Presenteer bevindingen en inzichten.",
      explanation: [
        "Maak een poster waarin teruggeblikt wordt op een proces: Wat werd bijgeleerd?, Welke acties werden bedacht en uitgevoerd?, ...",
        "Presenteer en deel inzichten tijdens een posterbeurs: Wat is blijven hangen?, Wat nemen ze mee naar de toekomst?, ...",
        "Nodig niet alleen medeleerlingen uit, maar ook ouders, buurtbewoners en leerkrachten van nabijgelegen scholen, ...",
      ],
      pic1: "Klimax_werkvorm__Samen_Actie_Ondernemen.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "so bovenbouw",
      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.edoschool.be/static/media/download_methodiek_posterbeurs.dd3edf56.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.edoschool.be/static/media/download_methodiek_posterbeurs.dd3edf56.pdf]",
    },
    {
      "": "Werkvorm 27",
      id: 325,
      sort: "method",
      goalMethodCatId: 4,
      goalMethodCategory: "(Samen) actie ondernemen",
      title: "Bevraging",
      titleInCode: "bevraging",
      teaser: "Verzamel feedback op concrete acties.",
      explanation: [
        "Test concrete acties uit.",
        "Hou een bevraging om feedback te verzamelen over de acties (bij medeleerlingen, leerkrachten, buurtbewoners, ouders, ...).",
        "Bespreek de uitkomst van de bevraging: Hoe hebben de verschillende betrokkenen de acties ervaren? Wat werd als sterk ervaren?  Suggesties of verbeterpunten?, ...",
        "Stuur toekomstige acties bij a.d.h.v. de bevindingen.",
      ],
      pic1: "Klimax_werkvorm__Samen_Actie_Ondernemen.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.edoschool.be/static/media/download_methodiek_bevraging.bb36f9c7.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.edoschool.be/static/media/download_methodiek_bevraging.bb36f9c7.pdf]",
    },
    {
      "": "Werkvorm 28",
      id: 326,
      sort: "method",
      goalMethodCatId: 4,
      goalMethodCategory: "(Samen) actie ondernemen",
      title: "Striphelden in actie",
      titleInCode: "striphelden-in-actie",
      teaser: "Duik in de fantasiewereld en genereer nieuwe ideeën.",
      explanation: [
        "Vertrek vanuit een levensecht probleem of situatie.",
        "Verken personages uit strips, boeken, films of sociale media. Omschrijf de typische kenmerken van deze personages (eigenschap, talent, speciale kracht, ...).",
        "Bedenk ideeën voor het probleem vanuit het perspectief van de personages. Deze ideeën hoeven in eerste instantie niet realistisch of haalbaar te zijn.",
        "Overloop klassikaal de ideeën en geef samen aan welke haalbaar zijn om in het echt uit te voeren.",
      ],
      pic1: "Klimax_werkvorm__Samen_Actie_Ondernemen.jpg",
      leeftijdscategorie1: "kleuters",
      leeftijdscategorie2: "bao onderbouw",
      leeftijdscategorie3: "bao bovenbouw",
      leeftijdscategorie4: "",
      ages: ["kleuters", "bao onderbouw", "bao bovenbouw"],
      flipstate: false,
      link1:
        "https://www.digitaldestiny.eu/_files/ugd/e7b5cf_4fbb1e46083d43f598a7b84de0add7a9.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.digitaldestiny.eu/_files/ugd/e7b5cf_4fbb1e46083d43f598a7b84de0add7a9.pdf]",
    },
    {
      "": "Werkvorm 29",
      id: 327,
      sort: "method",
      goalMethodCatId: 4,
      goalMethodCategory: "(Samen) actie ondernemen",
      title: "Brainwriting",
      titleInCode: "brainwriting",
      teaser: "Bouw verder op elkaars ideeën.",
      explanation: [
        "Verken een centraal probleem: Wie heeft ermee te maken? Wat hebben deze persoon of personen nodig om ...? Hoe kunnen we dit probleem oplossen?",
        "Bedenk of teken individueel drie tot vijf ideeën. Vertrek vanuit de vraag: 'Hoe zouden we ..(nood/behoefte/probleem) kunnen aanpakken? Hoe kunnen we dit oplossen?'",
        "Schuif de ideeën door naar de volgende leerling die verder aanvult. Schuif een aantal keren door.",
        "Verzamel klassikaal alle ideeën op een longlist en selecteer ideeën a.d.h.v. een stemming of criteria.",
      ],
      pic1: "Klimax_werkvorm__Samen_Actie_Ondernemen.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "so bovenbouw",
      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.digitaldestiny.eu/_files/ugd/e7b5cf_17011d57bc4c41fbb2be65e5f5bff1a9.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.digitaldestiny.eu/_files/ugd/e7b5cf_17011d57bc4c41fbb2be65e5f5bff1a9.pdf]",
    },
    {
      "": "Werkvorm 30",
      id: 328,
      sort: "method",
      goalMethodCatId: 5,
      goalMethodCategory: "Omgaan met waarden en normen",
      title: "Hoepel in",
      titleInCode: "hoepel-in",
      teaser: "Argumenteer je keuzes.",
      explanation: [
        "Kies een prentenboek of verhaal, met bijhorende voorwerpen of afbeeldingen.",
        "Vertel het verhaal tot het fragment waarover je een keuzevraag stelt. Leg in elke hoepel een voorwerp of personage/afbeelding.",
        "Stel je keuzevraag en kies tussen hoepel 1 of hoepel 2. Argumenteer.",
        "Herhaal met een andere afbeelding of voorwerp.",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Waarden_Normen.jpg",
      leeftijdscategorie1: "kleuters",
      leeftijdscategorie2: "bao onderbouw",
      leeftijdscategorie3: "bao bovenbouw",
      leeftijdscategorie4: "",
      ages: ["kleuters", "bao onderbouw", "bao bovenbouw"],
      flipstate: false,
      link1: "https://djapo.be/werkvorm-hoepel-in-filosoferen/",
      link2:
        "https://www.digitaldestiny.eu/_files/ugd/e7b5cf_cd4668ec145a40ba82fb435d7bb16e8e.pdf",
      link3: "",
      links:
        "[https://djapo.be/werkvorm-hoepel-in-filosoferen/, https://www.digitaldestiny.eu/_files/ugd/e7b5cf_cd4668ec145a40ba82fb435d7bb16e8e.pdf]",
    },
    {
      "": "Werkvorm 31",
      id: 329,
      sort: "method",
      goalMethodCatId: 5,
      goalMethodCategory: "Omgaan met waarden en normen",
      title: "De praatstok",
      titleInCode: "de-praatstok",
      teaser: "Ga op een respectvolle manier met elkaar in dialoog.",
      explanation: [
        "Zit in een kring en vertrek vanuit een centrale vraag/stelling/situatie/....",
        "Geef de praatstok door aan je buur.",
        "Wie de praatstok heeft mag spreken, de anderen mogen de spreker niet onderbreken. Je bepaalt  zelf of je spreekt of niet. Als je niets wilt zeggen geef je de stok door.",
        "Laat de praatstok 2 (of meer) keer rondgaan.",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Waarden_Normen.jpg",
      leeftijdscategorie1: "kleuters",
      leeftijdscategorie2: "bao onderbouw",
      leeftijdscategorie3: "bao bovenbouw",
      leeftijdscategorie4: "",
      ages: ["kleuters", "bao onderbouw", "bao bovenbouw"],
      flipstate: false,
      link1: "",
      link2: "",
      link3: "",
      links: [],
    },
    {
      "": "Werkvorm 32",
      id: 330,
      sort: "method",
      goalMethodCatId: 5,
      goalMethodCategory: "Omgaan met waarden en normen",
      title: "Rollenspel",
      titleInCode: "rollenspel",
      teaser: "Kruip in de huid van iemand anders.",
      explanation: [
        "Leef je in in een personage a.d.h.v. een korte bio (bv. een gepensioneerde vrouw die het niet breed heeft, een anderstalige nieuwkomer, een CEO, enz.). ",
        "Ga in debat op basis van een stelling vanuit het perspectief van je personage.",
        "Duid eventueel een moderator aan om het gesprek in goede banen te leiden.",
        "Reflecteer over het debat: Hoe was het om je in te leven in andere waarden en meningen? Sta jij daar anders tegenover?, ...",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Waarden_Normen.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.edoschool.be/static/media/download_fiche_persona.650ae4b0.pdf",
      link2: "https://11.be/klimaat-je-klas/les-2-debat",
      link3: "",
      links:
        "[https://www.edoschool.be/static/media/download_fiche_persona.650ae4b0.pdf, https://11.be/klimaat-je-klas/les-2-debat]",
    },
    {
      "": "Werkvorm 33",
      id: 331,
      sort: "method",
      goalMethodCatId: 5,
      goalMethodCategory: "Omgaan met waarden en normen",
      title: "Stellingenspel",
      titleInCode: "stellingenspel",
      teaser: "Breng je aan het twijfelen.",
      explanation: [
        "Verken enkele stellingen in kleine groep: Ben je het eens met deze uitspraken? Leg uit waarom je (niet) akkoord bent of twijfelt.",
        "Bespreek de resultaten in kleine groepjes: Over welke uitspraken hebben jullie een andere mening? Hoe komt dit? Over welke uitspraken was er het meeste twijfel? ",
        "Klassikaal gesprek. Kies één stelling en bespreek deze met de hele klas. Schrijf de stelling op het bord of leg de stelling in het midden van de cirkel. Onderzoek de verschillende perspectieven die leven in de klas. Stel verdiepende vragen: Waarom denk je dat? Is dat altijd en voor iedereen zo? Wat denken de anderen? Kan je dat aantonen? Is dit een feit of een mening? Zijn er nog andere mogelijkheden? Wat kunnen we hieruit besluiten?",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Waarden_Normen.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://www.klimaatburgers.be/materiaal",
      link2: "",
      link3: "",
      links: "[https://www.klimaatburgers.be/materiaal]",
    },
    {
      "": "Werkvorm 34",
      id: 332,
      sort: "method",
      goalMethodCatId: 5,
      goalMethodCategory: "Omgaan met waarden en normen",
      title: "Polarisatiestelling",
      titleInCode: "polarisatiestelling",
      teaser: "Kies een kant.",
      explanation: [
        "Vertrek vanuit een polariserende stelling (bv. wetenschap is altijd juist vs. wetenschap is een verzinsel).",
        "Positioneer je tegenover deze stelling (akkoord: links in de ruimte, niet akkoord: rechts, twijfel: midden).",
        "Argumenteer waarom je voor dat standpunt hebt gekozen.",
        "Indien er eensgezindheid is: Wat zou iemand kunnen zeggen die het daar niet mee eens is?",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Waarden_Normen.jpg",
      leeftijdscategorie1: "so onderbouw",
      leeftijdscategorie2: "so bovenbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://www.klimaatburgers.be/materiaal",
      link2: "http://www.wetenschapsbalans.be/materiaal/in-de-klas",
      link3: "",
      links:
        "[https://www.klimaatburgers.be/materiaal, http://www.wetenschapsbalans.be/materiaal/in-de-klas]",
    },
    {
      "": "Werkvorm 35",
      id: 333,
      sort: "method",
      goalMethodCatId: 5,
      goalMethodCategory: "Omgaan met waarden en normen",
      title: "Extreme meningen",
      titleInCode: "extreme-meningen",
      teaser: "Verken extremen.",
      explanation: [
        "Ga (in de buurt) op zoek naar extreme meningen en opvattingen over een probleem.",
        "Achterhaal a.d.h.v. een interview hoe deze meningen tot stand zijn gekomen.",
        "Verwerk de meningen, noden, wensen van de verschillende personen in 'persona'-fiches",
        "Wissel uit en breng de diverse meningen met elkaar in verband.",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Waarden_Normen.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.edoschool.be/static/media/download_methodiek_extreme%20meningen.76a3e3a8.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.edoschool.be/static/media/download_methodiek_extreme%20meningen.76a3e3a8.pdf]",
    },
    {
      "": "Werkvorm 37",
      id: 334,
      sort: "method",
      goalMethodCatId: 6,
      goalMethodCategory: "Omgaan met emoties",
      title: "Gevoelenstouw",
      titleInCode: "gevoelenstouw",
      teaser: "Laat emoties los.",
      explanation: [
        "Leg een stukje touw op de tafel en leg de smileys ernaast: de blije aan één uiteinde, de droevige aan het ander.",
        "Toon een voorwerp of een afbeelding en beschrijf hoe het gelinkt is aan het centrale thema.",
        "Denk na hoe je je hierbij voelt en hang je klassymbool/naam aan het touw.",
        "Vraag waarom je voor die plaats hebt gekozen: 'Wat betekent die plaats?',  'Waarom word jij blij/droevig van wat ik vertel?', ...",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Met_Gevoelens.jpg",
      leeftijdscategorie1: "kleuters",
      leeftijdscategorie2: "bao onderbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["kleuters", "bao onderbouw"],
      flipstate: false,
      link1:
        "https://djapo.be/wp-content/uploads/2022/06/Werkvorm-Gevoelenstouw-mondmaskers.pdf",
      link2: "",
      link3: "",
      links:
        "[https://djapo.be/wp-content/uploads/2022/06/Werkvorm-Gevoelenstouw-mondmaskers.pdf]",
    },
    {
      "": "Werkvorm 38",
      id: 335,
      sort: "method",
      goalMethodCatId: 6,
      goalMethodCategory: "Omgaan met emoties",
      title: "Emotioneel alfabet",
      titleInCode: "emotioneel-alfabet",
      teaser: "Geef uitdrukking aan je emoties.",
      explanation: [
        "Vraag de leerlingen hoe ze zich voelen n.a.v. een gesprek/verhaal, laat hen een gezichtje/persoon aanduiden waarmee ze zich verbonden voelen (zie voorbeelden via de links).",
        "Wat maakt dat ze zich zo voelen? Geef hen de kans dit te verwoorden. Geef zelf woorden als dit moeilijk blijkt.",
        "Ga in gesprek en bewaak veiligheid.",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Met_Gevoelens.jpg",
      leeftijdscategorie1: "kleuters",
      leeftijdscategorie2: "bao onderbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["kleuters", "bao onderbouw"],
      flipstate: false,
      link1: "https://www.wingsforkids.org/the-emotional-alphabet/",
      link2: "",
      link3: "",
      links: "[https://www.wingsforkids.org/the-emotional-alphabet/]",
    },
    {
      "": "Werkvorm 39",
      id: 336,
      sort: "method",
      goalMethodCatId: 6,
      goalMethodCategory: "Omgaan met emoties",
      title: "Fotoworkshop",
      titleInCode: "fotoworkshop",
      teaser: "Laat beelden spreken.",
      explanation: [
        "Selecteer foto's met bv. mensen of natuur.... in verschillende situaties gelinkt aan klimaatverandering.",
        "Bedenk individueel wat deze foto’s met je doen, hoe de mensen zich moeten voelen, welke andere emoties de prenten bij je opwekken.",
        "Bespreek de bevindingen in groepjes.",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Met_Gevoelens.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://duurzaamheidseducatie.paddlecms.net/sites/default/files/2021-01/Fotoworkshop.pdf",
      link2: "https://climatevisuals.org/",
      link3: "",
      links:
        "[https://duurzaamheidseducatie.paddlecms.net/sites/default/files/2021-01/Fotoworkshop.pdf, https://climatevisuals.org/]",
    },
    {
      "": "Werkvorm 40",
      id: 337,
      sort: "method",
      goalMethodCatId: 6,
      goalMethodCategory: "Omgaan met emoties",
      title: "Blobboom",
      titleInCode: "blobboom",
      teaser: "Geef uitdrukking aan je emoties.",
      explanation: [
        "Vraag de leerlingen hoe ze zich voelen n.a.v. een gesprek/verhaal/gebeurtenis/..., laat hen een gezichtje/persoon aanduiden waarmee ze zich verbonden voelen (zie voorbeelden via de links).",
        "Wat maakt dat ze zich zo voelen? Geef hen de kans dit te verwoorden. Geef zelf woorden als dit moeilijk blijkt.",
        "Ga in gesprek en bewaak veiligheid.",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Met_Gevoelens.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "so bovenbouw",
      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://i.pinimg.com/564x/53/db/38/53db38324095cceb6e587227aeb0e160.jpg",
      link2:
        "https://pro.katholiekonderwijs.vlaanderen/evaluatiebox-basisonderwijs/evaluatie-instrumenten/de-blobboom",
      link3: "",
      links:
        "[https://i.pinimg.com/564x/53/db/38/53db38324095cceb6e587227aeb0e160.jpg, https://pro.katholiekonderwijs.vlaanderen/evaluatiebox-basisonderwijs/evaluatie-instrumenten/de-blobboom]",
    },
    {
      "": "Werkvorm 41",
      id: 338,
      sort: "method",
      goalMethodCatId: 6,
      goalMethodCategory: "Omgaan met emoties",
      title: "Dialoogvragen",
      titleInCode: "dialoogvragen",
      teaser: "Argumenteer erop los.",
      explanation: [
        "Start van een centrale vraag: bv. 'Mag de opwarming van de aarde je koud laten?'",
        "Verzamel standpunten: Wat denk jij?,  Waarom?, Wie denkt iets anders?, ... ",
        "Vraag door en ga op zoek naar tegenargumenten: Kan je een (tegen) voorbeeld geven?, Wat zou iemand zeggen die het niet met jou eens is?, ...",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Met_Gevoelens.jpg",
      leeftijdscategorie1: "so onderbouw",
      leeftijdscategorie2: "so bovenbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://www.klimaatburgers.be/materiaal",
      link2: "",
      link3: "",
      links: "[https://www.klimaatburgers.be/materiaal]",
    },
    {
      "": "Werkvorm 42",
      id: 339,
      sort: "method",
      goalMethodCatId: 6,
      goalMethodCategory: "Omgaan met emoties",
      title: "De viskom",
      titleInCode: "de-viskom",
      teaser: "Weeg standpunten af.",
      explanation: [
        "Vorm 2 groepen: een panel van 6 die in discussie gaan over een vraag/standpunt ('de viskom') en de rest van de groep die errond zit ('de buitengroep').",
        "De buitengroep observeert in eerste instantie en neemt niet deel aan de discussie.",
        "Na verloop van tijd kan de buitengroep aansluiten bij de viskom en hun visie toevoegen aan de discussie.",
        "Nabespreking met de volledige groep: formuleren van conclusies en aanbevelingen.",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Met_Gevoelens.jpg",
      leeftijdscategorie1: "so bovenbouw",
      leeftijdscategorie2: "",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["so bovenbouw"],
      flipstate: false,
      link1:
        "https://duurzaamheidseducatie.paddlecms.net/sites/default/files/2021-01/fishbowl.pdf",
      link2: "",
      link3: "",
      links:
        "[https://duurzaamheidseducatie.paddlecms.net/sites/default/files/2021-01/fishbowl.pdf]",
    },
    {
      "": "Werkvorm 43",
      id: 340,
      sort: "method",
      goalMethodCatId: 6,
      goalMethodCategory: "Omgaan met emoties",
      title: "Concept cartoon",
      titleInCode: "concept-cartoon",
      teaser: "Leg je mening in de balans.",
      explanation: [
        "Zoek of maak een concept cartoon gelinkt aan het centrale thema.",
        "Denk individueel na over de uitspraken die op de cartoon staan. Bij welke uitspraak sluit je je het meest aan?",
        "Wissel uit en verken de verschillende meningen. Geef ook de kans om tijdens de uitwisseling van mening te veranderen.",
        "Nabespreking: Hoe was het om één uitspraak te kiezen? Wie veranderde zijn mening tijdens de oefening? Wat deed je twijfelen? Wat deed je nog sterker geloven in je eigen mening? ...",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Met_Gevoelens.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "so bovenbouw",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.digitaldestiny.eu/_files/ugd/e7b5cf_3bf92acbfc6e4022bb59d103c650efa4.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.digitaldestiny.eu/_files/ugd/e7b5cf_3bf92acbfc6e4022bb59d103c650efa4.pdf]",
    },
    {
      "": "Werkvorm 44",
      id: 341,
      sort: "method",
      goalMethodCatId: 6,
      goalMethodCategory: "Omgaan met emoties",
      title: "Gevoelensschijf",
      titleInCode: "gevoelensschijf",
      teaser: "Geef uitdrukking aan je gevoelens.",
      explanation: [
        "Vertrek vanuit een uitspraak, een onderwerp, ... gelinkt aan het centrale thema.",
        "Plaats je naam op de gevoelensschijf op basis van het gevoel dat de uitspraak of het onderwerp teweeg brengt.",
        "Ga hierover in gesprek: Wat maakt dat je dit gevoel hebt? Wie had hetzelfde gevoel? Wat merk je op? Wie twijfelde tussen meerdere gevoelens?",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Met_Gevoelens.jpg",
      leeftijdscategorie1: "kleuters",
      leeftijdscategorie2: "bao onderbouw",
      leeftijdscategorie3: "bao bovenbouw",
      leeftijdscategorie4: "",
      ages: ["kleuters", "bao onderbouw", "bao bovenbouw"],
      flipstate: false,
      link1:
        "https://www.digitaldestiny.eu/_files/ugd/e7b5cf_a7fe0b73ac0f443dbfe909923489712d.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.digitaldestiny.eu/_files/ugd/e7b5cf_a7fe0b73ac0f443dbfe909923489712d.pdf]",
    },
    {
      "": "Werkvorm 45",
      id: 342,
      sort: "method",
      goalMethodCatId: 1,
      goalMethodCategory: "Systeemdenken",
      title: "Omgevingsfoto",
      titleInCode: "omgevingsfoto",
      teaser: "Kijk vanuit verschillende blikken naar de wereld.",
      explanation: [
        "Trek een foto van iets wat je belangrijk vindt/opvalt/... op school, in de schoolomgeving of eigen thuis/buurt, gekoppeld aan het centrale thema.",
        "Wissel de foto's uit en verwoord waarom deze foto gekozen werd (koppeling met oorzaken/gevolgen/gebeurtenissen/oplossingen/...).",
        "Optioneel: verken achterliggende relaties en perspectieven via andere systeemdenken-methodieken (zie bv. 'Terug- en vooruitdenken').",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "so bovenbouw",
      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1:
        "https://www.edoschool.be/static/media/download_methodiek_omgevingsfotos.2ea318bf.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.edoschool.be/static/media/download_methodiek_omgevingsfotos.2ea318bf.pdf]",
    },
    {
      "": "Werkvorm 46",
      id: 343,
      sort: "method",
      goalMethodCatId: 1,
      goalMethodCategory: "Systeemdenken",
      title: "Siamese rups",
      titleInCode: "siamese-rups",
      teaser: "Maak een ketting van gevolgen.",
      explanation: [
        "Vertrek vanuit een situatie, feit, uitspraak of ervaring gekoppeld aan een centraal thema. Noteer dit in een cirkel (= hoofd van de 'rups').",
        "Verken de gevolgen en visualeer dit door de gevolgen in cirkels af te beelden aansluitend bij het 'hoofd van de rups', er ontstaat een ketting van gevolgen.",
        "Start terug bij het begin en bedenk andere mogelijke gevolgen vertrekkende vanuit dezelfde situatie, herhaal stap 2. Hierdoor ontstaat een 2de ketting ('rups').",
        "Onderzoek de verschillende gevolgkettingen en ga hierover in gesprek: Welke gevolgen zijn positief? Welke gevolgen hebben een negatieve impact?",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "",
      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw"],
      flipstate: false,
      link1:
        "https://www.digitaldestiny.eu/_files/ugd/e7b5cf_120dad5a617f4249b104a5259661c5cd.pdf",
      link2: "https://djapo.be/systeemdenken-over-de-actualiteit-siamese-rups/",
      link3: "",
      links:
        "[https://www.digitaldestiny.eu/_files/ugd/e7b5cf_120dad5a617f4249b104a5259661c5cd.pdf, https://djapo.be/systeemdenken-over-de-actualiteit-siamese-rups/]",
    },
    {
      "": "Werkvorm 47",
      id: 344,
      sort: "method",
      goalMethodCatId: 5,
      goalMethodCategory: "Omgaan met waarden en normen",
      title: "Een andere wereld",
      titleInCode: "een-andere-wereld",
      teaser: "Kijk door de ogen van iemand anders.",
      explanation: [
        "Vertrek vanuit een verhaal, een filmpje of reportage gelinkt aan een centraal thema.",
        "Kies een of meerdere voorwerpen, situaties of uitspraken waarover je door de ogen van de personages uit het verhaal/filmpje/reportage wil nadenken.",
        "Toon het voorwerp, de situatie ... die je wil verkennen en bedenk verbanden met de personages (Wat denkt personage x over dit voorwerp? Hoe voelt personage x zich hierbij? Waarom voelt hij/zij zich zo hierbij? ...).",
        "Bespreek de verschillende perspectieven en verzamel in een mindmap.",
      ],
      pic1: "Klimax_werkvorm__Omgaan_Waarden_Normen.jpg",
      leeftijdscategorie1: "bao bovenbouw",
      leeftijdscategorie2: "so onderbouw",
      leeftijdscategorie3: "",
      leeftijdscategorie4: "",
      ages: ["bao bovenbouw", "so onderbouw"],
      flipstate: false,
      link1:
        "https://www.digitaldestiny.eu/_files/ugd/e7b5cf_b3248d0950ec49d39d592c9f001b9822.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.digitaldestiny.eu/_files/ugd/e7b5cf_b3248d0950ec49d39d592c9f001b9822.pdf]",
    },
    {
      "": "Werkvorm 48",
      id: 345,
      sort: "method",
      goalMethodCatId: 1,
      goalMethodCategory: "Systeemdenken",
      title: "Probleemboom",
      titleInCode: "probleemboom",
      teaser: "Visualiseer oorzaken, gevolgen en oplossingen.",
      explanation: [
        "Vertrek vanuit een centrale vraag of probleem (bv. na het bekijken van een reportage, een foto of een artikel).",
        "Verken het probleem: Wat is er fout gelopen? Wat is het probleem? Wat zijn oorzaken? Wat zijn gevolgen?",
        "Visualiseer in kleine groepjes de verschillende elementen van het probleem op een lege 'probleemboom': stam = het probleem, wortels = oorzaken, takken = gevolgen.",
        "Wissel klassikaal uit.",
      ],
      pic1: "Klimax_werkvorm__Systeemdenken.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "",
      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw"],
      flipstate: false,
      link1:
        "https://www.digitaldestiny.eu/_files/ugd/e7b5cf_4e371b4d1f4f4164bfc56b928920c46c.pdf",
      link2: "",
      link3: "",
      links:
        "[https://www.digitaldestiny.eu/_files/ugd/e7b5cf_4e371b4d1f4f4164bfc56b928920c46c.pdf]",
    },
    {
      "": "Werkvorm 49",
      id: 346,
      sort: "method",
      goalMethodCatId: 4,
      goalMethodCategory: "(Samen) actie ondernemen",
      title: "Praatplaat",
      titleInCode: "",
      teaser: "Laat je inspireren en verbreed je horizonten.",
      explanation: [
        "Maak een praatplaat waarop verschillende acties (op verschillende niveau's) te zien zijn.",
        "Verken de praatplaat en selecteer acties. Wissel uit.",
        "Verzamel ideeën en analyseer: Wat is haalbaar & uitvoerbaar? Welke aspecten van het probleem worden aangepakt? Tot wie richt zich deze actie (individueel, collectief, politiek niveau)?, ...",
      ],
      pic1: "Klimax_werkvorm__Samen_Actie_Ondernemen.jpg",
      leeftijdscategorie1: "bao onderbouw",
      leeftijdscategorie2: "bao bovenbouw",
      leeftijdscategorie3: "so onderbouw",
      leeftijdscategorie4: "so bovenbouw",
      ages: ["bao onderbouw", "bao bovenbouw", "so onderbouw", "so bovenbouw"],
      flipstate: false,
      link1: "https://11.be/klimaat-je-klas/les-3-actie",
      link2: "",
      link3: "",
      links: "",
    },

    {
      id: 13,
      sort: "goal",
      BGColor: "yellow",
      title: "onderzoeken",
    },
  ],
};

function reducer(store = initialState, action) {
  switch (action.type) {
    // case "FETCH_PAGE_INFO":
    //   return {
    //     ...store,
    //   };
    case "CHANGE_MODAL_STATE":
      return {
        ...store,
        modalState: action.payload.newModalState,
      };
    case "SET_PLACEHOLDERS_NEEDED":
      return {
        ...store,
        placeholdersNeeded: action.payload.newPlaceholdersNeeded,
      };
    case "ADD_PINNED_CARD":
      // Pin toevoegen aan je selectie
      return {
        ...store,
        pinnedCards: [...store.pinnedCards, action.payload.newPinnedCard],
      };
    case "REMOVE_PINNED_CARD":
      // Pin wegdoen uit je selectie
      return {
        ...store,
        pinnedCards: [
          ...store.pinnedCards.filter(
            (pinnedCard, index) => index !== action.payload.index
          ),
        ],
        placeholdersNeeded:
          store.pinnedCards.length +
            (store.pinnedGoals.length > 0 ? 1 : 0) +
            1 <
          7
            ? [...store.placeholdersNeeded, "placeholder"]
            : [...store.placeholdersNeeded],
      };
    case "FLIP_PINNED_CARDS":
      // Flip alle functie op de homepage: alles achterkant tonen! Hij past meteen flipstates aan in pinnedCards én in cards.
      return {
        ...store,
        cards: [
          ...store.cards.map((card) => {
            // if (
            //   store.pinnedCards.some((pinnedCard) => pinnedCard.id === card.id)
            // ) {
            return {
              ...card,
              flipstate: true,
            };
            // } else {
            //   return {
            //     ...card,
            //   };
            // }
          }),
        ],
        pinnedCards: [
          ...store.pinnedCards.map((pinnedCard) => {
            return {
              ...pinnedCard,
              flipstate: true,
            };
          }),
        ],
        // pinnedGoalsFlipstate: true,
      };
    case "UNFLIP_PINNED_CARDS":
      // Flip alle functie op de homepage: alles voorkant tonen! Hij past meteen flipstates aan in pinnedCards én in cards.
      return {
        ...store,
        cards: [
          ...store.cards.map((card) => {
            // if (
            //   store.pinnedCards.some((pinnedCard) => pinnedCard.id === card.id)
            // ) {
            return {
              ...card,
              flipstate: false,
            };
            // } else {
            //   return {
            //     ...card,
            //   };
            // }
          }),
        ],
        pinnedCards: [
          ...store.pinnedCards.map((pinnedCard) => {
            return {
              ...pinnedCard,
              flipstate: false,
            };
          }),
        ],
        // pinnedGoalsFlipstate: false,
      };
    case "FLIP_CARDS":
      // Laat 1 kaartjes flippen in de array van ALLE cards (op cardniveau leest hij flipstate uit uit cards, niet uit pinnedCards; vandaar ook meteen voor de veiligheid aanpassen in zowel cards als pinnedCards)
      return {
        ...store,
        cards: [
          ...store.cards.map((card) => {
            if (card.id === action.payload.id) {
              return { ...card, flipstate: !card.flipstate };
            } else {
              return { ...card };
            }
          }),
        ],
        pinnedCards: [
          ...store.pinnedCards.map((pinnedCard) => {
            if (pinnedCard.id === action.payload.id) {
              return {
                ...pinnedCard,
                flipstate: !pinnedCard.flipstate,
              };
            } else {
              return { ...pinnedCard };
            }
          }),
        ],
      };
    case "DELETE_PINNED_CARDS":
      // Maakt de array van gepinde cards helemaal leeg (Bord leegmaken)
      return {
        ...store,
        pinnedCards: [],
        pinnedGoals: [],
        placeholdersNeeded: [
          "placeholder",
          "placeholder",
          "placeholder",
          "placeholder",
          "placeholder",
        ],
      };

    case "TOGGLE_SIDEBAR":
      return {
        ...store,
        sidebarState: !store.sidebarState,
        mobilemenuState: false,
      };
    case "CLOSE_SIDEBAR":
      return {
        ...store,
        sidebarState: false,
        mobilemenuState: false,
      };
    case "TOGGLE_PRINT":
      return {
        ...store,
        printState: !store.printState,
      };
    case "CLOSE_PRINT":
      return {
        ...store,
        printState: false,
      };

    case "TOGGLE_MOBILEMENU":
      return {
        ...store,
        mobilemenuState: !store.mobilemenuState,
        sidebarState: false,
      };
    case "CLOSE_MOBILEMENU":
      return {
        ...store,
        mobilemenuState: false,
        sidebarState: false,
      };

    case "TOGGLE_HIGH_CONTRAST":
      return {
        ...store,
        highContrast: !store.highContrast,
      };

    // GOALS
    case "ADD_PINNED_GOAL":
      // Pin toevoegen aan je selectie
      return {
        ...store,
        pinnedGoals: [...store.pinnedGoals, action.payload.newPinnedGoal],
      };

    case "REMOVE_PINNED_GOAL":
      // Pin wegdoen uit je selectie
      return {
        ...store,
        pinnedGoals: [
          ...store.pinnedGoals.filter(
            (pinnedGoal, index) => index !== action.payload.index
          ),
        ],
      };
    case "REMOVE_ALL_PINNED_GOALS":
      // Alle goals wegdoen uit je selectie
      return {
        ...store,
        pinnedGoals: [],
      };
    // case "FLIP_PINNED_GOAL":
    //   // Flip alle functie op de homepage: alles achterkant tonen! Hij past meteen flipstates aan in pinnedCards én in cards.
    //   return {
    //     ...store,
    //     pinnedGoalsFlipstate: !store.pinnedGoalsFlipstate,
    //   };

    case "DELETE_PINNED_GOALS":
      // Maakt de array van gepinde cards helemaal leeg (Bord leegmaken)
      return {
        ...store,
        pinnedGoals: [],
      };

    // case "CHANGE_SERVICE":
    //   return {
    //     ...store,
    //     services: {
    //       ...store.services,
    //       activeService: action.payload.newService,
    //     },
    //   };
    // case "CHANGE_PRODUCT":
    //   return {
    //     ...store,
    //     products: {
    //       ...store.products,
    //       activeProduct: action.payload.newProduct,
    //     },
    //   };

    // case "FETCH_SERVICE_CASE_TITLES":
    //   return {
    //     ...store,
    //     cards: {
    //       ...store.cards,
    //       linkedCaseTitles: action.payload.linkedCaseTitles,
    //     },
    //   };
    // case "FETCH_PRODUCT_CASE_TITLES":
    //   return {
    //     ...store,
    //     cards: {
    //       ...store.cards,
    //       linkedCaseTitles: action.payload.linkedCaseTitles,
    //     },
    //   };
    default:
      return store;
  }
}

export default reducer;
