import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { unflipPinnedCards } from "../redux/actions/actions";

const Inspiration = (props) => {
  const dispatch = useDispatch();

  return (
    <div className="klimax-inspiration">
      <div className="klimax-inspiration-wrapper">
        <Link to="/" className="klimax-inspiration__close" aria-label="sluit het inspiratiescherm">
          <i className="icon-klimax-close-rounded"></i>
        </Link>
        <h1 className="klimax-inspiration__title" style={{ padding: 0 }}>
          Kies een <span>inspiratie</span>bron
        </h1>

        <div className="klimax-inspiration-cards">
          <Link
            to="/inspiratie/topics"
            className="klimax-inspiration-card klimax-inspiration-card--topics"
            onClick={() => dispatch(unflipPinnedCards())}
          >
            <div className="klimax-inspiration-card__info">
              <h2 className="klimax-inspiration-card__title">Hot topics</h2>
              <h3 className="klimax-inspiration-card__subtitle">
                van de klimaatcrisis
              </h3>
            </div>
            <div className="klimax-inspiration-card__chevron">
              <i className="icon-klimax-arrow"></i>
            </div>
          </Link>

          <Link
            to="/inspiratie/Competenties"
            className="klimax-inspiration-card klimax-inspiration-card--goals"
            onClick={() => dispatch(unflipPinnedCards())}
          >
            <div className="klimax-inspiration-card__info">
              <h2 className="klimax-inspiration-card__title">Competenties</h2>
              <h3 className="klimax-inspiration-card__subtitle">
                voor actiecompetente leerlingen
              </h3>
            </div>
            <div className="klimax-inspiration-card__chevron">
              <i className="icon-klimax-arrow"></i>
            </div>
          </Link>

          <Link
            to="/inspiratie/themas"
            className="klimax-inspiration-card klimax-inspiration-card--themes"
            onClick={() => dispatch(unflipPinnedCards())}
          >
            <div className="klimax-inspiration-card__info">
              <h2 className="klimax-inspiration-card__title">Thema's</h2>
              <h3 className="klimax-inspiration-card__subtitle">
                uit de (leef)wereld
              </h3>
            </div>
            <div className="klimax-inspiration-card__chevron">
              <i className="icon-klimax-arrow"></i>
            </div>
          </Link>

          <Link
            to="/inspiratie/werkvormen"
            className="klimax-inspiration-card klimax-inspiration-card--methods"
            onClick={() => dispatch(unflipPinnedCards())}
          >
            <div className="klimax-inspiration-card__info">
              <h2 className="klimax-inspiration-card__title">Werkvormen</h2>
              <h3 className="klimax-inspiration-card__subtitle">
                voor actief en kwaliteitsvol leren
              </h3>
            </div>
            <div className="klimax-inspiration-card__chevron">
              <i className="icon-klimax-arrow"></i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators

    unflipPinnedCards: () => dispatch(unflipPinnedCards()),
  };
};

export default connect(null, mapDispatchToProps)(Inspiration);
