import React  from 'react';
import { Route, Routes, useLocation } from "react-router";
import Home from "./pages/Home";
import Inspiration from "./pages/Inspiration";
import Themes from "./pages/inspiratie/Themes";
import Topics from "./pages/inspiratie/Topics";
import Methods from "./pages/inspiratie/Methods";
import Goals from "./pages/inspiratie/Goals";
import Cookie from "./pages/Cookie";
import Toegankelijkheid from "./pages/Toegankelijkheid";
import CookieBanner from "./components/CookieBanner";

import Error from "./pages/404";

import DeleteAllModal from "./components/modal/DeleteAllModal";
import PinModal from "./components/modal/PinModal";
import Print from "./components/modal/Print";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import ScrollToHashElement from "./helpers/ScrollToHashElement";

function App(props) {
  const { highContrast } = props;
  const dispatch = useDispatch();
  const size = useWindowSize();
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
  }

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <div className={`App ${highContrast === true ? "high-contrast" : ""}`}>
      <Routes>
        <Route path="*" element={<Home />}></Route>
        <Route path="/inspiratie/*" element={<Inspiration />}></Route>
        <Route path="/inspiratie/themas/*" element={<Themes />}></Route>
        <Route path="/inspiratie/topics/*" element={<Topics />}></Route>
        <Route path="/inspiratie/werkvormen/*" element={<Methods />}></Route>
        <Route path="/inspiratie/Competenties/*" element={<Goals />}></Route>
        <Route path="/404" element={<Error />}></Route>
        <Route path="/cookies-privacy" element={<Cookie />}></Route>
        <Route path="/toegankelijkheid" element={<Toegankelijkheid />}></Route>
        <Route path="/print" element={<Print />}></Route>
      </Routes>
      <ScrollToHashElement></ScrollToHashElement>
      <DeleteAllModal></DeleteAllModal>
      <PinModal></PinModal>
      <CookieBanner></CookieBanner>
    </div>
  );
}

const mapStateToProps = (state) => ({
  highContrast: state.highContrast,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching actions returned by action creators
    // flipCards: (id) => dispatch(flipCards(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
