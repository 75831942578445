import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Accordion from "react-bootstrap/Accordion";
import { connect, useDispatch } from "react-redux";
import { closeSidebar } from "../redux/actions/actions";
import { Link } from "react-router-dom";

const Sidebar = (props) => {
  const { sidebarState } = props;
  const dispatch = useDispatch();
  const ref = useRef();
  const handleClickOutside = () => {
    if (sidebarState === true) {
      dispatch(closeSidebar());
    }
  };

  useOnClickOutside(ref, () => handleClickOutside());

  //   const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  //   992px

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }

  return (
    <div
      className={`klimax-sidebar ${
        sidebarState === true ? "klimax-sidebar--open" : ""
      }`}
      ref={ref}
    >
      <div className="klimax-sidebar-wrapper">
        <button
          className="klimax-sidebar__close"
          onClick={() => dispatch(closeSidebar())}
          aria-label="sluit de zijkbalk"
        >
          <i className="icon-klimax-close"></i>
        </button>
        <Tabs defaultActiveKey="first">
          <Tab
            eventKey="first"
            title={
              <div>
                <span>Model</span>{" "}
              </div>
            }
          >
            <h3 className="klimax-sidebar__title">
                Wat zijn de ingrediënten van doeltreffende klimaateducatie?
            </h3>

            <p>
                Wetenschappelijk onderzoek wijst uit dat klimaateducatie gebaat is bij een holistische en actiegerichte aanpak die ingebed zit in de leef- en leerwereld. De inspiratietool KLIMAX wil leerkrachten in het basis- en secundair onderwijs motiveren om in de breedte aan klimaat te werken door 4 ingrediënten met elkaar te verbinden.
            </p>

            <div className="klimax-legende">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="148"
                height="148"
                viewBox="0 0 148 148"
              >
                <g
                  id="Group_291"
                  data-name="Group 291"
                  transform="translate(-646 -250)"
                >
                  <circle
                    id="Ellipse_38"
                    data-name="Ellipse 38"
                    cx="74"
                    cy="74"
                    r="74"
                    transform="translate(646 250)"
                    fill="#fff"
                  />
                  <g
                    id="Group_290"
                    data-name="Group 290"
                    transform="translate(-20329.486 11023.514)"
                  >
                    <path
                      id="Path_1581"
                      data-name="Path 1581"
                      d="M87.681,64.679a28.285,28.285,0,0,1-49.305.3L22.817,74.042a46.279,46.279,0,0,0,80.327-.131Z"
                      transform="translate(20986.543 -10750.452)"
                      fill="#f2441a"
                      opacity="1"
                    />
                    <path
                      id="Path_1582"
                      data-name="Path 1582"
                      d="M36.015,64.324A28.341,28.341,0,0,1,64.324,36.015V18A46.289,46.289,0,0,0,24.2,87.428l15.56-9.064a28.119,28.119,0,0,1-3.743-14.04"
                      transform="translate(20985.162 -10763.838)"
                      fill="#f4cc27"
                      opacity="1"
                    />
                    <path
                      id="Path_1578"
                      data-name="Path 1578"
                      d="M82.309,64.324a28.122,28.122,0,0,1-3.571,13.74L94.2,87.3A46.289,46.289,0,0,0,54,18V36.015A28.341,28.341,0,0,1,82.309,64.324"
                      transform="translate(20995.486 -10763.838)"
                      fill="#f78e34"
                      opacity="1"
                    />
                    <path
                      id="Path_1583"
                      data-name="Path 1583"
                      d="M59.162,36A23.133,23.133,0,0,0,39.119,70.734L59.162,59.162Z"
                      transform="translate(20990.324 -10758.676)"
                      fill="#f4cc27"
                      opacity="1"
                    />
                    <path
                      id="Path_1584"
                      data-name="Path 1584"
                      d="M38.424,65.572a23.125,23.125,0,0,0,40.086,0L58.467,54Z"
                      transform="translate(20991.02 -10753.514)"
                      fill="#f2441a"
                      opacity="1"
                    />
                    <path
                      id="Path_1585"
                      data-name="Path 1585"
                      d="M54,36V59.162L74.043,70.734A23.133,23.133,0,0,0,54,36"
                      transform="translate(20995.486 -10758.676)"
                      fill="#f78e34"
                      opacity="1"
                    />
                    <path
                      id="Path_1586"
                      data-name="Path 1586"
                      d="M19.3,54H0a69.512,69.512,0,0,0,34.964,60.265L44.61,97.557A50.2,50.2,0,0,1,19.3,54"
                      transform="translate(20980 -10753.514)"
                      fill="#3e76da"
                      opacity="1"
                    />
                    <path
                      id="Path_1579"
                      data-name="Path 1579"
                      d="M117.727,54h-19.3A50.2,50.2,0,0,1,74.083,97l9.646,16.707a69.512,69.512,0,0,0,34-59.709"
                      transform="translate(21001.246 -10753.514)"
                      fill="#3e76da"
                      opacity="1"
                    />
                    <path
                      id="Path_1587"
                      data-name="Path 1587"
                      d="M61.694,94.6a49.856,49.856,0,0,1-24.876-6.627l-9.646,16.707a69.264,69.264,0,0,0,70.01-.556L87.536,87.418A49.875,49.875,0,0,1,61.694,94.6"
                      transform="translate(20987.793 -10743.931)"
                      fill="#3e76da"
                      opacity="1"
                    />
                    <path
                      id="Path_1580"
                      data-name="Path 1580"
                      d="M98.64,67.431h19.3A69.512,69.512,0,0,0,82.978,7.166L73.332,23.873A50.2,50.2,0,0,1,98.64,67.431"
                      transform="translate(21001.029 -10766.945)"
                      fill="#66a357"
                      opacity="1"
                    />
                    <path
                      id="Path_1588"
                      data-name="Path 1588"
                      d="M0,67.307H19.3a50.2,50.2,0,0,1,24.342-43L34,7.6A69.512,69.512,0,0,0,0,67.307"
                      transform="translate(20980 -10766.821)"
                      fill="#287b14"
                      opacity="1"
                    />
                    <path
                      id="Path_1589"
                      data-name="Path 1589"
                      d="M61.909,19.3a49.856,49.856,0,0,1,24.876,6.627L96.431,9.221a69.264,69.264,0,0,0-70.01.556l9.646,16.707A49.875,49.875,0,0,1,61.909,19.3"
                      transform="translate(20987.576 -10769)"
                      fill="#4f8e40"
                      opacity="1"
                    />
                  </g>
                </g>
              </svg>
              <p>
                <span className="highlight-blue">
                  Thema’s uit de leefwereld
                </span>{" "}
                die leerlingen motiveren en een relevant aanknopingspunt vormen
                om rond klimaat te werken.
              </p>

              <p>
                Theoretische inzichten over de klimaatcrisis. Deze <b>klimaatinhouden</b> of <span className="highlight-green">hot topics</span> worden onderverdeeld in oorzaken, gevolgen en oplossingen.
              </p>

              <p>
                <b>Werkvormen</b> die aanzetten tot actief en kwaliteitsvol leren over de klimaatcrisis en die bijdragen aan de <b>competenties</b> <span className="highlight-yellow">systeemdenken, toekomstdenken</span>, <span className="highlight-orange">strategisch denken, (samen)actie ondernemen</span>, <span className="highlight-red">omgaan met waarden en normen</span> en <span className="highlight-red">omgaan met emoties</span>. 
              </p>
            </div>

            <h3 className="klimax-sidebar__title">Doelstelling van KLIMAX</h3>

            <p>
            De inspiratietool KLIMAX wil leerkrachten in het basis- en secundair onderwijs inspireren om impactvolle projecten rond klimaat uit te werken en/of (bestaande) lessen rond klimaattopics anders aan te pakken. Het is geen didactisch ontwerpmodel, maar de tool wil op een laagdrempelige wijze inspiratie en houvast bieden. Via een rijke set aan <span className="highlight-blue">thema’s uit de leefwereld</span>, een overzicht van <span className="highlight-green">hot topics</span> (oorzaken, gevolgen en oplossingen), een variatie aan <b>werkvormen</b> en een breed <b>competentiekader</b> wil KLIMAX ondersteuning bieden bij het initiëren van een <b>KLIM</b>aatproject met <b>MAX</b>imale impact of bij het zoeken naar verbeterkansen of aanknopingspunten voor een bestaande les of project.
            </p>

            <h3 className="klimax-sidebar__title">Benieuwd naar meer? </h3>

            <p>De kadertekst ‘Klimaateducatie met impact’ bundelt recente wetenschappelijke inzichten over doeltreffende klimaateducatie. Ideaal voor leerkrachten en andere educatieve professionals die zich inhoudelijk willen verdiepen in het wat en hoe van klimaateducatie.</p>

            <a
              href="#"
              className="klimax-button klimax-button--inline klimax-button--inverted"
              target="_blank"
            >
              <i className="icon-klimax-download"></i> Download de kadertekst
            </a>


            <p>Diepgaande informatie rond de oorzaken, gevolgen en oplossingen van klimaatverandering kan je in de <a href="https://www.mosvlaanderen.be/aan-de-slag/klimaatverandering-oorzaken-gevolgen-en-oplossingen-mindmap-en-tekst" target="_blank">MOS-klimaatmindmap</a> (met bijhorende tekst) vinden.
</p>
          </Tab>
          <Tab
            eventKey="second"
            title={
              <div>
                <span>Werkwijze</span> <i className="icon-klimax-profile" />{" "}
              </div>
            }
          >
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Solowerkwijze 1</Accordion.Header>
                <Accordion.Body>
                    <h3>Vertrek vanuit een thema</h3>
                  <ol>
                    <li>
                        Ga in gesprek met leerlingen en zoek een <b>thema</b> dat aansluit bij hun leefwereld.
                    </li>
                    <li>
                        Onderzoek welke <b>hot topics</b> je ermee kan verbinden. Beperk je niet tot 1 oorzaak/gevolg, tracht meerdere relaties bloot te leggen en graaf dieper naar oorzaken van oorzaken en gevolgen van gevolgen.
                    </li>
                    <li>
                        Haal inspiratie uit de <b>werkvormen</b> om te ontdekken hoe je precies aan de slag kan. Selecteer uit elke kleur minstens 1 werkvorm.
                    </li>
                    <li>
                        Bekijk aan welke <b>competenties</b> je werkt door de keuze voor deze werkvormen. Overweeg of je selectie snor zit en stuur eventueel bij.
                    </li>
                  </ol>

                  <div className="klimax-questions">
                    <ul className="klimax-questions-list">
                      <li className="klimax-questions-list__item">
                        Sluit jouw selectie aan bij de leefwereld en de beginsituatie van de leerlingen?
                      </li>
                      <li className="klimax-questions-list__item">
                        Is jouw selectie goed gekozen zodat je breed aan klimaat kan werken?
                      </li>
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Solowerkwijze 2</Accordion.Header>
                <Accordion.Body>
                    <h3>Vertrek vanuit specifieke (vak)inhouden</h3>
                  <ol>
                    <li>
                        Verken rond welke <b>hot topics</b> je aan de slag wil gaan, gelinkt aan specifieke (vak)inhouden. Selecteer oorzaken, gevolgen en oplossingen van klimaatverandering die je zeker aan bod willen laten komen.
                    </li>
                    <li>
                        Zoek een <b>thema of meerdere deelthema’s</b> uit de leefwereld van de leerlingen die aansluiten bij bovenstaande hot topics.
                    </li>
                    <li>
                        Bepaal welke <b>competenties</b> je centraal plaatst in het klimaatproject. Een selectie van minimum 1 competentie uit elke kleur vormt een eerste opstap naar actiegericht leren rond klimaatverandering.
                    </li>
                    <li>
                        Haal inspiratie uit de <b>werkvormen</b> om te ontdekken hoe je precies aan de slag kan. Selecteer uit elke kleur minstens 1 werkvorm.
                    </li>
                  </ol>

                  <div className="klimax-questions">
                    <ul className="klimax-questions-list">
                      <li className="klimax-questions-list__item">
                        Sluit de keuze van de hot topics aan bij de beginsituatie van de leerlingen (wat ze wel/nog niet kennen over klimaatverandering)?
                      </li>
                      <li className="klimax-questions-list__item">
                        Is jouw selectie goed gekozen zodat je breed aan klimaat kan werken?
                      </li>
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>Solowerkwijze 3</Accordion.Header>
                <Accordion.Body>
                <h3>Vertrek vanuit een bestaande les</h3>
                  <ol>
                    <li>
                        Screen welke <b>competenties</b> reeds aan bod komen en op welke competenties je nog kan inzetten. Een selectie van minimum 1 competentie uit elke kleur vormt een eerste opstap naar actiegericht leren rond klimaatverandering. <b>Let op!</b> In 1 lesuur is het niet haalbaar om diepgaand aan alle competenties te werken. Bij voorkeur focus je op 1 (hoofd)competentie waarbij je dieper ingaat op verschillende deelaspecten.
                    </li>
                    <li>
                        Haal inspiratie uit de <b>werkvormen</b> om te ontdekken hoe je precies aan de slag kan.
                    </li>
                    <li>
                        Zoek een <b>thema</b> dat aansluit bij de les en de leefwereld van de leerlingen.
                    </li>
                    <li>
                        Onderzoek welke <b>hot topics</b> je ermee kan verbinden.
                    </li>
                  </ol>

                  <div className="klimax-questions">
                    <ul className="klimax-questions-list">
                      <li className="klimax-questions-list__item">
                        Sluit de keuze van de competenties aan bij de beginsituatie van de leerlingen?
                      </li>
                      <li className="klimax-questions-list__item">
                        Is jouw selectie goed gekozen zodat je breed aan klimaat kan werken?
                      </li>
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Tab>
          <Tab
            eventKey="third"
            title={
              <div>
                <span>Werkwijze</span> <i className="icon-klimax-group" />{" "}
              </div>
            }
          >
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Groepswerkwijze 1</Accordion.Header>
                <Accordion.Body>
                    <h3>Vertrek vanuit een schoolbreed thema</h3>
                  <ol>
                    <li>
                        Zoek een <b>thema of meerdere deelthema’s</b> die aansluiten bij de leefwereld van de leerlingen.
                    </li>
                    <li>
                        Onderzoek welke hot topics je ermee kan verbinden. Bepaal welke inhouden bij welke leeftijdsgroep aan bod kunnen komen doorheen het schoolbrede project.
                    </li>
                    <li>
                        Haal inspiratie uit de werkvormen om te ontdekken hoe je precies aan de slag kan. Selecteer uit elke kleur minstens 1 werkvorm en differentieer volgens de leeftijd.
                    </li>
                    <li>
                        Bekijk aan welke competenties je werkt door de keuze voor deze werkvormen. Overweeg of je selectie snor zit en stuur eventueel bij.
                    </li>
                  </ol>

                  <div className="klimax-questions">
                    <ul className="klimax-questions-list">
                      <li className="klimax-questions-list__item">
                        Sluit jouw selectie aan bij de leefwereld van de leerlingen en de schoolbrede context?
                      </li>
                      <li className="klimax-questions-list__item">
                        Is jouw selectie goed gekozen zodat je schoolbreed aan klimaat kan werken?
                      </li>
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Groepswerkwijze 2</Accordion.Header>
                <Accordion.Body>
                    <h3>Vertrek vanuit specifieke (vak)inhouden</h3>
                  <ol>
                    <li>
                        Verken rond welke <b>hot topics</b> julle met de vakgroep aan de slag willen gaan, gelinkt aan specifieke (vak)inhouden. Selecteer oorzaken, gevolgen en oplossingen van klimaatverandering die jullie zeker aan bod willen laten komen doorheen het klasoverschrijdend project.
                    </li>
                    <li>
                        Zoek een <b>thema of meerdere deelthema’s</b> uit de leefwereld van de leerlingen die aansluiten bij bovenstaande hot topics.
                    </li>
                    <li>
                        Bepaal welke <b>competenties</b> jullie centraal plaatsen in het klimaatproject. Een selectie van minimum 1 competentie uit elke kleur vormt een eerste opstap naar actiegericht leren rond klimaatverandering.
                    </li>
                    <li>
                        Haal inspiratie uit de <b>werkvormen</b> om te ontdekken hoe jullie precies aan de slag kunnen. Selecteer uit elke kleur minstens 1 werkvorm.
                    </li>
                  </ol>

                  <div className="klimax-questions">
                    <ul className="klimax-questions-list">
                      <li className="klimax-questions-list__item">
                        Sluit de keuze van de hot topics aan bij de beginsituatie van de leerlingen (wat ze wel/nog niet kennen over klimaatverandering)?
                      </li>
                      <li className="klimax-questions-list__item">
                        Welke kansen zie je voor een samenwerking met andere leerkrachten (uit andere vakken/domeinen) om dit actiegericht klimaatproject te realiseren?
                      </li>
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>Groepswerkwijze 3</Accordion.Header>
                <Accordion.Body>
                <h3>Werk samen aan een klimaat-leerlijn</h3>
                  <ol>
                    <li>
                        Screen de <b>competenties</b> die reeds op jaarbasis aan bod komen doorheen de verschillende klassen/leeftijden. Verken welke competenties nog weinig of niet aan bod komen en maak een selectie van waar je schoolbreed wil op inzetten.
                    </li>
                    <li>
                        Haal inspiratie uit de <b>werkvormen</b> om te ontdekken hoe je precies aan de slag kan. Selecteer uit elke kleur werkvormen en differentieer volgens de leeftijd van de leerlingen.
                    </li>
                    <li>
                        Zoek een <b>thema’s</b> die aansluiten bij de leefwereld van de leerlingen. Zijn er kansen om klasoverschrijdend aan de slag te gaan?
                    </li>
                    <li>
                        Onderzoek welke <b>hot topics</b> je ermee kan verbinden.
                    </li>
                  </ol>

                  <div className="klimax-questions">
                    <ul className="klimax-questions-list">
                      <li className="klimax-questions-list__item">
                        Heeft de screening nieuwe competenties opgeleverd die je op het eerste zicht niet zou linken met klimaateducatie?
                      </li>
                      <li className="klimax-questions-list__item">
                        Is jouw selectie goed gekozen zodat je breed aan klimaat kan werken doorheen de verschillende klassen/leeftijden?
                      </li>
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sidebarState: state.sidebarState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeSidebar: () => dispatch(closeSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
