import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../components/MethodCard";
import Dropdown from "react-bootstrap/Dropdown";

const Methods = (props) => {
  const [scrollTop, setScrollTop] = useState(0);
  const { pinnedCards, cards } = props;
  const showFronts = [];
  for (let i = 0; i < pinnedCards.length; i++) {
    showFronts.push(true);
  }

  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = (winScroll / height) * 100;

    setScrollTop(scrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const [selectedAge, changeSelectedAge] = useState("alle leeftijden");
  const listMethods = props.goalMethodCategory;
  const listMethodCards = props.cards
    .filter((card) => {
      return card.sort === "method";
    })
    .filter((methodCard) => {
      // leeftijdsfilter
      if (selectedAge === "alle leeftijden") {
        return methodCard;
      } else {
        return methodCard.ages.includes(selectedAge);
      }
    })
    .sort((a, b) => (a.goalMethodCatId > b.goalMethodCatId ? 1 : -1));
  console.log(listMethodCards);

  return (
    <div className="klimax-inspiration klimax-inspiration--picker klimax-inspiration--methods">
      <div className="klimax-inspiration-wrapper">
        <div>
          <Link
            to="/"
            className="klimax-inspiration__close"
            aria-label="Sluit en ga terug naar mijn bord"
          >
            <i className="icon-klimax-close-rounded"></i>
          </Link>

          <Link
            to="/inspiratie"
            className="klimax-inspiration__back"
            aria-label="Terug naar inspiratiekeuze"
          >
            <i className="icon-klimax-arrow"></i>
          </Link>

          <div className="klimax-inspiration__intro">
            <h1 className="klimax-inspiration__title">Werkvormen</h1>

            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="klimax-button klimax-button--inverted"
              >
                {selectedAge}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  aria-label="alle-leeftijden"
                  onClick={() => changeSelectedAge("alle leeftijden")}
                >
                  alle leeftijden
                </Dropdown.Item>
                <Dropdown.Item
                  aria-label="kleuters"
                  onClick={() => changeSelectedAge("kleuters")}
                >
                  kleuters
                </Dropdown.Item>
                <Dropdown.Item
                  aria-label="basisonderwijs-onderbouw"
                  onClick={() => changeSelectedAge("bao onderbouw")}
                >
                  basisonderwijs onderbouw
                </Dropdown.Item>
                <Dropdown.Item
                  aria-label="basisonderwijs-bovenbouw"
                  onClick={() => changeSelectedAge("bao bovenbouw")}
                >
                  basisonderwijs bovenbouw
                </Dropdown.Item>
                <Dropdown.Item
                  aria-label="secundair-onderwijs-onderbouw"
                  onClick={() => changeSelectedAge("so onderbouw")}
                >
                  secundair onderwijs onderbouw
                </Dropdown.Item>
                <Dropdown.Item
                  aria-label="secundair-onderwijs-bovenbouw"
                  onClick={() => changeSelectedAge("so bovenbouw")}
                >
                  secundair onderwijs bovenbouw
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="klimax-inspiration-results">
            <ul className="klimax-toc" aria-label="Inhoudstafel werkvormen">
              {listMethods.map((method, t) => (
                <li
                  key={t}
                  className={`klimax-toc__item klimax-toc__item--method-${method.primaryColor}`}
                >
                  <Link to={`./#${method.titleInCode}`}>
                    <div dangerouslySetInnerHTML={{ __html: method.title }} />
                  </Link>
                </li>
              ))}

              {/* <li>
                <a href="#" target="_blank" className="klimax-button">
                  <i className="icon-klimax-download" /> Overzicht
                </a>
              </li> */}
            </ul>

            <div className="klimax-inspiration-results__wrapper">
              <div className="klimax-climatecards" id="cards">
                {listMethodCards.map((card, i) => (
                  <Card
                    card={card}
                    pinnedCards={pinnedCards}
                    key={card.id}
                  ></Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  goalMethodCategory: state.goalMethodCategory,
  pinnedCards: state.pinnedCards,
});

export default connect(mapStateToProps)(Methods);
