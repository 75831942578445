import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../components/ThemeCard";

const Themes = (props) => {
  const { pinnedCards, cards } = props;
  const showFronts = [];
  for (let i = 0; i < pinnedCards.length; i++) {
    showFronts.push(true);
  }
  const listThemes = props.themeCategory.sort((a, b) =>
    a.title > b.title ? 1 : -1
  );
  const listThemesEigenIdee = listThemes.push(
    listThemes.splice(
      listThemes.indexOf(listThemes.find((x) => x.title === "Eigen idee")),
      1
    )[0]
  );

  const listThemeCards = props.cards.filter((themeCard) => {
    return themeCard.sort === "theme";
  });

  const listThemeCardsAlphebetically = listThemeCards
    .sort(
      (a, b) =>
        listThemes.findIndex((p) => p.id === a.themeCatId) -
        listThemes.findIndex((p) => p.id === b.themeCatId)
    )
    .push(
      listThemeCards.splice(
        listThemeCards.indexOf(
          listThemeCards.find((x) => x.title === "Eigen idee")
        ),
        1
      )[0]
    );

  return (
    <div className="klimax-inspiration klimax-inspiration--picker klimax-inspiration--themes">
      <div className="klimax-inspiration-wrapper">
        <div>
          <div className="klimax-inspiration__intro">
            <Link
              to="/"
              className="klimax-inspiration__close"
              aria-label="Sluit en ga terug naar mijn bord"
            >
              <i className="icon-klimax-close-rounded"></i>
            </Link>

            <Link
              to="/inspiratie"
              className="klimax-inspiration__back"
              aria-label="Terug naar inspiratiekeuze"
            >
              <i className="icon-klimax-arrow"></i>
            </Link>

            <h1 className="klimax-inspiration__title">Thema's</h1>
          </div>

          <div className="klimax-inspiration-results">
            <ul className="klimax-toc" aria-label="Inhoudstafel thema's">
              {listThemes.map((theme, t) => (
                <li
                  key={t}
                  className={`klimax-toc__item klimax-toc__item--${theme.primaryColor}`}
                >
                  <Link to={`#${theme.titleInCode}`}>{theme.title}</Link>
                </li>
              ))}
              {/* <li>
                                <a
                                    href="#"
                                    target="_blank"
                                    className="klimax-button"
                                >
                                    <i className="icon-klimax-download" />{" "}
                                    Overzicht
                                </a>
                            </li> */}
            </ul>

            <div className="klimax-inspiration-results__wrapper">
              <div className="klimax-climatecards" id="cards">
                {listThemeCards.map((card, i) => (
                  <Card
                    card={card}
                    pinnedCards={pinnedCards}
                    key={card.id}
                  ></Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  themeCategory: state.themeCategory,
  pinnedCards: state.pinnedCards,
});

export default connect(mapStateToProps)(Themes);
